import { Divider, InputAdornment, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormikProps, withFormik } from 'formik';
import { useEffect, useState } from "react";
import { EmployeesApi, JobsApi } from "../../../api/ModuleApi";
import { LaborValidationSchema } from "../../../helpers/FormValidators";
import { getWorkersCompRate } from "../../../helpers/DataHelpers";
import { FormProps } from '../../../interfaces/forms/FormTypes';
import { LaborFormData } from "../../../interfaces/labor/LaborTypes";
import { ModuleDataLimited } from "../../../store/modules/ModuleTypes";
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage";
import { FormInfoMessage } from "../../shared/forms/FormInfoMessage";
import { FormSubmitButton } from '../../shared/forms/FormSubmitButton';
import "./LaborForm.css";
import { LaborFormCalculations } from './LaborFormCalculations';

const InnerForm = (props: FormProps<LaborFormData> & FormikProps<LaborFormData>) => {
    const [jobData, setJobData] = useState<ModuleDataLimited[]>([])
    const [employeeData, setEmployeeData] = useState<ModuleDataLimited[]>([])

    useEffect(() => {
        JobsApi.fetchAllLimited().then((r) => setJobData(r as ModuleDataLimited[]))
        EmployeesApi.fetchAllLimited().then((r) => setEmployeeData(r as ModuleDataLimited[]))
    }, [])

	const { 
		handleChange, 
        setFieldValue,
		handleSubmit,
		values,
        isValid,
        isSubmitting,
        title,
        formType,
        handleBlur,
        touched,
        errors,
        setFieldTouched
	} = props;

    const checkPrevailingWage = (rate: 'hangFramingRate' | 'tapingLaborRate') => {
        if (values.job === undefined || values.job === null) { return false }
        if (values.job.prevailingWage === undefined || values.job.prevailingWage === null) { return false }
        const pw = values.job.prevailingWage
        return pw > 0 && parseFloat(values[rate]!) < pw
    }

	return (
		<form>
            <div className="form-input-container">
                <Autocomplete
                    options={jobData}
                    getOptionLabel={(data: ModuleDataLimited) => `${data.name} #${data.id}`}
                    fullWidth
                    value={ values.job }
					onBlur={handleBlur}
                    renderInput={(params) => 
                        <TextField 
                            error={touched.job && Boolean(errors.job)}
                            {...params} 
                            label="Job" 
                        />}
                    onChange={ (e, v) => {
                        JobsApi.fetchById(v?.id ?? -1)
                        .then((response) => {
                            setFieldValue("job", response) 
                            setFieldTouched("job", true)
                            if (response.prevailingWage !== null) {
                                setFieldValue("tapingLaborRate", response.prevailingWage)
                                setFieldValue("hangFramingRate", response.prevailingWage)
                            }
                        })
                    }}
                />
				{ touched.job && Boolean(errors.job) && <FormErrorMessage errorMessage={errors.job} /> }
            </div>
            <div className="form-input-container">
                <TextField
                    fullWidth
                    value={values.job?.customer?.name ?? ""}
                    label="Customer"
                    disabled={true}
                />
            </div>
            <div className="form-input-container">
                <Autocomplete
                    options={employeeData}
                    getOptionLabel={(employee: ModuleDataLimited) => `${employee.name} #${employee.id}`}
					onBlur={handleBlur}
                    fullWidth
                    value={ values.employee ? { id: values.employee.id, name: values.employee.fullName } : undefined }
                    renderInput={(params) => 
                        <TextField 
                            {...params} 
                            error={touched.employee && Boolean(errors.employee)}
                            label="Employee" 
                        />
                    }
                    onChange={ (e, v) => {
                        EmployeesApi.fetchById(v?.id ?? -1)
                        .then((emp) => {
                            setFieldValue("employee", emp)
                            setFieldTouched("employee", true)
                            const wcRate = getWorkersCompRate(emp.hangFramingRate)
                            setFieldValue("wcRate", wcRate)
                            if (!values.job || values.job.prevailingWage === null) {
                                setFieldValue("tapingLaborRate", emp?.tapingLaborRate)
                                setFieldValue("hangFramingRate", emp?.hangFramingRate)
                            }
                        })
                    }}
                />
				{ touched.employee && Boolean(errors.employee) && <FormErrorMessage errorMessage={errors.employee} /> }
            </div>
            <Divider />
            <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px", paddingBottom: "5px" }}>
                <strong>Hang Framing</strong>
            </h1>
            <div className="form-input-container">
                <TextField 
                    label="Hang Framing Rate" 
                    type="number"
                    name="hangFramingRate"
                    value= {values.hangFramingRate} 
                    onChange={handleChange}
					onBlur={handleBlur}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
					error={touched.hangFramingRate && Boolean(errors.hangFramingRate)}
                />
				{ touched.hangFramingRate && Boolean(errors.hangFramingRate) && <FormErrorMessage errorMessage={errors.hangFramingRate} /> }
				{ checkPrevailingWage('hangFramingRate') && <FormInfoMessage message={"Rate is less than prevailing wage."} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Hang Framing Hours" 
                    type="number"
                    name="hangFramingHours"
                    value= {values.hangFramingHours} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.hangFramingHours && Boolean(errors.hangFramingHours)}
                />
				{ touched.hangFramingHours && Boolean(errors.hangFramingHours) && <FormErrorMessage errorMessage={errors.hangFramingHours} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Hang Framing Hours OT" 
                    type="number"
                    name="hangFramingHoursOvertime"
                    value= {values.hangFramingHoursOvertime} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.hangFramingHoursOvertime && Boolean(errors.hangFramingHoursOvertime)}
                />
				{ touched.hangFramingHoursOvertime && Boolean(errors.hangFramingHoursOvertime) && <FormErrorMessage errorMessage={errors.hangFramingHoursOvertime} /> }
            </div>
            <Divider />
            <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px", paddingBottom: "5px" }}>
                <strong>Taping Labor</strong>
            </h1>
            <div className="form-input-container">
                <TextField 
                    label="Tape Labor Rate" 
                    type="number"
                    name="tapingLaborRate"
                    value= {values.tapingLaborRate} 
                    onChange={handleChange}
					onBlur={handleBlur}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
					error={touched.tapingLaborRate && Boolean(errors.tapingLaborRate)}
                />
				{ touched.tapingLaborRate && Boolean(errors.tapingLaborRate) && <FormErrorMessage errorMessage={errors.tapingLaborRate} /> }
				{ checkPrevailingWage('tapingLaborRate') && <FormInfoMessage message={"Rate is less than prevailing wage."} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Tape Labor Hours" 
                    type="number"
                    name="tapingLaborHours"
                    value= {values.tapingLaborHours} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.tapingLaborHours && Boolean(errors.tapingLaborHours)}
                />
				{ touched.tapingLaborHours && Boolean(errors.tapingLaborHours) && <FormErrorMessage errorMessage={errors.tapingLaborHours} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Tape Labor Hours OT" 
                    type="number"
                    name="tapingLaborHoursOvertime"
                    value= {values.tapingLaborHoursOvertime} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.tapingLaborHoursOvertime && Boolean(errors.tapingLaborHoursOvertime)}
                />
				{ touched.tapingLaborHoursOvertime && Boolean(errors.tapingLaborHoursOvertime) && <FormErrorMessage errorMessage={errors.tapingLaborHoursOvertime} /> }
            </div>
            <Divider />
            <div className="form-input-container">
            <h1 className="form-input-label">
                    Payroll Tax Rate (decimal)
                </h1>
                <TextField 
                    label="" 
                    type="number"
                    name="wcRate"
                    value= {values.wcRate} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.wcRate && Boolean(errors.wcRate)}
                />
				{ touched.wcRate && Boolean(errors.wcRate) && <FormErrorMessage errorMessage={errors.wcRate} /> }
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Week Ending Date
                </h1>
                <TextField 
                    type="date"
                    name="weekEndingDate"
                    value= { values.weekEndingDate } 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
            { values.employee && <LaborFormCalculations values={values} /> }
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const LaborForm = withFormik<FormProps<LaborFormData>, LaborFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: LaborValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Labor Form",
})(InnerForm);