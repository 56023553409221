import { ModuleDataType } from "../../store/modules/ModuleTypes";
import { Job } from "../jobs/JobTypes";

export enum ChangeOrderCategory {
    MaterialCost = "materialCost",
    HF = "hangFramingCost",
    Taping = "tapingCost",
    Misc = "miscCost"
}

export interface ChangeOrderItem {
    id: number,
    description: string,
    cost: number,
}

export interface ChangeOrder {
    id: number,
    title: string,
    number?: string,
    contractNumber: string,
    hangFramingCost: string,
    tapingCost: string,
    materialCost: string,
    miscCost: string,
    date: Date,
    approved: boolean,
    additionalNotes?: string,
    job: Job,
    items: ChangeOrderItem[]
}

export interface ChangeOrderFormData {
    title?: string,
    number?: string,
    contractNumber?: string,
    hangFramingCost?:string,
    tapingCost?: string,
    materialCost?: string,
    miscCost?: string,
    date?: string,
    approved?: boolean,
    additionalNotes?: string,
    job?: Job,    
    items: ChangeOrderItemFormData[],
    generateChangeOrderPdf?: boolean
}

export interface ChangeOrderItemFormData {
    description: string,    
    cost: string
}

export interface ChangeOrderDto {        
    title: string;
    number?: string,
    contractNumber: string,
    hangFramingCost:string,
    tapingCost: string,
    materialCost: string,
    miscCost: string,
    job: ModuleDataType;
    category: string;
    cost: number;
    date: Date;
    approved: boolean;
    additionalNotes: string;
    items: ChangeOrderItemDto[]
    // createdAt: string;
}

export interface ChangeOrderItemDto {
    description: string,
    cost: number,
}