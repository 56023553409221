import { InvoicesApi } from '../../../api/ModuleApi';
import { getInvoiceDto } from '../../../helpers/DtoGenerators';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { InvoiceFormData } from '../../../interfaces/invoices/InvoiceTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { InvoiceForm } from './InvoiceForm';

export const NewInvoiceForm = ({ onClose }: NewFormProps) => {
    const initialFormData: InvoiceFormData = { 
        disputedAmount: "0",
        changeOrdersToDate: "0",
        percentageCompletedToDate: "0",
        lessRetentionPercentage: "0",
        lessPreviouslyBilled: "0"
    }

    const handleClose = () => {
        onClose()
    }

    const handleNewInvoice = (formData: InvoiceFormData) => {
        InvoicesApi.create(getInvoiceDto(formData))
        .then(() => {
            handleClose()
        })
    }

    return (
        <NewForm
            title="Invoice"
            form={
                <InvoiceForm
                    formData={initialFormData}
                    formType={FormType.New}
                    title="Invoice"
                    onSubmit={ values => handleNewInvoice(values) }
                />
            }
            onClose={() => handleClose()}
        />
    )
}