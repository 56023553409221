import { InputAdornment, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { FormikProps, withFormik } from "formik"
import { useEffect, useState } from "react"
import { JobsApi, SuppliersApi } from "../../../api/ModuleApi"
import { MaterialValidationSchema } from "../../../helpers/FormValidators"
import { FormProps } from "../../../interfaces/forms/FormTypes"
import { MaterialFormData } from "../../../interfaces/materials/MaterialTypes"
import { ModuleDataLimited } from "../../../store/modules/ModuleTypes"
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage"
import { FormSubmitButton } from "../../shared/forms/FormSubmitButton"

const InnerForm = (props: FormProps<MaterialFormData> & FormikProps<MaterialFormData>) => {
    const [supplierData, setSupplierData] = useState<ModuleDataLimited[]>([])
    const [jobData, setJobData] = useState<ModuleDataLimited[]>([])

    useEffect(() => {
        SuppliersApi.fetchAllLimited().then((r) => setSupplierData(r as ModuleDataLimited[]))
        JobsApi.fetchAllLimited().then((r) => setJobData(r as ModuleDataLimited[]))
    }, [])

	const { 
		handleChange, 
        setFieldValue,
		handleSubmit,
		values,
        isValid,
        isSubmitting,
        title,
        formType,
        touched,
        setFieldTouched,
        errors,
        handleBlur
	} = props;
	return (
		<form>
            <div className="form-input-container">
                <Autocomplete
                    options={supplierData}
                    getOptionLabel={(supplier: ModuleDataLimited) => `${supplier.name} #${supplier.id}`}
                    fullWidth
                    value={ values.supplier }
					onBlur={handleBlur}
                    renderInput={(params) => 
                        <TextField 
                            error={touched.supplier && Boolean(errors.supplier)}
                            {...params} 
                            label="Supplier" 
                        />}
                    onChange={ (e, v) => {
                        SuppliersApi.fetchById(v?.id ?? -1)
                        .then((response) => {
                            setFieldValue("supplier", response) 
                            setFieldTouched("supplier", true)
                        })
                    }}
                />
				{ touched.supplier && Boolean(errors.supplier) && <FormErrorMessage errorMessage={errors.supplier} /> }
            </div>
            <div className="form-input-container">
                <Autocomplete
                    options={jobData}
                    getOptionLabel={(data: ModuleDataLimited) => `${data.name} #${data.id}`}
                    fullWidth
                    value={ values.job }
					onBlur={handleBlur}
                    renderInput={(params) => 
                        <TextField 
                            error={touched.job && Boolean(errors.job)}
                            {...params} 
                            label="Job" 
                        />}
                    onChange={ (e, v) => {
                        setFieldValue("job", v) 
                        setFieldTouched("job", true)
                    }}
                />
				{ touched.job && Boolean(errors.job) && <FormErrorMessage errorMessage={errors.job} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Invoice #"                                         
                    type="text"
                    name="invoiceNumber"
                    value= {values.invoiceNumber} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.invoiceNumber && Boolean(errors.invoiceNumber)}
                />
				{ touched.invoiceNumber && Boolean(errors.invoiceNumber) && <FormErrorMessage errorMessage={errors.invoiceNumber} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Amount" 
                    type="number"
                    name="amount"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    value= {values.amount} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.amount && Boolean(errors.amount)}
                />
				{ touched.amount && Boolean(errors.amount) && <FormErrorMessage errorMessage={errors.amount} /> }
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Date
                </h1>
                <TextField 
                    type="date"
                    name="date"
                    value= {values.date} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const MaterialForm = withFormik<FormProps<MaterialFormData>, MaterialFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: MaterialValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Material Form",
})(InnerForm);