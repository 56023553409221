import { Bid, BidDto, BidFormData } from "../interfaces/bids/BidTypes"
import { ChangeOrderDto, ChangeOrderFormData } from "../interfaces/change/ChangeOrderTypes"
import { CustomerDto, CustomerFormData } from "../interfaces/customers/CustomerTypes"
import { EmployeeDto, EmployeeFormData } from "../interfaces/employees/EmployeeTypes"
import { InvoiceDto, InvoiceFormData } from "../interfaces/invoices/InvoiceTypes"
import { JobDto, JobFormData } from "../interfaces/jobs/JobTypes"
import { LaborDto, LaborFormData } from "../interfaces/labor/LaborTypes"
import { MaterialDto, MaterialFormData } from "../interfaces/materials/MaterialTypes"
import { SupplierDto, SupplierFormData } from "../interfaces/suppliers/SupplierTypes"
import { PrevailingWageDto, PrevailingWageFormData } from "../interfaces/wages/PrevailingWageTypes"
import { formatDateToInput, getLocalDate, getNumberFromStringValue } from "./IndexHelpers"

export const getJobDto= (formData: JobFormData): JobDto => {
    return {
        name: formData.name ?? "",
        customer: { id: formData.customer?.id ?? -1 },
        address: formData.address ?? "",
        owner: formData.owner ?? "",
        status: formData.status,
        contractPrice: getNumberFromStringValue(formData.contractPrice),
        materialCost: getNumberFromStringValue(formData.materialCost),
        hangFramingCost: getNumberFromStringValue(formData.hangFramingCost),
        manDayPrice: getNumberFromStringValue(formData.manDayPrice),
        miscCost: getNumberFromStringValue(formData.miscCost),
        tapingLaborCost: getNumberFromStringValue(formData.tapingLaborCost),
        additionalNotes: formData.additionalNotes ?? "",
        date: new Date(formData.date ?? ""),
        prevailingWage: formData.showPrevailingWage ? getNumberFromStringValue(formData.prevailingWage) : null,
    } as JobDto
}

export const getBidDto= (formData: BidFormData): BidDto => {
    return {
        name: formData.name ?? "",
        customer: { id: formData.customer?.id ?? -1 },
        awarded: formData.awarded,
        bidAmount: getNumberFromStringValue(formData.bidAmount),
        awardAmount: getNumberFromStringValue(formData.awardAmount),
        date: new Date(formData.date ?? "")
    }
}

export const getBidForm = (data: Bid): BidFormData => {
    return {
        ...data,
        name: data.name ?? "",
        awarded: data.awarded,
        bidAmount: data.bidAmount.toString(),
        awardAmount: data.awardAmount.toString(),
        date: formatDateToInput(data.date),
    }
}

export const getChangeOrderDto = (formData: ChangeOrderFormData): ChangeOrderDto => {
    return {
        title: formData.title ?? "",
        number: formData.number ?? null,
        job: { id: formData.job?.id ?? -1 },
        contractNumber: formData.contractNumber ?? "",
        hangFramingCost:formData.hangFramingCost ?? 0,
        tapingCost: formData.tapingCost ?? 0,
        materialCost: formData.materialCost ?? 0,
        miscCost: formData.miscCost ?? 0,
        date: formData.date ? new Date(formData.date) : null,
        approved: formData.approved ?? false,
        additionalNotes: formData.additionalNotes ?? "",
        items: formData.items.map((item) => ({
            ...item,
            cost: getNumberFromStringValue(item.cost)
        }))
    } as ChangeOrderDto
}

export const getLaborDto = (formData: LaborFormData) : LaborDto => {
    return {
        job: {id: formData.job?.id ?? -1},
        employee: {id: formData.employee?.id ?? -1},
        hangFramingRate: getNumberFromStringValue(formData.hangFramingRate),
        hangFramingHours: getNumberFromStringValue(formData.hangFramingHours),
        hangFramingHoursOvertime: getNumberFromStringValue(formData.hangFramingHoursOvertime),
        tapingLaborRate: getNumberFromStringValue(formData.tapingLaborRate),
        tapingLaborHours: getNumberFromStringValue(formData.tapingLaborHours),
        tapingLaborHoursOvertime: getNumberFromStringValue(formData.tapingLaborHoursOvertime),
        miscExpenses: formData.miscExpenses ?? 0,
        wcRate: getNumberFromStringValue(formData.wcRate),
        weekEndingDate: new Date(formData.weekEndingDate ?? ""),
        additionalNotes: formData.additionalNotes ?? "",
    } as LaborDto
}

export const getEmployeeDto = (formData: EmployeeFormData) : EmployeeDto => {
    return {
        firstName: formData.firstName ?? "",
        lastName: formData.lastName ?? "",
        fullName: `${formData.firstName} ${formData.lastName}`,
        jobTitle: formData.jobTitle ?? "",
        hangFramingRate: getNumberFromStringValue(formData.hangFramingRate),
        tapingLaborRate: getNumberFromStringValue(formData.tapingLaborRate),
        socialSecurityNum: formData.socialSecurityNum ?? "",
        mainPhone: formData.mainPhone ?? "",
        cellPhone: formData.cellPhone ?? "",
        address: formData.address ?? "",
        email: formData.email ?? "",
        hireDate: new Date(formData.hireDate ?? ""),
        birthDate: new Date(formData.birthDate ?? ""),
        additionalNotes: formData.additionalNotes ?? "",        
        emergencyContact1: {
            name: formData.emergencyContact1?.name ?? "",
            phone: formData.emergencyContact1?.phone ?? "",
            relationship: formData.emergencyContact1?.relationship ?? "",
        },
        emergencyContact2: {
            name: formData.emergencyContact2?.name ?? "",
            phone: formData.emergencyContact2?.phone ?? "",
            relationship: formData.emergencyContact2?.relationship ?? "",
        }
    } as EmployeeDto
}

export const getCustomerDto = (formData: CustomerFormData) : CustomerDto => {
    return{
        name: formData.name ?? "",
        federalTaxId: formData.federalTaxId ?? "",
        contractorLicenseNum: formData.contractorLicenseNum ?? "",
        address: formData.address ?? "",
        contact: formData.contact ?? "",
        mainPhone: formData.mainPhone ?? "",
        phone2: formData.phone2 ?? "",
        email: formData.email ?? "",
        additionalNotes: formData.additionalNotes ?? "",
        printFormatChangeOrder: { id: formData.printFormatChangeOrder?.id ?? 1 },
        printFormatInvoice: { id: formData.printFormatInvoice?.id ?? 1 }
    } as CustomerDto
}

export const getSupplierDto = (formData: SupplierFormData) : SupplierDto => {
    return {
        name: formData.name ?? "",
        address: formData.address ?? "",
        phone: formData.phone ?? "",
        additionalNotes: formData.additionalNotes ?? "",
        contact1: formData.contact1 ?? "",
        contact2: formData.contact2 ?? "",
    } as SupplierDto
}

export const getInvoiceDto = (formData: InvoiceFormData) : InvoiceDto => {
    return {
        job: {id: formData.job?.id ?? -1},
        contractAmount: getNumberFromStringValue(formData.contractAmount),
        changeOrdersToDate: getNumberFromStringValue(formData.changeOrdersToDate),
        billThroughDate: getLocalDate(formData.billThroughDate),
        contractNumber: formData.contractNumber ?? "",
        revisedContractAmount: getNumberFromStringValue(formData.revisedContractAmount),
        percentageCompletedToDate: getNumberFromStringValue(formData.percentageCompletedToDate),
        lessRetentionPercentage: getNumberFromStringValue(formData.lessRetentionPercentage),
        disputedAmount: getNumberFromStringValue(formData.disputedAmount),
        date: new Date(formData.billThroughDate ?? ""),
        additionalNotes: formData.additionalNotes ?? "",
        subContractNumber: formData.subContractNumber ?? "",
        lessPreviouslyBilled: getNumberFromStringValue(formData.lessPreviouslyBilled),
        invoiceNumber: formData.invoiceNumber ?? "",
        poNumber: formData.poNumber ?? "",
        throughTrufChangeOrderNumber: formData.throughTrufChangeOrderNumber ?? "",
    } as InvoiceDto
}

export const getWagesDto = (formData: PrevailingWageFormData) :PrevailingWageDto => {
    return {
        value: getNumberFromStringValue(formData.value),
        startDate: new Date(formData.startDate ?? ""),
        endDate: new Date(formData.endDate ?? ""),
    } as PrevailingWageDto
}

export const getMaterialDto = (formData: MaterialFormData) : MaterialDto => {
    return {
        job: {id: formData.job?.id ?? -1},
        supplier: {id: formData.supplier?.id ?? -1},
        amount: getNumberFromStringValue(formData.amount),
        invoiceNumber: formData.invoiceNumber,
        additionalNotes: formData.additionalNotes ?? "",
        date: new Date(formData.date ?? "")
    } as MaterialDto
}