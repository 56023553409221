import { MaterialsApi } from '../../../api/ModuleApi';
import { getMaterialDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { Material, MaterialFormData } from '../../../interfaces/materials/MaterialTypes';
import { EditForm } from '../../shared/forms/EditForm';
import { MaterialForm } from './MaterialForm';

export const EditMaterialForm = ({ currentValue, onClose }: EditFormProps<Material>) => {
    const initialValues: MaterialFormData = {
        ...currentValue,
        amount: currentValue.amount ? currentValue.amount.toString() : "",
        date: formatDateToInput(currentValue.date)
    }

    const handleUpdate = (formData: MaterialFormData) => {
        MaterialsApi.edit(currentValue.id, getMaterialDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemove = () => {
        MaterialsApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <EditForm
            title="Material"
            form={
                <MaterialForm
                    formData={initialValues}
                    formType={FormType.Edit}
                    title="Material"
                    onSubmit={ values => handleUpdate(values) }
                />
            }
            onClose={onClose}
            handleDeleteAction={handleRemove}
        />
    )
}