import React from "react"
import { NavBar } from "../nav/NavBar"

type Props = {
    page: JSX.Element
}

export const ProtectedRoute = ({ page }: Props) => {
    return (
        <React.Fragment>
            <NavBar />
            <div className="page-container">
                { page }
            </div>
        </React.Fragment>
    )
}