import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

type Props = {
    message?: string
}

export const FormInfoMessage = ({ message }: Props) => {
    return (
        <React.Fragment>
            {
                <div style={{ display: "flex", alignItems: "center", marginTop: "5px", marginLeft: "10px" }}>
                    <FontAwesomeIcon icon={faInfoCircle} color="#123E66" />
                    <p style={{ color: "#123E66", margin: "5px", marginLeft: "10px" }}><i>{message}</i></p>
                </div>
            }
        </React.Fragment>
    )
}