import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { BidsApi } from '../../../api/ModuleApi';
import { getBidDto } from '../../../helpers/DtoGenerators';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { Bid, BidFormData } from '../../../interfaces/bids/BidTypes';
import { getBidForm } from '../../../helpers/DtoGenerators';
import { DeleteDialog } from '../../dialogs/DeleteDialog';
import { BidForm } from '../shared/form/BidForm';
import './EditBidForm.css';

export const EditBidForm = ({ onClose, currentValue }: EditFormProps<Bid>) => {
    const initialData: BidFormData = {
        ...getBidForm(currentValue)
    }
    console.log(initialData)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    const handleUpdateBid = (formData: BidFormData) => {
        BidsApi.edit(currentValue.id, getBidDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemoveBid = () => {
        BidsApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <div className={"page-background-container"}>
            <div className={"edit-job-form-container edit-job-form-container-info"}>
                <div className="form-container-header">
                    <h1>Edit Bid</h1>
                    <FontAwesomeIcon icon={faTimes} size="2x" onClick={ e => onClose() } />
                </div>
                <BidForm
                    formData={initialData}
                    formType={FormType.Edit}
                    title="Bid"
                    onSubmit={ values => handleUpdateBid(values) }
                />
                <DeleteDialog
                    open={deleteDialogOpen}
                    handleClose={ () => setDeleteDialogOpen(false) }
                    handleDelete={ () => {
                        setDeleteDialogOpen(false)
                        handleRemoveBid()
                    }}
                    content="Are you sure you want to delete this Bid?"
                />
            </div>
        </div>
    )
}