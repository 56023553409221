import { MaterialsApi } from '../../../api/ModuleApi';
import { getMaterialDto } from '../../../helpers/DtoGenerators';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { MaterialFormData } from '../../../interfaces/materials/MaterialTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { MaterialForm } from './MaterialForm';

export const NewMaterialForm = ({ onClose }: NewFormProps) => {
    const handleNewMaterial = (formData: MaterialFormData) => {
        MaterialsApi.create(getMaterialDto(formData))
        .then(() => {
            onClose()
        })
    }

    return (
        <NewForm
            title="Material"
            form={
                <MaterialForm
                    formData={{}}
                    formType={FormType.New}
                    title="Material"
                    onSubmit={ values => handleNewMaterial(values) }
                />
            }
            onClose={onClose}
        />
    )
}