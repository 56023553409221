import { ChangeOrder } from "../interfaces/change/ChangeOrderTypes";
import { Invoice, InvoiceFormData } from "../interfaces/invoices/InvoiceTypes";
import store from "../store";
import { selectPrevailingWageData } from "../store/modules/ModuleSelectors";
import { getNumberFromStringValue } from "./IndexHelpers";

export const getWorkersCompRate = (rate: number | undefined) => {
    const thresholdRate = 36
    const belowThresholdRate = 0.1915
    const overThresholdRate = 0.1638

    if (!rate) rate = 0
    const workersCompRate = rate < thresholdRate ? 
        belowThresholdRate : 
        overThresholdRate

    return workersCompRate;
}

export const getlaborCosts = (rate: number, hours: number, otHours: number, wcRate: number) => {
    const workersCompRate = wcRate ? wcRate : getWorkersCompRate(rate);
    const otRate = 1.5;
    const otCost = otHours * rate * otRate
    const regularHoursCost = rate * hours
    const workersCompCost = workersCompRate * (otCost + regularHoursCost)
    return {
        wc: workersCompCost,
        ot: otCost,
        hours: regularHoursCost,
    }
}

export const getCurrencyValue = (num: number) => {
    if (typeof num !== "number") {
        return !isNaN(num) ? `$${parseFloat(num).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "$0.00"
    }
    return !isNaN(num) ? `$${num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "$0.00"
}

export const getPrevailingWage = (date: Date) => {
    const data = selectPrevailingWageData(store.getState())
    const prevailingWage = data.find(pw => {//dummy data logic for now
        return (new Date(pw.startDate) < date && new Date(pw.endDate) > date)
    });
    if(prevailingWage){
        return prevailingWage.value;
    }
    else{//this shouldn't ever happen. What kind of validation should we do here?
        return 0;
    }
}


export const getChangeOrderCostsTotal = (co: ChangeOrder) =>{
    return parseFloat(((co.hangFramingCost ? getNumberFromStringValue(co.hangFramingCost) : 0) + 
            (co.tapingCost ? getNumberFromStringValue(co.tapingCost) : 0) + 
            (co.materialCost ? getNumberFromStringValue(co.materialCost) : 0) + 
            (co.miscCost ? getNumberFromStringValue(co.miscCost) : 0)).toFixed(2)).toLocaleString();             
}

const getNumValue = (val: string | undefined): number => {
    return val !== undefined && val !== "" ? parseFloat(val) : 0
}

const getDollarValue = (val: number): number => {
    return Math.round((val + Number.EPSILON) * 100) / 100;
}

const getValue = (val: number | undefined): number => {
    if (val === undefined) { return 0 }
    if (typeof val === "string") { return parseFloat(val)}
    if (isNaN(val)) { return 0 }
    return val
}

export const getInvoiceFormCalculations = (values: InvoiceFormData) => {
    let revised = getNumValue(values.contractAmount) + getNumValue(values.changeOrdersToDate)
    let amount = getNumValue(values.percentageCompletedToDate) / 100 * revised
    let lessAmount = getNumValue(values.lessRetentionPercentage) / 100 * amount
    let totalThisStatement = amount - lessAmount
    let totalDueThisStatement = totalThisStatement - getNumValue(values.lessPreviouslyBilled)

    return {
        revisedContractAmount: isNaN(revised) ? 0 : getDollarValue(revised),
        amountCompletedToDate: isNaN(amount) ? 0 : getDollarValue(amount),
        lessRetentionAmount: isNaN(lessAmount) ? 0 : getDollarValue(lessAmount),
        totalThisStatement: isNaN(totalThisStatement) ? 0 : getDollarValue(totalThisStatement),
        totalDueThisStatement: isNaN(totalDueThisStatement) ? 0 : getDollarValue(totalDueThisStatement)
    }
}
                    
export const getInvoiceCalculations = (values: Invoice) => {
    let revised = getValue(values.contractAmount) + getValue(values.changeOrdersToDate)
    let amount = getValue(values.percentageCompletedToDate) / 100 * revised
    let lessAmount = getValue(values.lessRetentionPercentage) / 100 * amount
    let totalThisStatement = amount - lessAmount
    let totalDueThisStatement = totalThisStatement - getValue(values.lessPreviouslyBilled)
    let changeOrdersToDate = getValue(values.changeOrdersToDate);

    return {
        revisedContractAmount: isNaN(revised) ? 0 : revised,
        amountCompletedToDate: isNaN(amount) ? 0 : amount,
        lessRetentionAmount: isNaN(lessAmount) ? 0 : lessAmount,
        totalThisStatement: isNaN(totalThisStatement) ? 0 : totalThisStatement,
        totalDueThisStatement: isNaN(totalDueThisStatement) ? 0 : totalDueThisStatement,
        changeOrdersToDate: isNaN(changeOrdersToDate) ? 0 : changeOrdersToDate,
    }
}

export const toNumber = (value: string | undefined | number) => {
    if (typeof value == "number") return value;
    if (!value || value === "") { return 0 }
    return parseFloat(value)
}

export const toString = (value: string | undefined | number) => {
    return value ? value.toString() : "0"
}