import { SearchFilter } from "../interfaces/search/FilterTypes";

export const getSearchKeysFromFilters = (filters: SearchFilter[]): string[] => {
    let keyArrays = filters.filter(f => f.isActive).map(f => f.keys)
    var temp: string[] = []
    for (var a of keyArrays) {
        temp = temp.concat(a)
    }

    return temp
}