import axios, { AxiosError, AxiosResponse } from "axios"
import { API_ROUTES, defaultApiOptions, getBackendUrlForPath } from "../helpers/ApiHelpers"
import { AlertTypes } from "../interfaces/alert/AlertState"
import { JobCostingData } from "../interfaces/jobs/JobTypes"
import { PrevailingWage } from "../interfaces/wages/PrevailingWageTypes"
import store from "../store"
import { setAlert } from "../store/alert/AlertActions"
var fileDownload = require('js-file-download');

export const getJobCostingData = (jobId: number) => {
    return new Promise<JobCostingData>((resolve, reject) => {
        let url = `${getBackendUrlForPath(API_ROUTES.JOBS)}/${jobId}/costing`
        axios.get(url, defaultApiOptions)
        .then((response: AxiosResponse) => {
            // store in redux
            resolve(response.data as JobCostingData)
        }, (error: AxiosError) => {
            store.dispatch(setAlert(`Could not fetch change orders. Please try refreshing the page.`, AlertTypes.Error))
            reject(error);
        })
    })
}

export const getLatestPrevailngWage = () => {
    return new Promise<PrevailingWage>((resolve, reject) => {
        let url = `${getBackendUrlForPath(API_ROUTES.WAGES)}/date`
        axios.get(url, defaultApiOptions)
        .then((response: AxiosResponse) => {
            // store in redux
            resolve(response.data as PrevailingWage)
        }, (error: AxiosError) => {
            store.dispatch(setAlert(`Could not fetch prevailing wage. Please try refreshing the page.`, AlertTypes.Error))
            reject(error);
        })
    })
}

export const generateInvoiceExcel = async (invoiceId: number, inProgress: boolean) => {
    try {
        let url = `${getBackendUrlForPath(API_ROUTES.INVOICES)}/excel/${invoiceId}?progress=${inProgress}`
        let response = await axios.post(url, {}, {
            withCredentials: true,
            responseType: 'blob'
        })
        fileDownload(response.data, "invoice.xlsx")
        return response
    } catch (error) {
        store.dispatch(setAlert(`Could not fetch change orders. Please try refreshing the page.`, AlertTypes.Error))
        throw error;
    }
}

export const getNextInvoiceNumber = async () => {
    try {
        let url = `${getBackendUrlForPath(API_ROUTES.INVOICES)}/lastid`
        const response = await axios.get(url, defaultApiOptions)
        return parseInt(response.data.id) + 1
    } catch (error) {
        throw error;
    }
}
