import BarLoader from "react-spinners/BarLoader";
import './LoadingAlert.css'

type Props = {
    title?: string
}

export const LoadingAlert = ({ title = "Loading..." }: Props) => {
    return (
        <div className={`app-background-container` }>
            <div className={`app-loading-container` }>
                <h3>{ title }</h3>
                <BarLoader color={"#1C5D99"} />
            </div>
        </div>
    )
}