import { LaborApi } from '../../../api/ModuleApi';
import { getLaborDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { Labor, LaborFormData } from '../../../interfaces/labor/LaborTypes';
import { EditForm } from '../../shared/forms/EditForm';
import { LaborForm } from './LaborForm';
import { toString } from "../../../helpers/DataHelpers"


export const EditLaborForm = ({ currentValue, onClose }: EditFormProps<Labor>) => {
    const initialValues: LaborFormData = {
        ...currentValue,
        tapingLaborHours: toString(currentValue.tapingLaborHours),
        tapingLaborRate: toString(currentValue.tapingLaborRate),
        tapingLaborHoursOvertime: toString(currentValue.tapingLaborHoursOvertime),
        hangFramingHours: toString(currentValue.hangFramingHours),
        hangFramingRate: toString(currentValue.hangFramingRate),
        hangFramingHoursOvertime: toString(currentValue.hangFramingHoursOvertime),
        wcRate: toString(currentValue.wcRate),
        weekEndingDate: formatDateToInput(currentValue.weekEndingDate)
    }

    const handleUpdate = (formData: LaborFormData) => {
        LaborApi.edit(currentValue.id, getLaborDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemove = () => {
        LaborApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <EditForm
            title="Labor"
            form={
                <LaborForm
                    formData={initialValues}
                    formType={FormType.Edit}
                    title="Labor"
                    onSubmit={ values => handleUpdate(values) }
                />
            }
            onClose={() => onClose() }
            handleDeleteAction={ () => handleRemove() }
        />
    )
}