import { User } from "../../interfaces/auth/UserTypes";
import { Bid } from "../../interfaces/bids/BidTypes";
import { ChangeOrder } from "../../interfaces/change/ChangeOrderTypes";
import { Customer } from "../../interfaces/customers/CustomerTypes";
import { Employee } from "../../interfaces/employees/EmployeeTypes";
import { Invoice } from "../../interfaces/invoices/InvoiceTypes";
import { Job } from "../../interfaces/jobs/JobTypes";
import { Labor } from "../../interfaces/labor/LaborTypes";
import { Material } from "../../interfaces/materials/MaterialTypes";
import { ModuleService, Service } from "../../interfaces/Service";
import { Supplier } from "../../interfaces/suppliers/SupplierTypes";
import { PrevailingWage } from "../../interfaces/wages/PrevailingWageTypes";

export interface ModuleStore {
    jobs: Service<ModuleService<Job>>,
    bids: Service<ModuleService<Bid>>,
    customers: Service<ModuleService<Customer>>,
    labor: Service<ModuleService<Labor>>,
    changeOrders: Service<ModuleService<ChangeOrder>>,
    users: Service<ModuleService<User>>,
    employees: Service<ModuleService<Employee>>,
    invoices: Service<ModuleService<Invoice>>,
    suppliers: Service<ModuleService<Supplier>>,
    materials: Service<ModuleService<Material>>,
    wages: Service<ModuleService<PrevailingWage>>,
}

export enum ModuleTypes {
    Job = "jobs",
    Bid = "bids",
    Customer = "customers",
    Labor = "labor",
    ChangeOrder = "changeOrders",
    User = "users",
    Employee = "employees",
    Invoice = "invoices",
    Supplier = "suppliers",
    Material = "materials",
    Wage = "wages",
}

export interface ModuleDataType {
    id: number
}

export interface ModuleDataLimited {
    id: number
    name: string
}

export enum ModuleActionTypes {
    STORE_DATA = "data/store",
    UPDATE_DATA = "data/update",
    REMOVE_DATA = "data/delete",
    CREATE_DATA = "data/create",
}

export interface StoreDataAction {
    type: ModuleActionTypes.STORE_DATA
    payload: {
        data: ModuleDataType[],
        moduleType: ModuleTypes
    }
}

export interface UpdateDataAction {
    type: ModuleActionTypes.UPDATE_DATA
    payload: {
        updateData: ModuleDataType,
        moduleType: ModuleTypes
    }
}

export interface CreateDataAction {
    type: ModuleActionTypes.CREATE_DATA
    payload: {
        newData: ModuleDataType,
        moduleType: ModuleTypes
    }
}

export interface RemoveDataAction {
    type: ModuleActionTypes.REMOVE_DATA
    payload: {
        dataId: number,
        moduleType: ModuleTypes
    }
}

export type ModuleActions = 
    | StoreDataAction
    | UpdateDataAction
    | CreateDataAction
    | RemoveDataAction