import { User } from "../../interfaces/auth/UserTypes"
import { AuthActionTypes, LoginAction, LogoutAction } from "./AuthTypes"

export const handleLogin = (u: User): LoginAction => {
    return {
        type: AuthActionTypes.LOGIN,
        payload: u
    }
}

export const handleLogout = (): LogoutAction => {
    return {
        type: AuthActionTypes.LOGOUT
    }
}