export const getBackendUrlForPath = (path: string) => {
    return process.env.REACT_APP_LOCAL_BACKEND + path
}

export const defaultApiOptions = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true
}

export enum API_ROUTES {
    BASE = "/",
    JOBS = "/jobs",
    BIDS = "/bids",
    CUSTOMERS = "/customers",
    EMPLOYEES = "/employees",
    LABOR = "/labor",
    CHANGE_ORDERS = "/change-orders",
    INVOICES = "/invoices",
    SUPPLIERS = "/suppliers",
    MATERIALS = "/materials",
    WAGES = "/prevailing-wages",
    VERIFY_USER = "/auth/getUser",
    LOGIN = "/auth/login",
    LOGOUT = "/auth/logout",
    PRINT_FORMAT_CHANGE_ORDER = "/print-format-change-order",
    PRINT_FORMAT_INVOICE = "/print-format-invoice",
    BIDS_REPORT = "/reports/bids",
    PROFIT_REPORT = "/reports/profit",
    LIST_USERS = "/auth/list",
    CREATE_USER = "/auth/create",
    EDIT_USER = "/auth/edit",
}