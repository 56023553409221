import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthApi } from '../../../api/AuthApi';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { User } from '../../../interfaces/auth/UserTypes';
import { UserForm, UserFormData } from '../shared/form/UserForm';
import './EditUserForm.css';

export const EditUserForm = ({ onClose, currentValue }: EditFormProps<User>) => {
    const initialData: UserFormData = {
        ...currentValue,
        password: '',
        repeatPassword: ''
    }
    console.log(initialData)

    const handleUpdateUser = (formData: UserFormData) => {
        const fd: Omit<User, 'id'> = {
            email: formData.email,
            role: formData.role,
            password: (formData.password && formData.repeatPassword) ? formData.password : undefined,
        }
        AuthApi.edit(currentValue.id, fd)
        .then(() => {
            onClose()
        })
    }

    return (
        <div className={"page-background-container"}>
            <div className={"edit-job-form-container edit-job-form-container-info"}>
                <div className="form-container-header">
                    <h1>Edit User</h1>
                    <FontAwesomeIcon icon={faTimes} size="2x" onClick={ e => onClose() } />
                </div>
                <UserForm
                    formData={initialData}
                    formType={FormType.Edit}
                    title="User"
                    onSubmit={ values => handleUpdateUser(values) }
                />
            </div>
        </div>
    )
}