import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { selectUsersData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import './UsersPage.css'
import { EditUserForm } from "./edit/EditUserForm"
import { NewUserForm } from "./new/NewUserForm"
import { AuthApi } from "../../api/AuthApi"
import { User } from "../../interfaces/auth/UserTypes"


const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5, type: 'number' },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'role', headerName: 'Role', flex: 0.5 }
];

export const UsersPage = () => {
    const userData = useSelector(selectUsersData)

    useEffect(() => {
        AuthApi.fetchAll()
    }, [])

    const getRows = (tableData: User[]) => {
        return tableData.map((user) => {
            return {
                ...user,
                rowObject: user
            }
        })
    }

    return (
        <DataPage
            filters={[]}
            title={"Users"}
            newButtonTitle={"New User"}
            pageData={userData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewUserForm}
            editForm={EditUserForm}
        />
    )
}