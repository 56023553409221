import { useEffect, useState } from 'react';
import { getJobCostingData } from '../../../api/IndexApi';
import { toNumber, getlaborCosts } from "../../../helpers/DataHelpers";
import { ChangeOrderCategory } from '../../../interfaces/change/ChangeOrderTypes';
import { Job, JobCostingData } from '../../../interfaces/jobs/JobTypes';
import { JobCostingChangeOrderTable } from './additional/JobCostingChangeOrderTable';
import './JobCosting.css';
import { JobCostingValueProps, JobCostingValues } from './JobCostingValues';

type Props = {
    job: Job
}

export const JobCosting = ({ job }: Props) => {
    const [data, setData] = useState<JobCostingData>({ changeOrders: [], materials: [], labor: [] })    
    const [jobCostingValues, setJobCostingValues] = useState<JobCostingValueProps[]>([]);
    const [toDateProfit, setToDateProfit] = useState<string>();

    useEffect(() => {
        fetchJobCostingData()
        // eslint-disable-next-line
    }, [job.id])

    const getChangeOrderTotal = () =>{
        let approvedChangeOrders = data.changeOrders.filter(co => co.approved);
        let changeOrderTotal = 0;

        for (let co of approvedChangeOrders) {
            for (let item of co.items) {
                changeOrderTotal += item.cost
            }
        }

        return changeOrderTotal;
    }

    const getAdjustedContractTotal = () =>{
        return (job.contractPrice ?? 0) + (getChangeOrderTotal() ?? 0);
    }

    const getProjectedProfit = () =>{
        const revenue = getAdjustedContractTotal() ?? 0;
        const costs = (job.materialCost ?? 0) + (job.hangFramingCost ?? 0) + (job.tapingLaborCost ?? 0) + (job.miscCost ?? 0) + getChangeOrderTotal();
        const profit = revenue - costs;
        const margin = 100 * profit / revenue;
        return Math.ceil(profit) + " (" + margin.toFixed(2) + "%)";
    }

    useEffect(()=>{

        const changeOrdersByCategory = (category: ChangeOrderCategory) => {
            return data.changeOrders
                .filter(co => co.approved)
                .reduce((acc, cur) => {//not sure how to tell TypeScript this will always be a number without the parseFloat
                return acc + parseFloat(cur[category]);
            },0)
        }
    
        const getAdjustedContract = () => {
            return{
                materialCost: (job.materialCost ?? 0) + changeOrdersByCategory(ChangeOrderCategory.MaterialCost),
                hangFramingCost: (job.hangFramingCost ?? 0) +  changeOrdersByCategory(ChangeOrderCategory.HF),
                tapingLaborCost: (job.tapingLaborCost ?? 0) +  changeOrdersByCategory(ChangeOrderCategory.Taping),
                miscCost: (job.miscCost ?? 0) +  changeOrdersByCategory(ChangeOrderCategory.Misc)
            }
        }
    
        const getToDate = () => {
            const materialCost = data.materials
            .reduce((acc, cur) => { 
                return acc + (cur.amount ?? 0);
            },0);
            let beforeLabors = {
                materialCost: materialCost,
                hangFramingCost: 0,
                tapingLaborCost: 0,
                miscCost: 0
            }
            let toDate = data.labor
            .reduce((acc, cur) => {
                const hangFramingCost = getlaborCosts(
                    toNumber(cur.hangFramingRate),
                    cur.hangFramingHours,
                    cur.hangFramingHoursOvertime,
                    cur.wcRate
                )
                const tapingCost = getlaborCosts(
                    toNumber(cur.tapingLaborRate),
                    cur.tapingLaborHours,
                    cur.tapingLaborHoursOvertime,
                    cur.wcRate
                )
                return {
                    hangFramingCost: acc.hangFramingCost + hangFramingCost.hours + hangFramingCost.ot + hangFramingCost.wc,
                    tapingLaborCost: acc.tapingLaborCost + tapingCost.hours + tapingCost.ot + tapingCost.wc,
                    miscCost: acc.miscCost + cur.miscExpenses,
                    materialCost: acc.materialCost//could avoid doing this operation by just adding the materialCost prop after
                }
            }, beforeLabors);
            toDate = {
                materialCost: toDate.materialCost,
                hangFramingCost: toDate.hangFramingCost,
                tapingLaborCost: toDate.tapingLaborCost,
                miscCost: toDate.miscCost
            }
            return toDate;
        }
    
        const getBalance = () => {
            const adjustedContract = getAdjustedContract();
            const toDate = getToDate();
            return {
                materialCost: adjustedContract.materialCost - toDate.materialCost,
                hangFramingCost: adjustedContract.hangFramingCost - toDate.hangFramingCost,
                tapingLaborCost: adjustedContract.tapingLaborCost - toDate.tapingLaborCost,
                miscCost: adjustedContract.miscCost - toDate.miscCost,
            }
        }    
    
        const getChangeOrder = () =>{                
            return {            
                materialCost: changeOrdersByCategory(ChangeOrderCategory.MaterialCost),
                hangFramingCost: changeOrdersByCategory(ChangeOrderCategory.HF),
                tapingLaborCost: changeOrdersByCategory(ChangeOrderCategory.Taping),
                miscCost: changeOrdersByCategory(ChangeOrderCategory.Misc)
            }
        }
    
        const getOriginalContract = () =>{                
            return {            
                materialCost: job.materialCost,
                hangFramingCost: job.hangFramingCost,
                tapingLaborCost: job.tapingLaborCost,
                miscCost: job.miscCost
            }
        }

        const getActualProfit = () =>{
            const toDate = getToDate();
            const revenue = getAdjustedContractTotal() ?? 0;
            const costs = toDate.materialCost + toDate.hangFramingCost + toDate.tapingLaborCost + toDate.miscCost;
            const profit = revenue - costs;
            const margin = 100 * profit / revenue;
            return Math.ceil(profit) + " (" + margin.toFixed(2) + "%)";
        }

        setToDateProfit(getActualProfit());

        setJobCostingValues([
            { contractData: getOriginalContract(), title: "Original Cost", manDayPrice: job.manDayPrice },
            { contractData: getChangeOrder(), title: "Change Order", manDayPrice: job.manDayPrice },
            { contractData: getAdjustedContract(), title: "Adjusted Cost", manDayPrice: job.manDayPrice },
            { contractData: getToDate(), title: "To Date", manDayPrice: job.manDayPrice },
            { contractData: getBalance(), title: "Balance", manDayPrice: job.manDayPrice },
        ])
    }, [data, job])

    const fetchJobCostingData = () => {        
        getJobCostingData(job.id)
        .then((res) => {
            setData(res)
        })
    }

    return (
        <div className="job-costing-container">
            <div className="job-costing-header">
                <p><strong>Man Day Price: ${job.manDayPrice}</strong></p>
                <p><strong>Man Days: {job.manDays}</strong></p>
            </div>
            <div className="job-costing-component-container">
                {
                    jobCostingValues.map((v, index) => (
                        <JobCostingValues 
                            contractData={ v.contractData } 
                            title={v.title} 
                            key={index}
                            manDayPrice={v.manDayPrice}
                        />
                    ))
                }
            </div>
            <div className="job-costing-footer">
                <div className="job-costing-footer-left">
                    <p><strong>Contract Amount: ${ job.contractPrice ?? "0" }</strong></p>
                    <p><strong>Adjusted Contract Amount: ${ getAdjustedContractTotal() }</strong></p>
                    {
                        job.updatedBy &&
                        <p>Updated By: { job.updatedBy }</p>
                    }
                    <p>Updated As Of: { job.updatedDate !== undefined ? new Date(job.updatedDate).toDateString() : "Not Available"}</p>
                </div>
                <div className="job-costing-footer-right">
                    <p>Projected Profit: ${getProjectedProfit()}</p>
                    <p>Actual Profit: ${toDateProfit}</p>
                </div>
            </div>
            <div className="divider"></div>
            <JobCostingChangeOrderTable 
                changeOrders={data.changeOrders} 
                refetchChangeOrders={fetchJobCostingData} 
                job={job}
            />
        </div>
    )
}