import { ModuleDataType } from "../store/modules/ModuleTypes";

export enum ServiceStatusValues {
    Loading,
    Loaded,
    Error
}

interface ServiceLoaded<T> {
    status: ServiceStatusValues.Loaded,
    payload: T
}

interface ServiceLoading {
    status: ServiceStatusValues.Loading
}

interface ServiceError {
    status: ServiceStatusValues.Error,
    error: Error
}

export interface ModuleService<T extends ModuleDataType> {
    data: T[]
}

export type Service<T> = 
    | ServiceLoaded<T>
    | ServiceLoading
    | ServiceError