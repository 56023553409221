import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"

type Props = {
    open: boolean
    handleClose: () => void
    handleDelete: () => void
    content?: string
}

export const DeleteDialog = ({ open, handleClose, handleDelete, content }: Props) => {
    return (
        <Dialog
            open={open}
        >
            <DialogTitle>Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { content ? content : "Are you sure you want to delete this item?" }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleDelete} autoFocus style={{ backgroundColor: "#e74c3c" }}>
                    <div style={{ color: "white "}}>Delete</div>
                </Button>
            </DialogActions>
        </Dialog>
    )
}