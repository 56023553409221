import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Divider, InputAdornment, TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormikProps, withFormik } from "formik"
import React, { useEffect, useState } from "react"
import { getNumberFromStringValue } from "../../../../src/helpers/IndexHelpers"
import { JobsApi } from "../../../api/ModuleApi"
import { ChangeOrderValidationSchema } from "../../../helpers/FormValidators"
import { ChangeOrder, ChangeOrderFormData, ChangeOrderItem, ChangeOrderItemFormData } from "../../../interfaces/change/ChangeOrderTypes"
import { FormProps } from "../../../interfaces/forms/FormTypes"
import { ModuleDataLimited } from "../../../store/modules/ModuleTypes"
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage"
import { PDFViewButton } from "../../shared/forms/PdfViewButton"
import { FormSubmitButton } from "../../shared/forms/FormSubmitButton"
import { ToggleInput } from "../../shared/inputs/ToggleInput"
import './ChangeOrderForm.css'
import { ChangeOrderItemInput } from "./item/ChangeOrderItemInput"

export const getChangeOrderFromUpdate = (request: ChangeOrderFormData, response:{id: number, items: ChangeOrderItem[]}) =>{
    const pdfData: ChangeOrder = {
        id: response.id,
        approved: request.approved!,
        contractNumber: request.contractNumber!,
        date: new Date(request.date!),
        hangFramingCost: request.hangFramingCost!,
        tapingCost: request.tapingCost!,
        materialCost: request.materialCost!,
        miscCost: request.miscCost!,
        items: response.items.map(i => {
            const item: ChangeOrderItem = {
                id: i.id,
                description: i.description,
                cost: i.cost
            }
            return item;
        }),
        job: request.job!,
        title: request.title!,
        additionalNotes: request.additionalNotes
    }
    return pdfData;
}

interface Props extends FormProps<ChangeOrderFormData> {
    showJobInput?: boolean
}

const InnerForm = (props: Props & FormikProps<ChangeOrderFormData>) => {
    const [jobData, setJobData] = useState<ModuleDataLimited[]>([])

    useEffect(() => {
        JobsApi.fetchAllLimited().then((r) => setJobData(r as ModuleDataLimited[]))
    }, [])

	const { 
		handleChange, 
        setFieldValue,
		isValid, 
		formType, 
        showJobInput,
		handleSubmit,
        isSubmitting,
        title,
		values,
        touched,
        errors,
        handleBlur,
        setFieldTouched 
	} = props;

    const handleAddItem = () => {
        const currentItems = [...values.items]
        currentItems.push({
            description: "",            
            cost: ""            
        })
        setFieldValue("items", currentItems) 
    }

    const handleRemoveItem = () => {
        const currentItems = [...values.items]
        if (currentItems.length > 1) {
            currentItems.pop()
        }
        setFieldValue("items", currentItems) 
    }

    const handleItemChange = (index: number, dataKey: keyof ChangeOrderItemFormData, value: any) => {
        var currentItems = [...values.items]
        currentItems[index] = {
            ...currentItems[index],
            [dataKey]: value
        }
        setFieldValue("items", currentItems)
    }

    const handleViewAndSubmit = () => {
        setFieldValue("generateChangeOrderPdf", true) 
        handleSubmit()
    }

    const getCostsTotal = () =>{
        return parseFloat(((values.hangFramingCost ? getNumberFromStringValue(values.hangFramingCost) : 0) + 
               (values.tapingCost ? getNumberFromStringValue(values.tapingCost) : 0) + 
               (values.materialCost ? getNumberFromStringValue(values.materialCost) : 0) + 
               (values.miscCost ? getNumberFromStringValue(values.miscCost) : 0)).toFixed(2)).toLocaleString();             
    }

    const getItemCostsTotal = () => {
        return parseFloat(values.items.reduce((acc,cur) => acc + getNumberFromStringValue(cur.cost), 0).toFixed(2)).toLocaleString();
    }

	return (
		<form>
            <div className="form-input-container">
                    <TextField
                        label="Change Order #"
                        name="number"
                        value= {values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.number && Boolean(errors.number)}
                    />
            </div>
            {
                showJobInput && <React.Fragment>
                    <div className="form-input-container">
                        <Autocomplete
                            options={jobData}
                            getOptionLabel={(job: ModuleDataLimited) => `${job.name} #${job.id}`}
                            fullWidth
                            onBlur={handleBlur}
                            value={values.job || null}
                            getOptionSelected={ (option, value) => option.id === value.id }
                            renderInput={(params) => 
                                <TextField 
                                    error={touched.job && Boolean(errors.job)}
                                    {...params} 
                                    label="Job" 
                            />}
                            onChange={ (e, v) => {
                                JobsApi.fetchById(v?.id ?? -1)
                                .then((response) => {
                                    setFieldValue("job", response) 
                                    setFieldTouched("job", true)
                                })
                            }}
                        />
                        { touched.job && Boolean(errors.job) && <FormErrorMessage errorMessage={errors.job} /> }
                    </div>
                    <div className="form-input-container">
                        <TextField
                            value={values.job?.customer?.name ?? ""}
                            label="Customer"
                            disabled={true}
                        />
                    </div>
                </React.Fragment>
            }
            <div className="form-input-container">
                <TextField 
                    label="Change Order Title" 
                    multiline
                    name="title"
                    value= {values.title} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.title && Boolean(errors.title)}
                />
				{ touched.title && Boolean(errors.title) && <FormErrorMessage errorMessage={errors.title} /> }
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Date
                </h1>
                <TextField 
                    value={ values.date } 
                    type="date"
                    name="date"
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Contract #" 
                    multiline
                    name="contractNumber"
                    value= {values.contractNumber} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.contractNumber && Boolean(errors.contractNumber)}
                />
				{ touched.contractNumber && Boolean(errors.contractNumber) && <FormErrorMessage errorMessage={errors.contractNumber} /> }
            </div>
            <Divider />
            <div className="form-input-container">
                <TextField 
                    label="Hang Framing Cost" 
                    multiline
                    name="hangFramingCost"
                    value= {values.hangFramingCost} 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.hangFramingCost && Boolean(errors.hangFramingCost)}
                />
				{ touched.hangFramingCost && Boolean(errors.hangFramingCost) && <FormErrorMessage errorMessage={errors.hangFramingCost} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Taping Cost" 
                    multiline
                    name="tapingCost"
                    value= {values.tapingCost} 
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
					onBlur={handleBlur}
					error={touched.tapingCost && Boolean(errors.tapingCost)}
                />
				{ touched.tapingCost && Boolean(errors.tapingCost) && <FormErrorMessage errorMessage={errors.tapingCost} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Material Cost" 
                    multiline
                    name="materialCost"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    value= {values.materialCost} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.materialCost && Boolean(errors.materialCost)}
                />
				{ touched.materialCost && Boolean(errors.materialCost) && <FormErrorMessage errorMessage={errors.materialCost} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Misc Cost" 
                    multiline
                    name="miscCost"
                    value= {values.miscCost} 
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
					onBlur={handleBlur}
					error={touched.miscCost && Boolean(errors.miscCost)}
                />
				{ touched.miscCost && Boolean(errors.miscCost) && <FormErrorMessage errorMessage={errors.miscCost} /> }
            </div>
            <div style={{ marginBottom: "20px" }}>Total: ${getCostsTotal()}</div>          
            <Divider />
            <h1 className="form-input-label change-order-items-header" style={{ marginLeft: 0, marginTop: "20px" }}>
                <strong>Change Order Items</strong>
                <div>
                    <FontAwesomeIcon 
                        icon={faPlusCircle} 
                        onClick={handleAddItem}
                        className="change-order-item-button" 
                        color="#298BE5" 
                    />
                    {
                        values.items.length > 1 &&
                        <FontAwesomeIcon 
                            icon={faMinusCircle} 
                            className="change-order-item-button" 
                            color="#e74c3c" 
                            onClick={handleRemoveItem}
                        />
                    }
                </div>
            </h1>
            {
                values.items.length ?
                <div>
                {values.items.map((item, index) => (
                    <ChangeOrderItemInput 
                        key={index.toString()}
                        handleBlur={handleBlur}
                        handleChange={handleItemChange}
                        item={item}
                        index={index}
                    />
                ))}
                <div style={{ marginBottom: "20px" }}>Total: ${getItemCostsTotal()}</div>   
                </div>
                :
                <em><p>No Change Order Items</p></em>
            }
            <Divider />
            <ToggleInput 
                label="Approved?"
                handleAnswerChange={ answer => {
                    setFieldValue("approved", answer)
                    setFieldTouched("approved", true)
                }}
                value={values.approved}
            />
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
            <div className = 'form-buttons-container'>
                <PDFViewButton 
                    isValid={isValid && values.approved === true} 
                    isSubmitting={isSubmitting}
                    handleSubmit={handleViewAndSubmit}
                />
                <FormSubmitButton 
                    isValid={isValid} 
                    isSubmitting={isSubmitting}
                    title={title}
                    formType={formType}	
                    handleSubmit={handleSubmit}
                />
            </div>
		</form>
	);
};

export const ChangeOrderForm = withFormik<Props, ChangeOrderFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: ChangeOrderValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
        let isViewPdf = false
		props.onSubmit(values)
        if (values.generateChangeOrderPdf) isViewPdf = true
        setSubmitting(isViewPdf)
	},
	displayName: "Change Order Form",
})(InnerForm);