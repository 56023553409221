import { InputAdornment, TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormikProps, withFormik } from "formik"
import { BidValidationSchema } from "../../../../helpers/FormValidators"
import { Customer } from "../../../../interfaces/customers/CustomerTypes"
import { FormProps } from "../../../../interfaces/forms/FormTypes"
import { BidFormData } from "../../../../interfaces/bids/BidTypes"
import { FormErrorMessage } from "../../../shared/forms/FormErrorMessage"
import { FormSubmitButton } from "../../../shared/forms/FormSubmitButton"
import { YesNoInput } from  "../../../shared/inputs/YesNoInput";
import { ModuleDataLimited } from "../../../../store/modules/ModuleTypes"
import { useEffect, useState } from "react"
import { CustomersApi } from "../../../../api/ModuleApi"

const InnerForm = (props: FormProps<BidFormData> & FormikProps<BidFormData>) => {
    const [customerData, setCustomerData] = useState<ModuleDataLimited[]>([])

    useEffect(() => {
        CustomersApi.fetchAllLimited().then((r) => setCustomerData(r as ModuleDataLimited[]))
    }, [])

	const { 
		handleChange, 
        setFieldValue,
        setFieldTouched,
		isValid, 
		formType, 
		handleSubmit,
        isSubmitting,
        title,
		values,
        handleBlur,
        touched,
        errors
	} = props;
	return (
		<form>
            <div className="form-input-container">
                <TextField 
                    label="Bid Name" 
                    value={values.name} 
                    name="name" 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.name && Boolean(errors.name)}
                />
				{ touched.name && Boolean(errors.name) && <FormErrorMessage errorMessage={errors.name} /> }
            </div>
            <div className="form-input-container">
                <Autocomplete
                    options={customerData}
                    getOptionLabel={(customer: Customer) => `${customer.name} #${customer.id}`}
                    value={ values.customer }
                    renderInput={(params) => 
                        <TextField 
                            name="customer" 
                            {...params} 
                            label="Customer" 
                            error={touched.customer && Boolean(errors.customer)}
                        />
                    }
                    onChange={ (e, v) => {
                        CustomersApi.fetchById(v?.id ?? -1)
                        .then((response) => {
                            setFieldValue("customer", v) 
                            setFieldTouched("customer", true)
                        })
                    }}
                    onBlur={handleBlur}
                />
                { touched.customer && Boolean(errors.customer) && <FormErrorMessage errorMessage={errors.customer} /> }
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Bid Awarded
                </h1>
                <YesNoInput 
                    value={ values.awarded } 
                    handleAnswerChange={ value => {
                        setFieldValue("awarded", value);
                    }}
                />
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Date
                </h1>
                <TextField 
                    value={values.date} 
                    type="date"
                    name="date"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.date && Boolean(errors.date)}
                />
				{ touched.date && Boolean(errors.date) && <FormErrorMessage errorMessage={errors.date} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Bid Amount" 
                    value= {values.bidAmount} 
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    name="bidAmount"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.bidAmount && Boolean(errors.bidAmount)}
                />
				{ touched.bidAmount && Boolean(errors.bidAmount) && <FormErrorMessage errorMessage={errors.bidAmount} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Award Amount" 
                    value= {values.awardAmount} 
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    name="awardAmount"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.awardAmount && Boolean(errors.awardAmount)}
                />
				{ touched.awardAmount && Boolean(errors.awardAmount) && <FormErrorMessage errorMessage={errors.awardAmount} /> }
            </div>
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const BidForm = withFormik<FormProps<BidFormData>, BidFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: BidValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Bid Form",
})(InnerForm);