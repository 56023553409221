import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ChangeOrdersApi } from "../../api/ModuleApi"
import { getChangeOrderCostsTotal } from "../../helpers/DataHelpers"
import { doesValueExist } from "../../helpers/IndexHelpers"
import { ChangeOrder } from "../../interfaces/change/ChangeOrderTypes"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectChangeOrderData } from "../../store/modules/ModuleSelectors"
import { LoadingAlert } from "../alerts/LoadingAlert"
import { DataPage } from "../shared/pages/DataPage"
import { CheckForNullCell } from "../shared/table/CheckForNullCell"
import { generateChangeOrderPdf } from "./assets/generateChangeOrderPdf"
import { EditChangeOrder } from "./edit/EditChangeOrder"
import { NewChangeOrder } from "./new/NewChangeOrder"

const filters: SearchFilter[] = [
    { title: "#", keys: ["number"], isActive: true },
    { title: "Customer", keys: ["job.customer.name", "job.customer.id"], isActive: true },
    { title: "Job", keys: ["job.name", "job.id"], isActive: true },
]


export const ChangeOrderPage = () => {    
    const pageData = useSelector(selectChangeOrderData)
    const [isLoading, setLoading] = useState(false)

    const pdfExporter = {
        field: "",
        headerName: "Export",
        flex: 0.5,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
            const onClick = () => {     
                setLoading(true)
                generateChangeOrderPdf(params.row.rowObject)
                .then(() => {
                    setLoading(false)
                })
            };
            return (
                <div style={{ width: "100%", height: "100%"}} onClick={onClick}>
                    <FontAwesomeIcon icon={faFilePdf} style={{color: "#123E66"}}/>
                </div>
            )
        }
    }

    const columns = [
        { field: 'number', headerName: '#', flex: .5, type: 'string' },
        { field: 'cost', headerName: 'Cost', flex: 0.6, type: "number" },
        { field: 'title', headerName: 'Title', flex: 0.8 },
        { field: 'job', headerName: 'Job', flex: 1.1 },
        { field: 'customer', headerName: 'Customer', flex: 1.5, renderCell: CheckForNullCell },
        { field: 'date', headerName: 'Date', flex: 0.5, type: "date" },
        pdfExporter
    ];

    useEffect(() => {
        ChangeOrdersApi.fetchAll()
    }, [])    

    const getRows = (tableData: ChangeOrder[]) => {
        return tableData.map((co) => {
            return {
                id: co.id,
                number: co.number,
                title: co.title,
                cost: `$${getChangeOrderCostsTotal(co)}`,
                customer: (doesValueExist(co.job.customer) ? `${co.job.customer!.name} #${co.job.customer!.id}` : null),
                job: `${co.job.name} #${co.job.id}`,
                date: co.date ? new Date(co.date).toLocaleDateString() : '',
                rowObject: co
            }
        })
    }
    
    return (                        
        <>
            <DataPage filters={filters}
                title={"Change Orders"}
                newButtonTitle={"New Change Order"}
                pageData={pageData}
                tableColumns={columns}
                getRows={ tableData => getRows(tableData) }
                newForm={ NewChangeOrder }            
                editForm={ EditChangeOrder }
            />                
            { isLoading && <LoadingAlert title="Generating PDF..." /> }
        </>
    )
}
