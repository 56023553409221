import { User } from "../../interfaces/auth/UserTypes";

export enum AuthActionTypes {
    LOGIN = "auth/login",
    LOGOUT = "auth/logout",
}

export interface LoginAction {
    type: AuthActionTypes.LOGIN
    payload: User
}

export interface LogoutAction {
    type: AuthActionTypes.LOGOUT
}

export type AuthActions = 
    | LoginAction
    | LogoutAction