type PDFButtonProps = {
    isValid: boolean,
    isSubmitting: boolean,
    handleSubmit: () => void,
    text?: string
}

export const PDFViewButton = ({ isValid, isSubmitting, handleSubmit, text = "VIEW PDF" }: PDFButtonProps) => {
    return (
        <button 
            type="submit"
            className="pdf-view-button"
            disabled={isSubmitting}
            onClick={handleSubmit}
        >
            { text }
        </button>
    )
}