import { StoreState } from ".."
import { ServiceStatusValues } from "../../interfaces/Service"
import { User } from "../../interfaces/auth/UserTypes"

export const isLoggedIn = (state: StoreState): boolean => {
    if (state.AuthReducer.status === ServiceStatusValues.Loaded && state.AuthReducer.payload.currentUser !== null) {
        return true
    } else { return false }
}

export const currentUser = (state: StoreState): User | null => {
    if (state.AuthReducer.status === ServiceStatusValues.Loaded && state.AuthReducer.payload.currentUser !== null) {
        return state.AuthReducer.payload.currentUser
    } else { return null }
}

export const selectAuthStatus = (state: StoreState): ServiceStatusValues => {
    return state.AuthReducer.status
}