import { EmployeesApi } from '../../../api/ModuleApi';
import { getEmployeeDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { Employee, EmployeeFormData } from '../../../interfaces/employees/EmployeeTypes';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { EditForm } from '../../shared/forms/EditForm';
import { EmployeeForm } from './EmployeeForm';

export const EditEmployeeForm = ({ currentValue, onClose }: EditFormProps<Employee>) => {
    const initialValues: EmployeeFormData = {
        ...currentValue,
        hangFramingRate: currentValue.hangFramingRate ? currentValue.hangFramingRate.toString() : "",
        tapingLaborRate: currentValue.tapingLaborRate ? currentValue.tapingLaborRate.toString() : "",
        hireDate: formatDateToInput(currentValue.hireDate),
        birthDate: formatDateToInput(currentValue.birthDate),
    }

    const handleUpdate = (formData: EmployeeFormData) => {
        EmployeesApi.edit(currentValue.id, getEmployeeDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemove = () => {
        EmployeesApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <EditForm
            title="Employee"
            form={
                <EmployeeForm
                    formData={initialValues}
                    formType={FormType.Edit}
                    title="Employee"
                    onSubmit={ values => handleUpdate(values) }
                />
            }
            onClose={onClose}
            handleDeleteAction={ handleRemove }
        />
    )
}