import { AuthApi } from '../../../api/AuthApi';
import { User } from '../../../interfaces/auth/UserTypes';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { UserForm, UserFormData } from '../shared/form/UserForm';

export const NewUserForm = ({ onClose }: NewFormProps) => {
    const initialFormData: UserFormData = { 
        email: '',
        password: '',
        repeatPassword: '',
        role: 'staff'
    }

    const handleClose = () => {
        onClose()
    }

    const handleNewUser = (formData: UserFormData) => {
        const fd: Omit<User, 'id'> = {
            email: formData.email,
            role: formData.role,
            password: (formData.password && formData.repeatPassword) ? formData.password : undefined,
        }
        AuthApi.create(fd)
        .then(() => {
            handleClose()
        })
    }

    return (
        <NewForm
            title="User"
            form={
                <UserForm
                    formData={initialFormData}
                    formType={FormType.New}
                    title="User"
                    onSubmit={ values => handleNewUser(values) }
                />
            }
            onClose={() => handleClose()}
        />
    )
}