import { FormType } from "../../../interfaces/forms/FormTypes"

type Props = {
    isValid: boolean,
    isSubmitting: boolean,
    formType: FormType,
    title: string,
    handleSubmit: () => void
}

export const FormSubmitButton = ({ isValid, isSubmitting, formType, title, handleSubmit }: Props) => {
    return (
        <button 
            type="submit"
            className={"form-create-button" + (!isValid ? " form-create-button--disabled" : "")}
            disabled={isSubmitting || !isValid}
            onClick={handleSubmit}
        >
            { 
                (formType === FormType.New ? "CREATE" : "UPDATE") + 
                ` ${title.toUpperCase()}` 
            }
        </button>
    )
}