import { EmployeesApi } from '../../../api/ModuleApi';
import { getEmployeeDto } from '../../../helpers/DtoGenerators';
import { EmployeeFormData } from '../../../interfaces/employees/EmployeeTypes';
import { FormType } from '../../../interfaces/forms/FormTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { EmployeeForm } from './EmployeeForm';

type Props = {
    onClose: () => void
}

export const NewEmployeeForm = ({ onClose }: Props) => {
    const handleNew = (formData: EmployeeFormData) => {
        EmployeesApi.create(getEmployeeDto(formData))
        .then(() => {
            onClose()
        })
    }

    return (
        <NewForm
            title="Employee"
            form={
                <EmployeeForm
                    formData={{}}
                    formType={FormType.New}
                    title="Employee"
                    onSubmit={ values => handleNew(values) }
                />
            }
            onClose={onClose}
        />
    )
}