import { ValueFormatterParams } from "@material-ui/data-grid"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectBidsData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import { CheckForNullCell } from "../shared/table/CheckForNullCell"
import { EditBidForm } from "./edit/EditBidForm"
import './BidsPage.css'
import { NewBidForm } from "./new/NewBidForm"
import { BidsApi } from "../../api/ModuleApi"
import { Bid } from "../../interfaces/bids/BidTypes"

const filters: SearchFilter[] = [
    { title: "Customer", keys: ["customer.name", "customer.id"], isActive: true },
    { title: "Bid", keys: ["name", "id"], isActive: true },
]

const numberFormat = (n: number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(n);

const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5, type: 'number' },
    { field: 'name', headerName: 'Bid Name', flex: 2 },
    { field: 'customer', headerName: 'Customer', flex: 2, renderCell: CheckForNullCell },
    { 
        field: 'awarded', 
        headerName: 'Awarded', 
        flex: 1.5,
        valueFormatter: (params: ValueFormatterParams) => params.value === true ? 'Yes' : 'No',
    },
    { 
        field: 'bidAmount', 
        headerName: 'Bid Amount', 
        flex: 2,
        valueFormatter: (params: ValueFormatterParams) => {
            if (params.value == null) {
              return '--';
            }
            return numberFormat(params.value as number);
        },
    },
    { 
        field: 'awardAmount', 
        headerName: 'Awarded Amount', 
        flex: 2,
        valueFormatter: (params: ValueFormatterParams) => {
            if (params.value == null) {
              return '--';
            }
            return numberFormat(params.value as number);
        },
    },
    { 
        field: 'date', 
        headerName: 'Date', 
        flex: 1.5,
        valueFormatter: (params: ValueFormatterParams) => {
            if (!params.value) return ''
            const date = new Date(params.value as string | Date);
            return new Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(date);
        },
    }
];

export const BidsPage = () => {
    const bidData = useSelector(selectBidsData)

    useEffect(() => {
        BidsApi.fetchAll()
    }, [])

    const getRows = (tableData: Bid[]) => {
        return tableData.map((bid) => {
            return {
                ...bid,
                customer: bid.customer ? `${bid.customer.name} #${bid.customer.id}` : null,
                rowObject: bid
            }
        })
    }

    return (
        <DataPage
            filters={filters}
            title={"Bids"}
            newButtonTitle={"New Bid"}
            pageData={bidData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewBidForm}
            editForm={EditBidForm}
        />
    )
}