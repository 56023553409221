import { ModuleReducerHelper } from "../../helpers/ReduxHelpers"
import { Service, ServiceStatusValues } from "../../interfaces/Service"
import { ModuleActions, ModuleActionTypes, ModuleDataType, ModuleStore } from "./ModuleTypes"

const defaultStatus: Service<ModuleDataType> = { status: ServiceStatusValues.Loading }
const initialState: ModuleStore = {
    jobs: defaultStatus,
    bids: defaultStatus,
    customers: defaultStatus,
    users: defaultStatus,
    employees: defaultStatus, 
    suppliers: defaultStatus, 
    materials: defaultStatus, 
    wages: defaultStatus, 
    invoices: defaultStatus, 
    changeOrders: defaultStatus, 
    labor: defaultStatus, 
}
const reducer = new ModuleReducerHelper()

export const ModuleReducer = (state: ModuleStore = initialState, action: ModuleActions) => {
    switch (action.type) {
        case ModuleActionTypes.STORE_DATA:
            return {
                ...state,
                [action.payload.moduleType]: reducer.getStateWithStoredData(action.payload.data)
            } as ModuleStore
        case ModuleActionTypes.UPDATE_DATA:
            return {
                ...state,
                [action.payload.moduleType]: reducer.getStateWithUpdatedData(action.payload.updateData, state[action.payload.moduleType])
            } as ModuleStore
        case ModuleActionTypes.CREATE_DATA:
            return {
                ...state,
                [action.payload.moduleType]: reducer.getStateWithNewData(action.payload.newData, state[action.payload.moduleType])
            } as ModuleStore
        case ModuleActionTypes.REMOVE_DATA:
            return {
                ...state,
                [action.payload.moduleType]: reducer.getStateWithRemovedData(action.payload.dataId, state[action.payload.moduleType])
            } as ModuleStore
        default:
            return state
    }
}