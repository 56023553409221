export const loadMapsAPI = (loaded: React.MutableRefObject<boolean>) => {
	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
		loadScript(
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyCMSWV94pEKF69pfzMKjzhhiuzO5z6TCSo&libraries=places',
			document.querySelector('head'),
			'google-maps',
		);
		}

		loaded.current = true;
	}
}

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}