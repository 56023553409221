import { EditJobPage } from "./EditJobForm"

type Props = {
    editPage: EditJobPage,
    onPageChange: (p: EditJobPage) => void
}

export const EditJobTabs = ({ editPage, onPageChange }: Props) => {
    return (
        <div className="edit-job-tab-container">
            <div 
                className={`edit-job-tab-item` + (editPage === EditJobPage.Info ? " edit-job-tab-item--selected" : "")}
                onClick={ e => onPageChange(EditJobPage.Info) }
            >
                Job Info
            </div>
            <div 
                className={`edit-job-tab-item` + (editPage === EditJobPage.Costing ? " edit-job-tab-item--selected" : "")}
                onClick={ e => onPageChange(EditJobPage.Costing) }
            >
                Job Costing
            </div>
        </div>
    )
}