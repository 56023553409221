import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { DeleteDialog } from "../../dialogs/DeleteDialog"

type Props = {
    onClose: () => void,
    form: JSX.Element,
    title: string,
    handleDeleteAction: () => void
}

export const EditForm = ({ onClose, form, title, handleDeleteAction }: Props) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    return (
        <div className={"page-background-container"}>
            <div className="form-container">
                <div className="form-container-header">
                    <h1>Edit { title }</h1>
                    <FontAwesomeIcon icon={faTimes} size="2x" onClick={ e => onClose() } />
                </div>
                { form }
                <div 
                    className="form-delete-button"
                    onClick={ e => setDeleteDialogOpen(true) }
                >
                    <p>Delete Entry</p>
                </div>
                <DeleteDialog
                    open={deleteDialogOpen}
                    handleClose={ () => setDeleteDialogOpen(false) }
                    handleDelete={ () => {
                        setDeleteDialogOpen(false)
                        handleDeleteAction()
                    }}
                />
            </div>
        </div>
    )
}