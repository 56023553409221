import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { SuppliersApi } from "../../api/ModuleApi"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { Supplier } from "../../interfaces/suppliers/SupplierTypes"
import { selectSupplierData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import { EditSupplierForm } from "./form/EditSupplierForm"
import { NewSupplierForm } from "./form/NewSupplierForm"

const filters: SearchFilter[] = [
    { title: "#", keys: [ "id" ], isActive: true },
    { title: "Name", keys: [ "name" ], isActive: true },
    { title: "Contact", keys: [ "contact1" ], isActive: true },
    { title: "Phone", keys: [ "phone" ], isActive: true },
]

const columns = [
    { field: 'id', headerName: '#', flex: 0.5, type: 'number' },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'contact', headerName: 'Contact', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 }
];

export const SuppliersPage = () => {
    const pageData = useSelector(selectSupplierData)

    useEffect(() => {
        SuppliersApi.fetchAll()
    }, [])

    const getRows = (tableData: Supplier[]) => {
        return tableData.map((supplier) => {
            return {
                id: supplier.id,
                name: supplier.name,
                phone: supplier.phone,
                contact: supplier.contact1,
                rowObject: supplier
            }
        })
    }

    return (
        <DataPage
            filters={filters}
            title={"Suppliers"}
            newButtonTitle={"New Supplier"}
            pageData={pageData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewSupplierForm}
            editForm={EditSupplierForm}
        />
    )
}