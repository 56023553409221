import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"

type Props = {
    open: boolean
    handleClose: () => void
    handleLogOut: () => void
    content?: string
}

export const LogOutDialog = ({ open, handleClose, handleLogOut, content }: Props) => {
    return (
        <Dialog
            open={open}
        >
            <DialogTitle>Log Out</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { content ? content : "Are you sure you want to log out?" }
                </DialogContentText>
            </DialogContent>
            <DialogActions>                
                <Button onClick={handleLogOut} autoFocus style={{ backgroundColor: "var(--light-blue)" }}>
                    <div style={{ color: "white "}}>Yes</div>
                </Button>
                <Button onClick={handleClose} color="primary">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )
}