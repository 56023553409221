import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GridCellParams } from "@material-ui/data-grid"

export const CheckForNullCell = (params: GridCellParams) => {
    return (
        <div className={params.value === null ? `table-cell-error` : ""}>
            { params.value === null && <FontAwesomeIcon icon={faExclamationTriangle} /> }
            { params.value === null ? "Empty Field" : params.value }
        </div>
    )
}