import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
    value?: boolean,
    handleAnswerChange: (value: boolean) => void
}

export const YesNoInput = ({ value, handleAnswerChange }: Props) => {
    return (
        <div className="checkbox-container">
            <div 
                className={"form-checkbox-option" + (value ? " form-checkbox-option--active" : "")}
                onClick={ e => handleAnswerChange(true) }
            >
                { (typeof value !== "undefined" && value) && <FontAwesomeIcon icon={faCheck} className="form-checkbox-check" /> }
                Yes
            </div>
            <div 
                className={"form-checkbox-option" + ((typeof value !== "undefined" && !value) ? " form-checkbox-option--active" : "")}
                onClick={ e => handleAnswerChange(false) }
            >
                { (typeof value !== "undefined" && !value) && <FontAwesomeIcon icon={faCheck} className="form-checkbox-check" /> }
                No
            </div>
        </div>
    )
}