import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { JobStatus } from "../../../../interfaces/jobs/JobTypes"

type Props = {
    jobStatus: JobStatus,
    handleJobStatusChange: (status: JobStatus) => void
}

export const StatusInput = ({ jobStatus, handleJobStatusChange }: Props) => {
    return (
        <div className="checkbox-container">
            <div 
                className={"form-checkbox-option" + (jobStatus === JobStatus.Complete ? " form-checkbox-option--active" : "")}
                onClick={ e => handleJobStatusChange(JobStatus.Complete) }
            >
                { jobStatus === JobStatus.Complete && <FontAwesomeIcon icon={faCheck} className="form-checkbox-check" /> }
                Completed
            </div>
            <div 
                className={"form-checkbox-option" + (jobStatus === JobStatus.InProgress ? " form-checkbox-option--active" : "")}
                onClick={ e => handleJobStatusChange(JobStatus.InProgress) }
            >
                { jobStatus === JobStatus.InProgress && <FontAwesomeIcon icon={faCheck} className="form-checkbox-check" /> }
                In Progress
            </div>
        </div>
    )
}