import { CreateDataAction, ModuleActionTypes, ModuleDataType, ModuleTypes, RemoveDataAction, StoreDataAction, UpdateDataAction } from "./ModuleTypes"

export const dataLoaded = (data: ModuleDataType[], moduleType: ModuleTypes): StoreDataAction => {
    return {
        type: ModuleActionTypes.STORE_DATA,
        payload: {
            data: data,
            moduleType: moduleType
        }
    }
}

export const updateModuleData = (data: ModuleDataType, moduleType: ModuleTypes): UpdateDataAction => {
    return {
        type: ModuleActionTypes.UPDATE_DATA,
        payload: {
            updateData: data,
            moduleType: moduleType
        }
    }
}

export const newModuleData = (data: ModuleDataType, moduleType: ModuleTypes): CreateDataAction => {
    return {
        type: ModuleActionTypes.CREATE_DATA,
        payload: {
            newData: data,
            moduleType: moduleType
        }
    }
}

export const removeModuleData = (id: number, moduleType: ModuleTypes): RemoveDataAction => {
    return {
        type: ModuleActionTypes.REMOVE_DATA,
        payload: {
            dataId: id,
            moduleType: moduleType
        }
    }
}