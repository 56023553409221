import { Link } from 'react-router-dom'
import './NotFound.css'

type Props = {
    notFoundButton?: {
        title: string
        redirect: string
    }
}

export const NotFound = ({ notFoundButton }: Props) => {
    return (
        <div className="not-found-container">
            <h1>404</h1>
            <h4>The page you are looking for can't be found.</h4>
            {
                notFoundButton &&
                <Link to={notFoundButton.redirect} style={{marginTop: "30px"}}>
                    { notFoundButton.title }
                </Link>
            }
        </div>
    )
}