import { TextField } from "@material-ui/core"
import { FormikProps, withFormik } from "formik";
import { SupplierValidationSchema } from "../../../helpers/FormValidators";
import { FormProps } from "../../../interfaces/forms/FormTypes"
import { SupplierFormData } from "../../../interfaces/suppliers/SupplierTypes"
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage";
import { FormSubmitButton } from "../../shared/forms/FormSubmitButton";
import { AddressInput } from "../../shared/inputs/AddressInput"

const InnerForm = (props: FormProps<SupplierFormData> & FormikProps<SupplierFormData>) => {
	const { 
		handleChange, 
        setFieldValue,
		handleSubmit,
		values,
        isValid,
        isSubmitting,
        title,
        handleBlur,
        errors,
        touched,
        formType
	} = props;
	return (
		<form>
            <div className="form-input-container">
                <TextField 
                    label="Name" 
                    name="name"
                    value= {values.name} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.name && Boolean(errors.name)}
                />
				{ touched.name && Boolean(errors.name) && <FormErrorMessage errorMessage={errors.name} /> }
            </div>
            <div className="form-input-container">
                <AddressInput address={ values.address } setAddress={ s => setFieldValue("address", s) } />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Phone" 
                    name="phone"
                    value= {values.phone} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Contact" 
                    name="contact1"
                    value= {values.contact1} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Contact" 
                    name="contact2"
                    value= {values.contact2} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const SupplierForm = withFormik<FormProps<SupplierFormData>, SupplierFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: SupplierValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Supplier Form",
})(InnerForm);