import { ChangeOrdersApi } from '../../../api/ModuleApi';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getChangeOrderDto } from "../../../helpers/DtoGenerators";
import { ChangeOrderFormData } from "../../../interfaces/change/ChangeOrderTypes";
import { FormType } from "../../../interfaces/forms/FormTypes";
import { Job } from "../../../interfaces/jobs/JobTypes";
import { ChangeOrderForm, getChangeOrderFromUpdate } from "../form/ChangeOrderForm"
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { generateChangeOrderPdf } from '../assets/generateChangeOrderPdf';

type Props = {
    job?: Job,
    onClose: (update?:any) => void
}

export const NewChangeOrder = ({ onClose, job }: Props) => {
    const handleNewChangeOrder = (formData: ChangeOrderFormData) => {
        console.log('Request: ID: ' + JSON.stringify(formData))
        ChangeOrdersApi.create(getChangeOrderDto(formData))
        .then((res) => {
            if(formData.generateChangeOrderPdf){
                //@ts-ignore Response interfaces?
                const pdfData = getChangeOrderFromUpdate(formData, res)
                generateChangeOrderPdf(pdfData);
            }
            else{
                onClose(res)
            }
        })
    }

    return (
        <div className="page-background-container">
            <div className="page-alert-super-container">
                <div className="page-alert-container" style={{ width: "50vw" }}>
                    <div className="new-change-order-header-container">
                        <h1>New Change Order</h1>
                        <FontAwesomeIcon icon={faTimes} size="2x" onClick={ e => onClose() } />
                    </div>
                    <ChangeOrderForm
                        formData={ job ? 
                            { job: job, items: [], date: formatDateToInput(new Date()) } :
                            { items: [], date: formatDateToInput(new Date()) }
                        }
                        formType={FormType.New}
                        showJobInput={!Boolean(job)}
                        title="Change Order"
                        onSubmit={ values => handleNewChangeOrder(values) }
                    />
                </div>
            </div>
        </div>
    )
}