import { DataGrid, GridColumns, GridRowsProp } from "@material-ui/data-grid";
import Fuse from 'fuse.js';
import { useEffect, useState } from "react";
import { JobStatus } from "../../../interfaces/jobs/JobTypes";
import { ExportToolbar } from "./ExportToolbar";

type Props<T> = {
    onRowSelect: (data: T) => void,
    searchQuery?: string,
    searchKeys?: string[],
    data: any[],
    columns: GridColumns,
    getRows: (tableData: any[]) => GridRowsProp,
    toolbar?: () => JSX.Element,
    height?: number
}

export const DataTable = (<T extends object>({ 
        data, 
        onRowSelect, 
        searchQuery, 
        searchKeys, 
        columns,
        getRows,
        toolbar,
        height
    }: Props<T>) => {
    const [tableData, setTableData] = useState<T[]>(data)
    const [filterModelItems, setFilterModelItems] = useState([
        { columnField: 'status', operatorValue: 'contains', value: JobStatus.InProgress },
    ]);

    useEffect(() => {
        // With no search term, filter in progress only and keep the data
        if (typeof searchKeys === "undefined" || !searchQuery || searchQuery === "") {
            setFilterModelItems([
                { columnField: 'status', operatorValue: 'contains', value: JobStatus.InProgress },
            ]);
            return setTableData(data);
        }

        // When searching, remove the filter
        setFilterModelItems([]);

        const fuse = new Fuse(data, { keys: searchKeys ?? [], threshold: 0.2 })
        const filteredJobs = fuse.search(searchQuery).map(x => x.item) as T[]
        setTableData(filteredJobs)
    }, [searchQuery, searchKeys, data])

    return (
        <div style={{ flexGrow: 2, width: '100%', margin: '0px', height: height ?? "" }}>
            <DataGrid  
                rows={getRows(tableData)} 
                columns={columns} 
                pageSize={50}
                onRowClick={ params => onRowSelect(params.row.rowObject) }
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                disableSelectionOnClick={true}
                disableDensitySelector={true}
                density={"comfortable"}
                components={{
                    Toolbar: toolbar ?? ExportToolbar
                }}
                filterModel={{ items: filterModelItems }}
            />
        </div>
    );
})