export enum FormType {
    New = "New",
    Edit = "Edit"
}

export type NewFormProps = {
    onClose: () => void
}

export type EditFormProps<T> = {
    onClose: () => void
    currentValue: T
}

export interface FormProps<T> {
	title: string;
	formType: FormType;
	formData: T;
	onSubmit: (values: T) => void
}