import { AlertTypes } from "../../interfaces/alert/AlertState"
import { AlertActionTypes, RemoveAlertAction, SetAlertAction } from "./AlertTypes"

export const setAlert = (text: string, type: AlertTypes): SetAlertAction => {
    return {
        type: AlertActionTypes.SET_ALERT,
        payload: {
            text: text,
            type: type
        }
    }
}

export const removeAlert = (): RemoveAlertAction => {
    return {
        type: AlertActionTypes.REMOVE_ALERT
    }
}