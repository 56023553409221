import { LaborApi } from '../../../api/ModuleApi';
import { getLaborDto } from '../../../helpers/DtoGenerators';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { LaborFormData } from '../../../interfaces/labor/LaborTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { LaborForm } from './LaborForm';

export const NewLaborForm = ({ onClose }: NewFormProps) => {
    const handleClose = () => {
        onClose()
    }

    const handleNewLabor = (formData: LaborFormData) => {
        LaborApi.create(getLaborDto(formData))
        .then(() => {
            handleClose()
        })
    }

    return (
        <NewForm
            title="Labor"
            form={
                <LaborForm
                    formData={{}}
                    formType={FormType.New}
                    title="Labor"
                    onSubmit={ values => handleNewLabor(values) }
                />
            }
            onClose={() => handleClose()}
        />
    )
}