import { faFilePdf, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getChangeOrderCostsTotal } from "../../../../helpers/DataHelpers";
import { ChangeOrder } from "../../../../interfaces/change/ChangeOrderTypes";
import { Job } from "../../../../interfaces/jobs/JobTypes";
import { LoadingAlert } from "../../../alerts/LoadingAlert";
import { EditChangeOrder } from "../../../change/edit/EditChangeOrder";
import { NewChangeOrder } from "../../../change/new/NewChangeOrder";
import { generateChangeOrderPdf } from "../../../change/assets/generateChangeOrderPdf";
import { DataTable } from "../../../shared/table/DataTable";
import { ExportToolbarLeftAlignNoColumns } from "../../../shared/table/ExportToolbar";
import './JobCostingChangeOrderTable.css';

type Props = {
    refetchChangeOrders: () => void,
    changeOrders: ChangeOrder[],
    job: Job
}

export const JobCostingChangeOrderTable = ({ refetchChangeOrders, changeOrders, job }: Props) => {
    const [showNew, setShowNew] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [updateChangeOrder, setUpdateChangeOrder] = useState<ChangeOrder | null>(null)    
    const [isLoading, setLoading] = useState(false)

    const pdfExporter = {
        field: "",
        headerName: "PDF",
        flex: 0.5,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
            const onClick = () => {     
                setLoading(true)
                generateChangeOrderPdf(params.row.rowObject)
                .then(() => {
                    setLoading(false)
                })
            };
            return (
                <div style={{ width: "100%", height: "100%"}} onClick={onClick}>
                    <FontAwesomeIcon icon={faFilePdf} style={{color: "#123E66"}}/>
                </div>
            )
        }
    }

    const columns = [
        { field: 'cost', headerName: 'Total Cost', flex: 0.5, type: "number" },
        { field: 'title', headerName: 'CO Title', flex: 1 },
        { field: 'date', headerName: 'Date', flex: 0.6, type: "date" },
        pdfExporter
    ];

    useEffect(() => {
        refetchChangeOrders()
        // eslint-disable-next-line
    }, [showEditForm, showNew])

    const handleRowSelect = (r: ChangeOrder) => {
        setUpdateChangeOrder(r)
        setShowEditForm(true)            
    }    

    const getRows = () => {
        return changeOrders
        .map(co => ({
            id: co.id,
            title: co.title,
            cost: `$${getChangeOrderCostsTotal(co)}`,
            date: new Date(co.date).toDateString(),
            rowObject: co
        }))
    }

    return (
        <div className="change-order-container">
            <div className="change-order-header">
                <p className="change-order-title">Change Orders</p>
                <FontAwesomeIcon className="new-change-order-button" icon={faPlusCircle} onClick={ e => setShowNew(true) } />
            </div>
            { 
                changeOrders.length ? 
                <DataTable<ChangeOrder>
                    onRowSelect={ d => handleRowSelect(d as ChangeOrder) }
                    data={changeOrders}
                    columns={columns} 
                    getRows={getRows}
                    height={350}
                    toolbar={ExportToolbarLeftAlignNoColumns}
                />
                : 
                <h4 style={{ fontWeight: 400 }}>No Change Orders</h4>
            }            
            { showNew && <NewChangeOrder job={job} onClose={ () => setShowNew(false) } /> }
            { showEditForm && updateChangeOrder && 
                <EditChangeOrder currentValue={updateChangeOrder} onClose={() => setShowEditForm(false)} showJobInput={false} /> 
            }
            { isLoading && <LoadingAlert title="Generating PDF..." /> }
        </div>
    )
}