import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@material-ui/core/Tooltip'
import { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from 'react-router-dom'
import { AuthApi } from '../../api/AuthApi'
import { AlertTypes } from '../../interfaces/alert/AlertState'
import { navPages } from '../../interfaces/navigation/NavPageTypes'
import store from '../../store'
import { setAlert } from '../../store/alert/AlertActions'
import { LogOutDialog } from "../dialogs/LogOutDialog"
import './NavBar.css'
import { currentUser } from '../../store/auth/AuthSelectors'
import { useSelector } from 'react-redux'

export const NavBar = () => {    
    const location = useLocation()
    const history = useHistory()
    const [logOutDialogOpen, setLogOutDialogOpen] = useState(false)
    const user = useSelector(currentUser);

    useEffect(() => {
        console.log(location.pathname)
    }, [location])

    const handleLogout = () => {
        AuthApi.logout()
        .then(() => {
            history.push("/login")
        })
        .catch(() => {
            store.dispatch(setAlert("Could not logout successfully, please try again.", AlertTypes.Error))
        })
    }

    return (
        <div className={"nav-bar-container"} style={{ width: "5vw" }}>
            <div className="nav-bar-top">
                <div className={"nav-bar-title-container nav-bar-title-container-collapsed"}>
                    <div className="nav-bar-title">TF</div>
                </div>
                {
                    navPages.filter(p => !p.adminOnly || user?.role === 'admin').map((navPage, index) => (
                        <Link 
                            to={navPage.href} 
                            className="nav-bar-link" 
                            key={navPage.title + index.toString()}
                        >
                            <Tooltip title={navPage.title} placement="right">
                                <div 
                                    className={"nav-bar-item-collapsed" + (location.pathname.includes(navPage.href) ? " nav-bar-item-selected" : "")}
                                >
                                    <FontAwesomeIcon icon={navPage.icon} style={{color: "#fff"}} />                          
                                </div>
                            </Tooltip>
                        </Link>
                    ))
                }
                <Tooltip title="Logout" placement="right">
                    <div 
                        className={"nav-bar-item-collapsed"}
                        onClick={ e => setLogOutDialogOpen(true) }
                    >
                        <FontAwesomeIcon icon={faSignOutAlt} style={{color: "#fff"}} />                    
                    </div>
                </Tooltip>
            </div>            
            <LogOutDialog
                open={logOutDialogOpen}
                handleClose={ () => setLogOutDialogOpen(false) }
                handleLogOut={ () => {
                    setLogOutDialogOpen(false)
                    handleLogout()
                }}
            />
        </div>
    )
}