import { ModuleDataType } from "../../store/modules/ModuleTypes";
import { ChangeOrder } from "../change/ChangeOrderTypes";
import { Customer } from "../customers/CustomerTypes";
import { Labor } from "../labor/LaborTypes";
import { Material } from "../materials/MaterialTypes";

export interface Job {
    id: number,
    customer?: Customer
    name: string,
    status: JobStatus,
    address?: string,
    owner?: string,    
    materialCost?: number,
    hangFramingCost?: number,
    tapingLaborCost?: number,
    miscCost?: number,
    contractPrice?: number
    additionalNotes?: string,
    manDays?: number,
    manDayPrice?: number,
    date: Date,
    prevailingWage?: number,
    updatedDate?: Date
    updatedBy: string | null 
}

export interface JobContractCost {
    materialCost?: number,
    hangFramingCost?: number,
    tapingLaborCost?: number,
    miscCost?: number,
    contractPrice?: number
}

export enum JobStatus {
    Complete = "Complete",
    InProgress = "In Progress"
}

export interface JobFormData {
    name?: string,
    owner?: string,
    customer?: Customer,
    status: JobStatus,
    address?: string
    materialCost?: string,
    hangFramingCost?: string,
    tapingLaborCost?: string,
    manDayPrice?: string,
    miscCost?: string,
    contractPrice?: string,
    additionalNotes?: string,
    date?: string,
    showPrevailingWage: boolean,
    prevailingWage?: string
}

export interface JobDto {    
    name: string;
    customer: ModuleDataType;
    status: JobStatus;
    owner: string;
    address: string;
    contractPrice: number;
    materialCost: number;
    hangFramingCost: number;
    miscCost: number;
    prevailingWage: number | null;
    tapingLaborCost: number;
    manDayPrice: number;
    date: Date;
    additionalNotes: string;
}

export interface JobCostingData {
    changeOrders: ChangeOrder[],
    labor: Labor[],
    materials: Material[]
}