import { ModuleService, Service, ServiceStatusValues } from "../interfaces/Service"
import { ModuleDataType } from "../store/modules/ModuleTypes"

export class ModuleReducerHelper {
    getStateWithStoredData(data: ModuleDataType[]): Service<ModuleService<ModuleDataType>> {
        return {
            status: ServiceStatusValues.Loaded,
            payload: {
                data: data
            } 
        }
    }
    getStateWithUpdatedData = (updatedData: ModuleDataType, prevState: Service<ModuleService<ModuleDataType>>): Service<ModuleService<ModuleDataType>> => {
        if (prevState.status !== ServiceStatusValues.Loaded) { return prevState }
        const tempData = [...prevState.payload.data]
        const dataIndex = prevState.payload.data.findIndex((data) => data.id === updatedData.id)
        if (dataIndex < 0) { return prevState }
        tempData[dataIndex] = updatedData

        return {
            status: ServiceStatusValues.Loaded,
            payload: {
                data: tempData
            } 
        }
    }
    getStateWithNewData = (newData: ModuleDataType, prevState: Service<ModuleService<ModuleDataType>>): Service<ModuleService<ModuleDataType>> => {
        if (prevState.status !== ServiceStatusValues.Loaded) { return prevState }
        const tempData = [...prevState.payload.data]
        tempData.push(newData)

        return {
            status: ServiceStatusValues.Loaded,
            payload: {
                data: tempData
            } 
        }
    }
    getStateWithRemovedData = (id: number, prevState: Service<ModuleService<ModuleDataType>>): Service<ModuleService<ModuleDataType>> => {
        if (prevState.status !== ServiceStatusValues.Loaded) { return prevState }
        const tempData = [...prevState.payload.data]
        const dataIndex = prevState.payload.data.findIndex((d) => d.id === id)
        if (dataIndex < 0) { return prevState }
        tempData.splice(dataIndex, 1)

        return {
            status: ServiceStatusValues.Loaded,
            payload: {
                data: tempData
            } 
        }
    }
}