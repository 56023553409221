import { API_ROUTES, defaultApiOptions, getBackendUrlForPath } from "../helpers/ApiHelpers";
import axios from "axios"
import { Customer } from "../interfaces/customers/CustomerTypes";

export interface ReportParameters {
  category: 'customer' | 'year',
  customers: Customer[],
  startDate: Date | null,
  endDate: Date | null
}

interface BidsReportResultRaw {
    total: string,
    awarded: string,
    lost: string,
    bid_total: number,
    award_total: number,
    category: string
}

export interface BidsReportResult {
    total: number,
    awarded: number,
    lost: number,
    bid_total: number,
    award_total: number,
    category: string
}

const convertBidsReport = (r: BidsReportResultRaw[]): BidsReportResult[] => r.map(i => ({
    ...i,
    total: parseInt(i.total),
    awarded: parseInt(i.awarded),
    lost: parseInt(i.lost),
}))

export interface ProfitReportResult {
    combined: {
        revenue: number,
        cost: number,
        profit: number,
    },
    categories: {
        [key: string]: {
            revenue: number,
            cost: number,
            profit: number,
            jobs: {
                name: string,
                revenue: number,
                cost: number,
                profit: number
            }[]
        }
    }
}


const encodeReportParameters = (params: ReportParameters) => ({
  category: params.category,
  customers: encodeURIComponent(JSON.stringify(params.customers.map(c => c.id))),
  start: params.startDate?.toString() ?? undefined,
  end: params.endDate?.toString() ?? undefined,
})


export class ReportApi {
    static bidsReport = (params: ReportParameters) => {
        return new Promise<BidsReportResult[]>((resolve, reject) => {
            axios.get<BidsReportResultRaw[]>(getBackendUrlForPath(API_ROUTES.BIDS_REPORT), {
                withCredentials: true,
                params: encodeReportParameters(params)
            }).then((res) => {
                resolve(convertBidsReport(res.data))
            }, (error) => {
                reject();
            })
        })
    }

    static profitReport = (params: ReportParameters) => {
      return new Promise<ProfitReportResult>((resolve, reject) => {
          axios.get<ProfitReportResult>(getBackendUrlForPath(API_ROUTES.PROFIT_REPORT), {
              withCredentials: true,
              params: encodeReportParameters(params)
          }).then((res) => {
              resolve(res.data)
          }, (error) => {
              reject();
          })
      })
  }
}