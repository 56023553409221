import { AlertTypes } from "../../interfaces/alert/AlertState";

export enum AlertActionTypes {
    SET_ALERT = "alert/set",
    REMOVE_ALERT = "alert/remove"
}

export interface SetAlertAction {
    type: AlertActionTypes.SET_ALERT,
    payload: {
        text: string,
        type: AlertTypes
    }
}

export interface RemoveAlertAction {
    type: AlertActionTypes.REMOVE_ALERT
}

export type AlertActions = 
    | SetAlertAction
    | RemoveAlertAction