import { API_ROUTES, defaultApiOptions, getBackendUrlForPath } from "../helpers/ApiHelpers";
import { LoginFormData } from "../interfaces/auth/LoginTypes";
import { User } from "../interfaces/auth/UserTypes";
import store from "../store";
import axios, { AxiosError, AxiosResponse } from "axios"
import { handleLogin, handleLogout } from "../store/auth/AuthActions";
import { dataLoaded } from "../store/modules/ModuleActions";
import { ModuleTypes } from "../store/modules/ModuleTypes";
import { setAlert } from "../store/alert/AlertActions";
import { AlertTypes } from "../interfaces/alert/AlertState";

export class AuthApi {
    static verifyAuthStatus() {
        return new Promise((resolve, reject) => {
            axios.get(getBackendUrlForPath(API_ROUTES.VERIFY_USER), {
                withCredentials: true
            }).then((res) => {
                var user = res.data as User
                store.dispatch(handleLogin(user))
                resolve(user)
            }, () => {
                store.dispatch(handleLogout())
                reject()
            })
        })
    }

    static login = (formData: LoginFormData) => {
        const body = { email: formData.email ?? "", password: formData.password ?? "" }
        return new Promise((resolve, reject) => {
            axios.post(getBackendUrlForPath(API_ROUTES.LOGIN), body, defaultApiOptions)
            .then((res) => {
                var user = res.data as User
                store.dispatch(handleLogin(user))
                resolve(res.data)
            }, (error: AxiosError) => {
                reject();
            })
        })
    }

    static logout = () => {
        return new Promise((resolve, reject) => {
            axios.get(getBackendUrlForPath(API_ROUTES.LOGOUT), {
                withCredentials: true
            }).then((res) => {
                store.dispatch(handleLogout())
                resolve(res)
            }, (error) => {
                reject();
            })
        })
    }

    static async fetchAll() {
        try {
            let response = await axios.get(getBackendUrlForPath(API_ROUTES.LIST_USERS), defaultApiOptions)
            store.dispatch(dataLoaded(response.data as User[], ModuleTypes.User))
            return response.data
        } catch (error: any) {
            store.dispatch(setAlert(`Could not fetch users. Please try refreshing the page.`, AlertTypes.Error))
            throw new Error(error)
        }
    }

    static create(dto: Omit<User, 'id'>) {
        return new Promise((resolve, reject) => {
            axios.post(getBackendUrlForPath(API_ROUTES.CREATE_USER), dto, defaultApiOptions)
            .then((response: AxiosResponse) => {
                store.dispatch(setAlert(`User has been succesfully created.`, AlertTypes.Success))
                // fetch again
                this.fetchAll()
                .then(() => resolve(response.data) )
            }, (error: AxiosError) => {
                store.dispatch(setAlert(`Could not create user. Please try again.`, AlertTypes.Error))
                reject(error);
            })
        })
    }

    static edit(id: number, dto: Omit<User, 'id'>) {
        let apiUrl = `${getBackendUrlForPath(API_ROUTES.EDIT_USER)}/${id}`
        return new Promise((resolve, reject) => {
            axios.post(apiUrl, dto, defaultApiOptions)
            .then((response: AxiosResponse) => {
                store.dispatch(setAlert(`User has been succesfully updated.`, AlertTypes.Success))
                // fetch again
                this.fetchAll()
                .then(() => resolve(response.data) )
            }, (error: AxiosError) => {
                store.dispatch(setAlert(`Could not update user. Please try again.`, AlertTypes.Error))
                reject(error);
            })
        })
    }
}