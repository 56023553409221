import React, { FunctionComponent, useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Box, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import './ReportsPage.css'
import { Customer } from '../../interfaces/customers/CustomerTypes';
import { CustomersApi } from '../../api/ModuleApi';
import { ModuleDataLimited } from '../../store/modules/ModuleTypes';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Print } from '@material-ui/icons'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ResponsiveContainer, Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, Text, Cell } from 'recharts';
import { BidsReportResult, ProfitReportResult, ReportApi, ReportParameters } from '../../api/ReportApi';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

enum ReportType {
  BidsAwarded = 'bidsAwarded',
  BidsTotal = 'bidsTotal',
  Profit = 'profit',
  ProfitMargin = 'profitMargin',
}

enum GroupBy {
  Customer = 'customer',
  Year = 'year'
}

const getXaxis = (printing: boolean, data: any[]) => {
  if (!printing) return <XAxis dataKey="category" />
  const longestCat = data.slice().sort((a, b) => b.category.length - a.category.length)[0]?.category ?? '';
  return <XAxis interval={0} dataKey="category" angle={-90} textAnchor="end" height={longestCat.length * 5 + 15} tick={(props: any) => {
    const { x, y, payload } = props;
    return (
      <g>
          <text fontSize={11} x={x} y={y + 4} transform={`rotate(-90, ${x}, ${y})`}  textAnchor='end'>
           { payload.value }
          </text>
      </g>
    );
  }} />
}

interface BidsAwardedReportProps {
  data: BidsReportResult[],
  category: GroupBy,
  printing: boolean,
}
const BidsAwardedReport: FunctionComponent<BidsAwardedReportProps> = (props) => {
  const { data, category, printing } = props;
  const parsedData = useMemo(() => {
    if (!data || !data.map) return [];
    return data.map(d => ({
      ...d,
      category: category === GroupBy.Customer ? d.category : ((new Date(d.category)).getFullYear()),
      percentAwarded: 100 * (d.awarded / d.total),
      percentLost: 100 * (d.lost / d.total)
    })).sort((a, b) => {
      if (category === GroupBy.Year) return 0;
      else return Intl.Collator('en-US').compare(a.category as string, b.category as string);
    })
  }, [data, category]);

  return (
    <ResponsiveContainer>
      <BarChart
        data={parsedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        { getXaxis(printing, parsedData) }
        <YAxis />
        <Tooltip formatter={(value, name, props, index) => {
          const x = props.payload;
          return `${value} (${(index === 0 ? x?.percentAwarded : x?.percentLost).toFixed(2)}%)`
        }} />
        <Legend />
        <Bar dataKey="awarded" name="Awarded" stackId="a" fill="#66BB6A" isAnimationActive={!printing} />
        <Bar dataKey="lost" name="Lost" stackId="a" fill="#F44336" isAnimationActive={!printing} />
      </BarChart>
    </ResponsiveContainer>
  )
}

interface BidAmountReportProps {
  data: BidsReportResult[],
  category: GroupBy,
  printing: boolean,
}
const BidAmountReport: FunctionComponent<BidAmountReportProps> = (props) => {
  const { data, category, printing } = props;
  const parsedData = useMemo(() => {
    if (!data || !data.map) return [];
    return data.map(d => ({
      ...d,
      category: category === GroupBy.Customer ? d.category : ((new Date(d.category)).getFullYear()),
      bid_total: d.bid_total ?? 0,
      award_total: d.award_total ?? 0,
    })).sort((a, b) => {
      if (category === GroupBy.Year) return 0;
      else return Intl.Collator('en-US').compare(a.category as string, b.category as string);
    })
  }, [data, category]);

  return (
    <ResponsiveContainer>
      <BarChart
        data={parsedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        { getXaxis(printing, parsedData) }
        <YAxis tickFormatter={(value: number) => {
          return value.toFixed(1) + '%' 
        }} />
        <Tooltip formatter={(value: number) => {
          return value.toFixed(1) + '%' 
        }} />
        <Legend />
        <Bar dataKey="bid_total" name="Bid" fill="#3949AB" isAnimationActive={!printing} />
        <Bar dataKey="award_total" name="Awarded" fill="#4CAF50" isAnimationActive={!printing} />
      </BarChart>
    </ResponsiveContainer>
  )
}

const hexColourArr = [
  "#a3d9ff",
  "#7e6b8f",
  "#96e6b3",
  "#da3e52",
  "#f2e94e",
  "#b0d0d3",
  "#c08497",
  "#f7af9d",
  "#f7e3af",
  "#f3eec3"
];
interface ProfitReportProps {
  data: ProfitReportResult,
  category: GroupBy,
  printing: boolean,
  showJobs: boolean,
  setCustomers: Dispatch<SetStateAction<Customer[]>>,
  setGroupBy: Dispatch<SetStateAction<GroupBy>>,
  customers: Customer[],
}
const ProfitReport: FunctionComponent<ProfitReportProps> = (props) => {
  const { data, category, printing, showJobs, customers, setCustomers, setGroupBy } = props;
  const parsedData = useMemo(() => {
    if (!data || !data.categories) return [];
    return Object.entries(data.categories).map(([catName, d]) => ({
      ...d,
      category: catName,
      jobs: d.jobs
    })).sort((a, b) => {
      if (category === GroupBy.Year) return 0;
      else return Intl.Collator('en-US').compare(a.category as string, b.category as string);
    })
  }, [data, category]).slice().sort((a, b) => {
    if (category === GroupBy.Customer) return b.profit - a.profit;
    else return 0;
  });

  const customerOnClick = (data: any) => {
    const c = customers.find(c => c.id === data.customerId);
    if (c) {
      setCustomers([c]);
      setGroupBy(GroupBy.Year);
    }
  }
  // Determine max number of jobs to trick bar chart into rendering everything properly
  const maxNumberJobs = parsedData.slice().sort((a, b) => b.jobs.length - a.jobs.length)[0]?.jobs?.length ?? 0;

  return (
    <ResponsiveContainer>
      <BarChart
        data={parsedData}
        margin={{
          top: 20,
          right: 30,
          left: 150,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        { getXaxis(printing, parsedData) }
        <YAxis tickFormatter={(value: number) => {
          return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
        }} domain={[0, (dataMax: number) => (dataMax + dataMax * .1)]} />
        {
          showJobs ?
          <Tooltip
          content={(props) => (
              <div style={{
                border: '#bbb 1.5px solid',
                  backgroundColor: 'white',
                  padding: '15px 6px'
              }}>
                <p style={{
                  margin: '0 0',
                  marginBottom: '1em',
                  fontWeight: 'bold',
                  fontSize: '1.15em',
                  padding: '0px 15px',
                  backgroundColor: 'white',
                }}>
                  {props.payload && props.payload[0] != null && props.payload[0].payload.category}
                </p>
                <p style={{
                  margin: '0 0',
                  marginBottom: '1em',
                  fontWeight: 'bold',
                  fontSize: '1.15em',
                  padding: '0px 15px',
                  backgroundColor: 'white',
                }}>
                  <b>Total Profit:</b> { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.payload?.[0]?.payload?.profit) }
                </p>
                <hr />
                {
                  (props.payload?.[0]?.payload?.jobs as any[])?.map((item, i) => (
                    <div style={{padding: '0px 15px'}}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.25em' }}>
                        <div style={{ display: 'inline-block', width: '15px', height: '15px', border: '1px solid black', backgroundColor: hexColourArr[i % hexColourArr.length], margin: '.25em' }} >&nbsp;</div>
                        <b>{item.name}</b>
                      </div>
                      <div style={{marginBottom: '.25em', paddingLeft: 'calc(30px + .25em)'}}>
                        <b>Profit:</b> { Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.profit) }
                      </div>
                    </div>
                  )).slice().reverse()
                }
              </div>
            )}
          />
          :
          <Tooltip formatter={(value: number) => {
            return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
          }} />
        }
        {
          showJobs ?
          parsedData.map((dataObj, y) => {
            return Array(maxNumberJobs).fill(0).map((_, i) => {
                const job = dataObj.jobs[i];
                if (y === 0)
                return (
                  <Bar
                    dataKey={`jobs[${i}].profit`}
                    stackId={dataObj.category}
                    fill={hexColourArr[i % hexColourArr.length]}
                    name={job?.name ?? ''}
                  />
                );
              })
          })
          :
          <Bar dataKey="profit" name="Profit" isAnimationActive={!printing} cursor={category === GroupBy.Customer ? 'pointer' : undefined}
          onClick={category === GroupBy.Customer ? customerOnClick : undefined}
          >
            {parsedData.map((entry) => (
              <Cell fill={entry.profit >= 0 ? '#4CAF50' : '#F44336'} />
            ))}
          </Bar>
        }
      </BarChart>
    </ResponsiveContainer>
  )
}
const ProfitMarginReport: FunctionComponent<ProfitReportProps> = (props) => {
  const { data, category, printing } = props;
  const parsedData = useMemo(() => {
    if (!data || !data.categories) return [];
    return Object.entries(data.categories).map(([catName, d]) => ({
      ...d,
      category: catName,
      profitMargin: (d.profit / d.revenue) * 100
    })).sort((a, b) => {
      if (category === GroupBy.Year) return 0;
      else return Intl.Collator('en-US').compare(a.category as string, b.category as string);
    })
  }, [data, category]);

  return (
    <ResponsiveContainer>
      <BarChart
        data={parsedData}
        margin={{
          top: 20,
          right: 30,
          left: 150,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        { getXaxis(printing, parsedData) }
        <YAxis tickFormatter={(value: number) => {
          return value.toFixed(1) + '%' 
        }} domain={[(dataMin: number) => dataMin < 0 ? -100 : 0, 100]} />
        <Tooltip
          content={(props) => (
            <div style={{
              border: '#bbb 1.5px solid',
                backgroundColor: 'white',
            }}>
              <p style={{
                margin: '0 0',
                padding: '6px 15px',
                backgroundColor: 'white',
              }}>
                {props.payload && props.payload[0] != null && props.payload[0].payload.category}
              </p>
              <p style={{
                margin: '0 0',
                padding: '6px 15px',
                backgroundColor: 'white',
              }}>
                Profit Margin:
                {' '}
                <b>
                {
                  props.payload && props.payload[0] != null && (props.payload[0].payload.profitMargin).toFixed(1) + '%'
                }
                </b>
              </p>
              <p style={{
                margin: '0 0',
                padding: '6px 15px',
                backgroundColor: 'white',
              }}>
                Profit:
                {' '}
                <b>
                {
                  props.payload && props.payload[0] != null && Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((props.payload[0].payload.profit))
                }
                </b>
              </p>
            </div>
          )}
        />
        <Legend />
        <Bar dataKey="profitMargin" name="Profit Margin" isAnimationActive={!printing}>
          {parsedData.map((entry) => (
            <Cell fill={entry.profitMargin >= 0 ? '#4CAF50' : '#F44336'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}


export const ReportsPage = () => {
  const [lastParams, setLastParams] = useState<ReportParameters & { reportType: ReportType }>();
  const [reportType, setReportType] = useState<ReportType>(ReportType.BidsAwarded);
  const [groupBy, setGroupBy] = useState<GroupBy>(GroupBy.Customer);

  const [customerData, setCustomerData] = useState<ModuleDataLimited[]>([])
  useEffect(() => {
    CustomersApi.fetchAllLimited().then((r) => setCustomerData(r as ModuleDataLimited[]))
  }, [])
  const [customers, setCustomers] = useState<Customer[]>([]);
  const availableCustomers = useMemo(() => customerData.filter(c => !customers.map(cc => cc.id).includes(c.id)), [customerData, customers])

  const [startDate, setStartDate] = useState<MaterialUiPickersDate | null>(null);
  const startDateSafe = useMemo(() => {
    if (!startDate) return startDate;
    try {
      Intl.DateTimeFormat('en-US').format(startDate as any);
      return new Date(startDate);
    } catch (e) {
      return null;
    }
  }, [startDate])
  const [endDate, setEndDate] = useState<MaterialUiPickersDate | null>(null);
  const endDateSafe = useMemo(() => {
    if (!endDate) return endDate;
    try {
      Intl.DateTimeFormat('en-US').format(endDate as any);
      return new Date(endDate);
    } catch (e) {
      return null;
    }
  }, [endDate])

  const [data, setData] = useState<BidsReportResult[] | ProfitReportResult>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    const params = {
      category: groupBy,
      customers: [...customers],
      startDate: startDateSafe,
      endDate: endDateSafe
    }
    const paramsWithType = {
      reportType,
      ...params
    }

    setLoading(true);
    if (reportType === ReportType.BidsAwarded || reportType === ReportType.BidsTotal) ReportApi.bidsReport(params).then(d => {
      setData(d);
      setLastParams(paramsWithType);
    })
    else if (reportType === ReportType.Profit || reportType === ReportType.ProfitMargin) ReportApi.profitReport(params).then(d => {
      setData(d);
      setLastParams(paramsWithType);
    })
    setLoading(false);
  }, [reportType, groupBy, customers, startDateSafe, endDateSafe])

  const chartName = useMemo(() => {
    let mainName = '';
    switch (reportType) {
      case ReportType.BidsAwarded:
        mainName = 'Bids Awarded'
        break;
      case ReportType.BidsTotal:
        mainName = 'Bid vs. Awarded Amount'
        break;
      case ReportType.Profit:
        mainName = 'Total Profit'
        break;
      default:
        break;
    }
    let out = mainName;

    if (groupBy === GroupBy.Customer) out += '  By Customer'
    else if (groupBy === GroupBy.Year) out += ' By Year'

    if (customers.length === 1) out += ` For Customer ${customers[0].name}`
    else if (customers.length > 1) out += ` For ${customers.length} Customers`

    if (startDateSafe && !endDateSafe) out += ` Since ${Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(startDateSafe)}`
    else if (!startDateSafe && endDateSafe) out += ` Until ${Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(endDateSafe)}`
    else if (startDateSafe && endDateSafe) out += `  Between ${Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(startDateSafe)} and ${Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(endDateSafe)}`

    return out;
  }, [reportType, groupBy, customers, startDateSafe, endDateSafe])

  const [printing, setPrinting] = useState(false);
  const doPrint = () => {
    setPrinting(true);
    setTimeout(() => {
      window.print();
      setPrinting(false);
    });
  }

  return (
    <div style={{ height: '100%' }}>
      <div className="page-title">Reports</div>
      <Grid container spacing={3} className="report-options-container">
        <Grid item xs={12} lg={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Report Type</InputLabel>
                <Select
                  label="Report Type"
                  value={reportType}
                  onChange={(e) => {
                    setReportType(e.target.value as ReportType)
                  }}
                >
                  <MenuItem value={ReportType.BidsAwarded}>% Bids Awarded</MenuItem>
                  <MenuItem value={ReportType.BidsTotal}>Bid vs. Awarded Amounts</MenuItem>
                  <MenuItem value={ReportType.Profit}>Profit</MenuItem>
                  <MenuItem value={ReportType.ProfitMargin}>Profit Margin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Group By</InputLabel>
                <Select
                  label="Group By"
                  value={groupBy}
                  onChange={(e) => {
                    setGroupBy(e.target.value as GroupBy)
                  }}
                >
                  <MenuItem value={GroupBy.Customer}>Customer</MenuItem>
                  <MenuItem value={GroupBy.Year}>Year</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={availableCustomers}
                multiple
                getOptionLabel={(customer: Customer) => `${customer.name} #${customer.id}`}
                value={customers}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label="Filter by Customer"
                  />
                }
                onChange={(e, v) => {
                  setCustomers(v);
                }}
                disableCloseOnSelect
              />
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  variant="inline"
                  format="MM/dd/yyyy"
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  clearable
                  views={['date', 'month', 'year']}
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  label="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  clearable
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" startIcon={<Print />} onClick={doPrint}>Print Report</Button>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box display="flex" flexDirection="column" height="90%" id="section-to-print">
            <Box fontSize="1.5em" fontWeight="bold" textAlign="center" position="relative">
              { chartName }
              { (lastParams?.reportType === ReportType.Profit || lastParams?.reportType === ReportType.ProfitMargin) ?
                      <Box
                      style={{ fontSize: 20, fontWeight: 'bold', color: '#777', background: '#fff', display: 'block', float: "right", position: 'relative', right: '1em' }}
                    >
                      <div>Total Profit: {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((data as ProfitReportResult)?.combined?.profit ?? 0)}</div>
                      <div>Total Adj. Contract: {Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(((data as ProfitReportResult)?.combined?.revenue ?? 0))}</div>
                      <div>Total Profit Margin: {((((data as ProfitReportResult)?.combined?.profit ?? 0) / ((data as ProfitReportResult)?.combined?.revenue ?? 0)) * 100).toFixed(1)}%</div>
                    </Box>
                    : null  
            }
            </Box>
            <Box flexGrow="1">
              {
                loading ? null :
                lastParams?.reportType === ReportType.BidsAwarded ? <BidsAwardedReport data={data as BidsReportResult[]} category={groupBy} printing={printing} /> :
                  lastParams?.reportType === ReportType.BidsTotal ? <BidAmountReport data={data as BidsReportResult[]} category={groupBy} printing={printing} /> :
                  lastParams?.reportType === ReportType.Profit ? <ProfitReport setCustomers={setCustomers} setGroupBy={setGroupBy} customers={availableCustomers} showJobs={customers.length > 0} data={data as ProfitReportResult} category={groupBy} printing={printing} /> : 
                  lastParams?.reportType === ReportType.ProfitMargin ? <ProfitMarginReport setCustomers={setCustomers} setGroupBy={setGroupBy} customers={availableCustomers} showJobs={false} data={data as ProfitReportResult} category={groupBy} printing={printing} /> : <></>
              }
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}