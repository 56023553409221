import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBriefcase, faBrush, faFileContract, faMoneyBillWave, faParachuteBox, faPenAlt, faTruck, faUserClock, faUserFriends, faHandHoldingUsd, faChartBar, faUsersCog } from "@fortawesome/free-solid-svg-icons";
import { ChangeOrderPage } from "../../components/change/ChangeOrderPage";
import { CustomersPage } from "../../components/customers/CustomersPage";
import { EmployeesPage } from "../../components/employees/EmployeesPage";
import { InvoicesPage } from "../../components/invoices/InvoicesPage";
import { JobsPage } from "../../components/jobs/JobsPage";
import { LaborPage } from "../../components/labor/LaborPage";
import { MaterialsPage } from "../../components/materials/MaterialsPage";
import { SuppliersPage } from "../../components/suppliers/SuppliersPage";
import { PrevailingWagePage } from "../../components/wages/PrevailingWagePage";
import { BidsPage } from "../../components/bids/BidsPage";
import { ReportsPage } from "../../components/reports/ReportsPage";
import { UsersPage } from "../../components/users/UsersPage";

export interface NavPage {
    href: string,
    icon: IconProp,
    title: string,
    page: JSX.Element,
    adminOnly?: boolean
}

export const navPages: NavPage[] = [
    {
        href: "/jobs",
        icon: faBriefcase,
        title: "Jobs",
        page: <JobsPage />
    },
    {
        href: "/bids",
        icon: faHandHoldingUsd,
        title: "Bids",
        page: <BidsPage />
    },
    {
        href: "/labor",
        icon: faTruck,
        title: "Labor",
        page: <LaborPage />
    },
    {
        href: "/change-orders",
        icon: faPenAlt,
        title: "Change Orders",
        page: <ChangeOrderPage />
    },
    {
        href: "/invoices",
        icon: faFileContract,
        title: "Invoices",
        page: <InvoicesPage />
    },
    {
        href: "/customers",
        icon: faUserFriends,
        title: "Customers",
        page: <CustomersPage />
    },
    {
        href: "/users",
        icon: faUsersCog,
        title: "Users",
        page: <UsersPage />,
        adminOnly: true
    },
    {
        href: "/employees",
        icon: faUserClock,
        title: "Employees",
        page: <EmployeesPage />
    },
    {
        href: "/suppliers",
        icon: faParachuteBox,
        title: "Suppliers",
        page: <SuppliersPage />
    },
    {
        href: "/materials",
        icon: faBrush,
        title: "Materials",
        page: <MaterialsPage />
    },
    {
        href: "/wages",
        icon: faMoneyBillWave,
        title: "Prevailing Wage",
        page: <PrevailingWagePage />
    },
    {
        href: "/reports",
        icon: faChartBar,
        title: "Reports",
        page: <ReportsPage />,
        adminOnly: true
    }
]