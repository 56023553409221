import { CustomersApi } from '../../api/ModuleApi';
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Customer } from "../../interfaces/customers/CustomerTypes"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectCustomersData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import { EditCustomerForm } from "./form/EditCustomerForm"
import { NewCustomerForm } from "./form/NewCustomerForm"

const filters: SearchFilter[] = [
    { title: "#", keys: [ "id" ], isActive: true },
    { title: "Name", keys: [ "name" ], isActive: true },
    { title: "Phone", keys: [ "mainPhone" ], isActive: true },
    { title: "Contact", keys: [ "contact" ], isActive: true }
]

const columns = [
    { field: 'id', headerName: '#', flex: 0.2, type: 'number' },
    { field: 'name', headerName: 'Customer Name', flex: 0.85 },
    { field: 'phone', headerName: 'Phone', flex: 0.85 },
    { field: 'contact', headerName: 'Contact', flex: 0.85 },
];

export const CustomersPage = () => {
    const pageData = useSelector(selectCustomersData)

    useEffect(() => {
        CustomersApi.fetchAll()
    }, [])

    const getRows = (tableData: Customer[]) => {
        return tableData.map((customer) => {
            return {
                id: customer.id,
                name: customer.name,
                contact: customer.contact,
                phone: customer.mainPhone,
                rowObject: customer
            }
        })
    }

    return (
        <DataPage
            filters={filters}
            title={"Customers"}
            newButtonTitle={"New Customer"}
            pageData={pageData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewCustomerForm}
            editForm={EditCustomerForm}
        />
    )
}