import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { InvoicesApi } from "../../api/ModuleApi"
import { doesValueExist } from "../../helpers/IndexHelpers"
import { Invoice } from "../../interfaces/invoices/InvoiceTypes"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectInvoiceData } from "../../store/modules/ModuleSelectors"
import { LoadingAlert } from "../alerts/LoadingAlert"
import { DataPage } from "../shared/pages/DataPage"
import { CheckForNullCell } from "../shared/table/CheckForNullCell"
import { PdfExportButton } from "../shared/table/PdfExportButton"
import { InvoiceAssetGenerator } from "./assets/InvoiceAssetGenerator"
import { EditInvoiceForm } from "./form/EditInvoiceForm"
import { NewInvoiceForm } from "./form/NewInvoiceForm"

const filters: SearchFilter[] = [
    { title: "#", keys: ["id"], isActive: true },
    { title: "Customer", keys: ["job.customer.name", "job.customer.id"], isActive: true },
    { title: "Job", keys: ["job.name", "job.id"], isActive: true },
    { title: "Date", keys: [ "billThroughDate" ], isActive: true },
]

export const InvoicesPage = () => {
    const pageData = useSelector(selectInvoiceData)
    const [isLoading, setLoading] = useState(false)

    const pdfExporterProgress = {
        field: "progress",
        headerName: "In Progress",
        flex: 0.4,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
            const onClick = () => {     
                setLoading(true)
                new InvoiceAssetGenerator().generateAsset(params.row.rowObject, true)
                .finally(() => {
                    setLoading(false)
                })
            };
            return <PdfExportButton onClick={onClick} />
        }
    }

    const pdfExporterFinal = {
        field: "final",
        headerName: "Final",
        flex: 0.3,
        disableClickEventBubbling: true,
        renderCell: (params: any) => {
            const onClick = () => {     
                setLoading(true)
                new InvoiceAssetGenerator().generateAsset(params.row.rowObject, false)
                .finally(() => {
                    setLoading(false)
                })
            };
            return <PdfExportButton onClick={onClick} />
        }
    }

    const columns = [
        { field: 'id', headerName: '#', flex: 0.2, type: 'number' },
        { field: 'contract', headerName: 'Contract $', flex: 0.4, type: "number" },
        { field: 'jobName', headerName: 'Job', flex: 0.85, renderCell: CheckForNullCell },
        { field: 'customerName', headerName: 'Customer', flex: 0.85, renderCell: CheckForNullCell },
        { field: 'date', headerName: 'BT Date', flex: 0.5, type: "date" },
        pdfExporterProgress,
        pdfExporterFinal
    ];

    useEffect(() => {
        InvoicesApi.fetchAll()
    }, [])

    const getRows = (tableData: Invoice[]) => {
        return tableData.map((invoice) => {
            return {
                id: invoice.id,
                customerName: invoice.job ? (doesValueExist(invoice.job.customer) ? invoice.job.customer!.name : "") : null,
                jobName: invoice.job ? invoice.job.name : null,
                date: new Date(invoice.billThroughDate).toLocaleDateString(),
                contract: "$" + invoice.contractAmount,
                rowObject: invoice
            }
        })
    }

    return (
        <>
            <DataPage
                filters={filters}
                title={"Invoices"}
                newButtonTitle={"New Invoice"}
                pageData={pageData}
                tableColumns={columns}
                getRows={ tableData => getRows(tableData) }
                newForm={NewInvoiceForm}
                editForm={EditInvoiceForm}
            />
            { isLoading && <LoadingAlert title="Generating PDF..." /> }
        </>
    )
}