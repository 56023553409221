import { Divider } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { getDollarFigureFromNumber } from "../../../helpers/IndexHelpers"
import { JobContractCost } from "../../../interfaces/jobs/JobTypes"

interface ContentValueData {
    title: "H/F" | "Taping" | "Material" | "Misc",
    val: number | undefined
}

export type JobCostingValueProps = {
    title: string,
    contractData?: JobContractCost,
    manDayPrice?: number
}

export const JobCostingValues = ({ contractData, title, manDayPrice }: JobCostingValueProps) => {
    const [contentValues, setContentValues] = useState<ContentValueData[]>([])

    useEffect(() => {
        setContentValues([
            { title: "H/F", val: contractData?.hangFramingCost },
            { title: "Taping", val: contractData?.tapingLaborCost },
            { title: "Material", val: contractData?.materialCost },
            { title: "Misc", val: contractData?.miscCost },
        ])
    }, [contractData])

    return (
        <div className="job-costing-component">
            <h1 className="job-costing-component-title">{ title }</h1> 
            <div className="job-costing-component-content">
                {
                    contentValues.map((contentValue, index) => (
                        <React.Fragment key={index.toString()}>
                            <div className="job-costing-component-content-values">
                                <p>{contentValue.title}:</p>
                                <div className="job-costing-component-content-values-right">
                                    <p>${ getDollarFigureFromNumber(contentValue.val) }</p>
                                    {
                                        contentValue.title !== "Material" && contentValue.title !== "Misc" &&
                                        <p className="job-costing-man-day-quotient">{ getDollarFigureFromNumber((contentValue.val ?? 0)/(manDayPrice ?? 1))}</p>
                                    }
                                </div>
                            </div>
                            <Divider />
                        </React.Fragment>
                    ))
                }
                <p className="job-costing-component-total">
                    Total: ${ getDollarFigureFromNumber((contentValues.reduce((a, b) => a + (b.val ?? 0), 0))) }
                </p>
            </div>
        </div>
    )
}