import { AlertState} from "../../interfaces/alert/AlertState"
import { AlertActions, AlertActionTypes } from "./AlertTypes"

export const AlertReducer = (state: AlertState = {}, action: AlertActions) => {
    switch (action.type) {
        // case AuthActionTypes.LOGOUT:
        //     return { isLoggedIn: false } as AuthState
        case AlertActionTypes.SET_ALERT:
            return { alert: { text: action.payload.text, type: action.payload.type } }
        case AlertActionTypes.REMOVE_ALERT:
            return { }
        default:
            return state
    }
}