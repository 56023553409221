import { StoreState } from ".."
import { User } from "../../interfaces/auth/UserTypes"
import { Bid } from "../../interfaces/bids/BidTypes"
import { ChangeOrder } from "../../interfaces/change/ChangeOrderTypes"
import { Customer } from "../../interfaces/customers/CustomerTypes"
import { Employee } from "../../interfaces/employees/EmployeeTypes"
import { Invoice } from "../../interfaces/invoices/InvoiceTypes"
import { Job } from "../../interfaces/jobs/JobTypes"
import { Labor } from "../../interfaces/labor/LaborTypes"
import { Material } from "../../interfaces/materials/MaterialTypes"
import { ServiceStatusValues } from "../../interfaces/Service"
import { Supplier } from "../../interfaces/suppliers/SupplierTypes"
import { PrevailingWage } from "../../interfaces/wages/PrevailingWageTypes"

export const selectJobsData = (state: StoreState): Job[] => {
    if (state.ModuleReducer.jobs.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.jobs.payload.data
    }
    return []
} 

export const selectBidsData = (state: StoreState): Bid[] => {
    if (state.ModuleReducer.bids.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.bids.payload.data
    }
    return []
} 

export const selectCustomersData = (state: StoreState): Customer[] => {
    if (state.ModuleReducer.customers.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.customers.payload.data
    }
    return []
}

export const selectUsersData = (state: StoreState): User[] => {
    if (state.ModuleReducer.users.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.users.payload.data
    }
    return []
} 


export const selectEmployeeData = (state: StoreState): Employee[] => {
    if (state.ModuleReducer.employees.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.employees.payload.data
    }
    return []
} 

export const selectLaborData = (state: StoreState): Labor[] => {
    if (state.ModuleReducer.labor.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.labor.payload.data
    }
    return []
} 

export const selectChangeOrderData = (state: StoreState): ChangeOrder[] => {
    if (state.ModuleReducer.changeOrders.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.changeOrders.payload.data
    }
    return []
} 

export const selectInvoiceData = (state: StoreState): Invoice[] => {
    if (state.ModuleReducer.invoices.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.invoices.payload.data
    }
    return []
} 

export const selectSupplierData = (state: StoreState): Supplier[] => {
    if (state.ModuleReducer.suppliers.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.suppliers.payload.data
    }
    return []
} 

export const selectMaterialData = (state: StoreState): Material[] => {
    if (state.ModuleReducer.materials.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.materials.payload.data
    }
    return []
} 

export const selectPrevailingWageData = (state: StoreState): PrevailingWage[] => {
    if (state.ModuleReducer.wages.status === ServiceStatusValues.Loaded) {
        return state.ModuleReducer.wages.payload.data
    }
    return []
} 
