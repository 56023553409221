import blobStream from 'blob-stream';
import { ChangeOrder, ChangeOrderItem } from "../../../interfaces/change/ChangeOrderTypes"

interface FileDictionary {
    [key: string]:{
        url: string,
        data?: any,
        xhr?: XMLHttpRequest
    }
}

export const generateChangeOrderPdf = (rowObject: ChangeOrder) =>{
	return new Promise(async (resolve) => {
		var filesLoaded = 0;
	
		var files: FileDictionary = {
			img1: {
				url: `${window.location.origin}/TruFormHeader.png`,
			},
			img2: {
				url: `${window.location.origin}/TruFormFooter.png`,
			}
		};

		const getTotalCost = () => {
			return rowObject.items.reduce((acc, cur) => acc + cur.cost, 0).toFixed(2)
		}

		//@ts-ignore
		const doc = new PDFDocument({
			margins: {
			top: 100,
			bottom: 90,
			left: 36,
			right: 36            
		}});        

		const [b1, b2] = await Promise.all([fetch(files['img1'].url), fetch(files['img2'].url)])
		const a1 = await b1.arrayBuffer();
		const a2 = await b2.arrayBuffer();
		doc.image(a1, 24, 40, {fit: [572, 38]});
		doc.image(a2, 20, 705, {fit: [572, 19]});
		doc.on('pageAdded', () => doc.image(a1, 24, 40, {fit: [572, 38]}));
		doc.on('pageAdded', () => doc.image(a2, 20, 705, {fit: [572, 19]}));

		const stream = doc.pipe(blobStream());


		doc.font('Helvetica-Bold');
		doc.fontSize(10);
		//Left Headers
		doc.text('Job Number', 20, 105, {align: 'right', width: 75})
		.moveDown(0.5)
		.text('CustomerName', {align: 'right', width: 75})
		.moveDown(0.5)
		.text('JobName', {align: 'right', width: 75})
		//Right Headers
		doc.text('Date', 20, 105, {align: 'right', width: 440})
		.moveDown(1)
		.text('Contract #', {align: 'right', width: 440})
		.moveDown(0.5)
		.text('Change Order Number', {align: 'right', width: 440})
		//Left Values
		doc.font('Helvetica');
		doc.text(rowObject.job.id, 100, 105)
		.moveDown(0.5)    
		.text(rowObject.job.customer!.name)
		.moveDown(0.5)
		.text(rowObject.job.name)
		.moveDown(0.1)
		doc.text(rowObject.job.address, {width: 132})

		//Right Values        
		doc.text(new Date(rowObject.date).toDateString(), 464, 105)        
		.moveDown(1.05)
		.text(rowObject.contractNumber ? rowObject.contractNumber : ' ')
		.moveDown(0.55)
		.text(rowObject.number ?? " ")
		//Agreement
		doc.font('Helvetica-Bold');
		doc.fontSize(12);
		doc.text('AMENDMENT/CHANGE TO SUBCONTRACT AGREEMENT', 130, 210, {underline: true})
		
		doc.font('Helvetica');
		doc.fontSize(10);
		doc.text('This hereby amends the contract executed by and between', 40, 240, {indent:20})
		//customer name line
		doc.moveTo(324, 250)
			.lineTo(468, 250)
			.stroke()
		//customer name
		doc.font('Helvetica-Bold');        
		doc.text(rowObject.job.customer!.name, 324, 240)

		doc.font('Helvetica');        
		doc.text(', contractor and', 470, 240)

		doc.font('Helvetica-Bold')
		.text('TruForm Construction Inc.', 40, undefined, {continued: true});//i think there's a JS function to handle the arguments in a cleaner way
		
		doc.font('Helvetica');
		doc.text(', for the job located at,')
		//address line
		doc.moveTo(268, 262)
			.lineTo(550, 262)
			.stroke()
		//address
		doc.font('Helvetica-Bold');        
		doc.text(rowObject.job.address, 270, 252)

		doc.font('Helvetica')
		.text('covering work to be performed under contract is hereby modified as follows:', 40)
		.moveDown(0.5);

		doc.font('Helvetica-Bold');        
		doc.text('Subcontractor agrees to perform work as set forth:')
		.moveDown(0.5);
		//order details
		doc.font('Helvetica');
		doc.text(rowObject.additionalNotes)
		.moveDown(1.5);
		
		rowObject.items.forEach((item: ChangeOrderItem) => {
		    doc.text(item.description !== "" ? item.description : "No Description", {continued: true, width: 490})
		    .text(`$${item.cost.toFixed(2)}`, {align: 'right'})
		    .moveDown(1)
		});

		// if (rowObject.items.length < 6) doc.moveDown(6 - rowObject.items.length)


		doc.font('Helvetica-Bold');        
		doc.text('Total Cost', {width: 440, align: 'right'})

		doc.font('Helvetica');
		doc.moveUp(1).text(`$${getTotalCost()}`, {align: 'right', underline: true, width: 490}).moveDown(1);

		doc.fontSize(9);
		doc.text('It is understood and agreed that the original contract referred herein is increased by the amount of', { continued: true })
		doc.fontSize(10).text(`                     $${getTotalCost()}`, { }).fontSize(9);
		doc.text('is non-negotiable, due and payable upon completion. This addendum / change is null and void if not accepted within 30 days.')
		doc
		.moveDown(3)
		//acception boxes
		doc.fontSize(10).text('This addendum / change accepted', { continued: true })

		doc.fontSize(12);
		doc.text('                             ', { continued: true });
		doc.lineJoin('bevel')
		.rect(doc.x + 240, doc.y - 2, 16, 16)
		.stroke();
		doc.text('    yes', { continued: true });
		doc.text('         ', { continued: true });
		doc.lineJoin('bevel')
		.rect(doc.x + 240 + 50, doc.y - 2, 16, 16)
		.stroke();
		doc.text('no' , { continued: true });

		doc.fontSize(10);
		doc.text(`          `, {continued: true})
		doc.text(`$${getTotalCost()}`, {underline: true})
		.moveDown(2.5)
		//fine print
		doc.fontSize(6);
		doc.text('This change order covers only the direct cost "labor, material, equipment, etc.". No compensation is included for the delays or extra costs incurred by reason of the delays, suspension of the work and other impacts created by the issuance of this change order. We reserve our rights to make a claim for an extension of time and for extra costs for delays to the work, inefficiencies and impacts created by this change order until the end of the project when the overall effect of this change order can be accurately determined. All other terms and conditions of the original contract are to remain the same')
		.moveDown(0.5);
		
		doc.fontSize(10);
		console.log(doc.y);
		if (doc.y >= 565) doc.addPage();
		doc.text('IN WITNESS WHEREOF, the said parties have hereunto set their hands this date:')
		.moveDown(2)
		
		//signature labels
		doc.font('Helvetica-Bold');
		doc.text('By:     ', {indent: 25, align: 'left', width: 500, continued: true})
		doc.text('                                                                                  ', { underline: true, continued: true})
		doc.font('Helvetica').text(', Contractor', { underline: false })
		.moveDown(1)
		doc.font('Helvetica-Bold')
		.text('Title:  ', {indent: 25, align: 'left', width: 500, continued: true})
		.text('                                                                                  ', { underline: true, continued: true})
		.font('Helvetica').text('       Date ', { underline: false, continued: true })
		.text('                                    ', { underline: true})
		.moveDown(3)
		doc.font('Helvetica-Bold')
		.text('By:     ', {indent: 25, align: 'left', width: 500, continued: true})
		.text('                                                                                  ', { underline: true, continued: true})
		.font('Helvetica').text('       Date ', { underline: false, continued: true })
		.text('                                    ', { underline: true })
		.moveDown(1)
		
		doc.text('Dan Ruppe, President', { indent: 45 });
		doc.font('Helvetica-Bold');
		doc.text('TruForm Construction Corporation' , { indent: 40 })
		// //contractor line
		// doc.moveTo(80, 594)
		// 	.lineTo(328, 594)
		// 	.stroke()
		// //title line
		// doc.moveTo(80, 617)
		// 	.lineTo(328, 617)
		// 	.stroke()
		// //title date line
		// doc.moveTo(384, 617)
		// 	.lineTo(480, 617)
		// 	.stroke()
		// //president line
		// doc.moveTo(80, 664)
		// 	.lineTo(328, 664)
		// 	.stroke()
		// //president date line
		// doc.moveTo(384, 664)
		// 	.lineTo(480, 664)
		// 	.stroke()
		doc.end();
		
		stream.on('finish', function() {
			const blob = stream.toBlob('application/pdf');
			const link = document.createElement('a');
			const url = window.URL.createObjectURL(blob);
			link.href = url;
			link.target = "_blank";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			resolve(true)
		});
	})
}
