import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { LaborApi } from "../../api/ModuleApi"
import { doesValueExist, formatDateToInput } from "../../helpers/IndexHelpers"
import { Labor } from "../../interfaces/labor/LaborTypes"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectLaborData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import { CheckForNullCell } from "../shared/table/CheckForNullCell"
import { EditLaborForm } from "./form/EditLaborForm"
import { NewLaborForm } from "./form/NewLaborForm"

const filters: SearchFilter[] = [
    { title: "#", keys: ["id"], isActive: true },
    { title: "Customer", keys: ["job.customer.name", "job.customer.id"], isActive: true },
    { title: "Job", keys: ["job.name", "job.id"], isActive: true },
    { title: "Employee", keys: ["employee.fullName", "employee.id"], isActive: true },
]

const columns = [
    { field: 'id', headerName: '#', flex: 0.2, type: 'number' },
    { field: 'jobName', headerName: 'Job', flex: 0.7, renderCell: CheckForNullCell },
    { field: 'customerName', headerName: 'Customer', flex: 1, renderCell: CheckForNullCell },
    { field: 'employeeName', headerName: 'Employee', flex: 0.6, renderCell: CheckForNullCell },
    { field: 'hours', headerName: 'Hours', flex: 0.3, type: "number" },
    { field: 'weekEnding', headerName: 'Week Ending', flex: 0.5, type: "date" },
];

export const LaborPage = () => {
    const laborData = useSelector(selectLaborData)

    useEffect(() => {
        LaborApi.fetchAll()
    }, [])

    const getRows = (tableData: Labor[]) => {
        return tableData.map((labor) => {
            const endDate = labor.weekEndingDate ?
                new Date(labor.weekEndingDate) : ""
            return {
                id: labor.id,
                customerName: labor.job ? (doesValueExist(labor.job.customer) ? labor.job.customer!.name : "") : null,
                jobName: labor.job ? labor.job.name : null,
                employeeName: labor.employee ? labor.employee.fullName : null,
                weekEnding: formatDateToInput(endDate),
                hours: labor.tapingLaborHours + labor.hangFramingHours,
                rowObject: labor
            }
        })
    }

    return (
        <DataPage
            filters={filters}
            title={"Labor"}
            newButtonTitle={"New Labor"}
            pageData={laborData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewLaborForm}
            editForm={EditLaborForm}
        />
    )
}