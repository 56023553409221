import { GridCellParams } from "@material-ui/data-grid"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { JobsApi } from "../../api/ModuleApi"
import { Job } from "../../interfaces/jobs/JobTypes"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectJobsData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import { CheckForNullCell } from "../shared/table/CheckForNullCell"
import { EditJobForm } from "./edit/EditJobForm"
import './JobsPage.css'
import { NewJobForm } from "./new/NewJobForm"

const filters: SearchFilter[] = [
    { title: "Customer", keys: ["customer.name", "customer.id"], isActive: true },
    { title: "Job", keys: ["name", "id"], isActive: true },
]

const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5, type: 'number' },
    { field: 'name', headerName: 'Job Name', flex: 2 },
    { field: 'customer', headerName: 'Customer', flex: 2, renderCell: CheckForNullCell },
    { field: 'address', headerName: 'Address', flex: 2 },
    { 
        field: 'status', 
        headerName: 'Status', 
        flex: 1.5,
        renderCell: (params: GridCellParams) => (
            <div className={`jobs-table-status`}>
                <div className={`jobs-table-status-${(params.value as string).toLowerCase().replace(/\s/g, '')}`}>{ params.value }</div>
            </div>
        )
    }
];

export const JobsPage = () => {
    const jobData = useSelector(selectJobsData)

    useEffect(() => {
        JobsApi.fetchAll()
    }, [])

    const getRows = (tableData: Job[]) => {
        return tableData.map((job) => {
            return {
                id: job.id,
                customer: job.customer ? `${job.customer.name} #${job.customer.id}` : null,
                name: job.name!,
                address: job.address,
                status: job.status,
                rowObject: job
            }
        })
    }

    return (
        <DataPage
            filters={filters}
            title={"Jobs"}
            newButtonTitle={"New Job"}
            pageData={jobData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewJobForm}
            editForm={EditJobForm}
        />
    )
}