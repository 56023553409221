export const doesValueExist = (value: any) => {
    return (typeof value !== "undefined" && value !== null)
}

export const doesStringHaveValue = (value?: string) => {
    return (typeof value !== "undefined" && value !== "")
}

export const getNumberFromStringValue = (val?: string) => {
    if (typeof val === "undefined" || isNaN(parseFloat(val))) { return 0 }
    return parseFloat(val)
}

export const getStringFromNumberValue = (val?: number) => {
    if (typeof val === "undefined") { return undefined }
    return `${val}`
}

export const getDollarFigureFromNumber = (val? : number) => {
    return val ? parseFloat(val.toFixed(2)).toLocaleString() : "0"
}

export const getLocalDate = (date?: Date | string) => {
    if (date === undefined || date === null || date === "") { return "" }
    let d = new Date(date);
    d.setMinutes(d.getTimezoneOffset());
    return d;
}

export const formatDateToInput = (date?: Date | string) => {
    if (date === undefined || date === null || date === "") { return "" }
    var d = new Date(date),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + d.getUTCDate(),
        year = d.getUTCFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}