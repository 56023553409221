import { WagesApi } from '../../../api/ModuleApi';
import { getWagesDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { PrevailingWage, PrevailingWageFormData } from '../../../interfaces/wages/PrevailingWageTypes';
import { EditForm } from '../../shared/forms/EditForm';
import { PrevailingWageForm } from './PrevailingWageForm';

export const EditPrevailingWageForm = ({ currentValue, onClose }: EditFormProps<PrevailingWage>) => {
    const initialValues: PrevailingWageFormData = {
        ...currentValue,
        value: currentValue.value.toString(),
        startDate: formatDateToInput(currentValue.startDate),
        endDate: formatDateToInput(currentValue.endDate),
    }

    const handleUpdate = (formData: PrevailingWageFormData) => {
        WagesApi.edit(currentValue.id, getWagesDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemove = () => {
        WagesApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <EditForm
            title="Prevailing Wage"
			form={ 
				<PrevailingWageForm
					title="Prevailing Wage"
					formType={FormType.Edit}
					formData={initialValues}
					onSubmit={ (values) => handleUpdate(values) }
				/> 
			}
            onClose={onClose}
            handleDeleteAction={handleRemove}
        />
    )
}