import { Divider, InputAdornment, TextField } from "@material-ui/core"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormikProps, withFormik } from "formik"
import { JobValidationSchema } from "../../../../helpers/FormValidators"
import { Customer } from "../../../../interfaces/customers/CustomerTypes"
import { FormProps } from "../../../../interfaces/forms/FormTypes"
import { JobFormData } from "../../../../interfaces/jobs/JobTypes"
import { FormErrorMessage } from "../../../shared/forms/FormErrorMessage"
import { FormSubmitButton } from "../../../shared/forms/FormSubmitButton"
import { AddressInput } from "../../../shared/inputs/AddressInput"
import { StatusInput } from "../inputs/StatusInput"
import { YesNoInput } from  "../../../shared/inputs/YesNoInput";
import { ModuleDataLimited } from "../../../../store/modules/ModuleTypes"
import { useEffect, useState } from "react"
import { CustomersApi } from "../../../../api/ModuleApi"
import { getLatestPrevailngWage } from "../../../../api/IndexApi"

const InnerForm = (props: FormProps<JobFormData> & FormikProps<JobFormData>) => {
    const [customerData, setCustomerData] = useState<ModuleDataLimited[]>([])

    useEffect(() => {
        CustomersApi.fetchAllLimited().then((r) => setCustomerData(r as ModuleDataLimited[]))
    }, [])

	const { 
		handleChange, 
        setFieldValue,
        setFieldTouched,
		isValid, 
		formType, 
		handleSubmit,
        isSubmitting,
        title,
		values,
        handleBlur,
        touched,
        errors
	} = props;
	return (
		<form>
            <div className="form-input-container">
                <TextField 
                    label="Job Name" 
                    value={values.name} 
                    name="name" 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.name && Boolean(errors.name)}
                />
				{ touched.name && Boolean(errors.name) && <FormErrorMessage errorMessage={errors.name} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Job Owner" 
                    value= {values.owner} 
                    onChange={handleChange}
                    name="owner"
                />
            </div>
            <div className="form-input-container">
                <Autocomplete
                    options={customerData}
                    getOptionLabel={(customer: Customer) => `${customer.name} #${customer.id}`}
                    value={ values.customer }
                    renderInput={(params) => 
                        <TextField 
                            name="customer" 
                            {...params} 
                            label="Customer" 
                            error={touched.customer && Boolean(errors.customer)}
                        />
                    }
                    onChange={ (e, v) => {
                        CustomersApi.fetchById(v?.id ?? -1)
                        .then((response) => {
                            setFieldValue("customer", v) 
                            setFieldTouched("customer", true)
                        })
                    }}
                    onBlur={handleBlur}
                />
                { touched.customer && Boolean(errors.customer) && <FormErrorMessage errorMessage={errors.customer} /> }
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Status
                </h1>
                <StatusInput jobStatus={values.status} handleJobStatusChange={ status => setFieldValue("status", status) }/>
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Prevailing Wage
                </h1>
                <YesNoInput 
                    value={ values.showPrevailingWage } 
                    handleAnswerChange={ value => {
                        setFieldValue("showPrevailingWage", value) 
                        if (!value) { setFieldValue("prevailingWage", null )}
                        if (value) { 
                            getLatestPrevailngWage()
                            .then(pw => setFieldValue("prevailingWage", pw.value))
                        }
                    }}
                />
            </div>
            { 
                values.showPrevailingWage && 
                <div className="form-input-container">
                    <TextField 
                        label="Prevailing Wage" 
                        value= {values.prevailingWage} 
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        name="prevailingWage"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.prevailingWage && Boolean(errors.prevailingWage)}
                    />
                    { touched.prevailingWage && Boolean(errors.prevailingWage) && <FormErrorMessage errorMessage={errors.prevailingWage} /> }
                </div>
            }
            <div className="form-input-container">
                <AddressInput address={values.address} setAddress={ a => setFieldValue("address", a) } />
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Date
                </h1>
                <TextField 
                    value={values.date} 
                    type="date"
                    name="date"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.date && Boolean(errors.date)}
                />
				{ touched.date && Boolean(errors.date) && <FormErrorMessage errorMessage={errors.date} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Man Day Price" 
                    value= {values.manDayPrice} 
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    name="manDayPrice"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.manDayPrice && Boolean(errors.manDayPrice)}
                />
				{ touched.manDayPrice && Boolean(errors.manDayPrice) && <FormErrorMessage errorMessage={errors.manDayPrice} /> }
            </div>
            <Divider />
            <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px" }}>
                <strong>Original Contract</strong>
            </h1>
            <div className="form-input-container">
                <TextField 
                    label="Contract Amount" 
                    value= {values.contractPrice} 
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    name="contractPrice"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.contractPrice && Boolean(errors.contractPrice)}
                />
				{ touched.contractPrice && Boolean(errors.contractPrice) && <FormErrorMessage errorMessage={errors.contractPrice} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Hang Framing" 
                    value= {values.hangFramingCost} 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    type="number"
                    name="hangFramingCost"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.hangFramingCost && Boolean(errors.hangFramingCost)}
                />
				{ touched.hangFramingCost && Boolean(errors.hangFramingCost) && <FormErrorMessage errorMessage={errors.hangFramingCost} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Taping Labor" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    value= {values.tapingLaborCost} 
                    type="number"
                    name="tapingLaborCost"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.tapingLaborCost && Boolean(errors.tapingLaborCost)}
                />
				{ touched.tapingLaborCost && Boolean(errors.tapingLaborCost) && <FormErrorMessage errorMessage={errors.tapingLaborCost} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Material Costs" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    value= {values.materialCost} 
                    type="number"
                    name="materialCost"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.materialCost && Boolean(errors.materialCost)}
                />
				{ touched.materialCost && Boolean(errors.materialCost) && <FormErrorMessage errorMessage={errors.materialCost} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Misc Costs" 
                    value= {values.miscCost} 
                    type="number"
                    name="miscCost"
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
					onBlur={handleBlur}
					error={touched.miscCost && Boolean(errors.miscCost)}
                />
				{ touched.miscCost && Boolean(errors.miscCost) && <FormErrorMessage errorMessage={errors.miscCost} /> }
            </div>
            <Divider />
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const JobForm = withFormik<FormProps<JobFormData>, JobFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: JobValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Job Form",
})(InnerForm);