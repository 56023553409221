import { InputAdornment, TextField } from "@material-ui/core"
import { ChangeOrderItemFormData } from "../../../../interfaces/change/ChangeOrderTypes"
import './ChangeOrderItemInput.css'

type Props = {
    item: ChangeOrderItemFormData,
    index: number,
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    },
    handleChange: (index: number, key: keyof ChangeOrderItemFormData, value: any) => void
}

export const ChangeOrderItemInput = ({ item, index, handleBlur, handleChange }: Props) => {
    return (
        <div className="change-order-item-input">
            <TextField 
                label="Cost" 
                value= {item.cost} 
                style={{ width: "25%" }}
                type="number"
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                onChange={ e => handleChange(index, "cost", e.target.value)}
                onBlur={handleBlur}
            />            
            <TextField 
                label="Description" 
                value= {item.description} 
                style={{ width: "70%" }}
                onChange={ e => handleChange(index, "description", e.target.value) }
                onBlur={handleBlur}
            />            
        </div>
    )
}