import { TextField } from '@material-ui/core'
import { useState } from 'react'
import AuthenticationImage from './authentication.svg'
import '../login/LoginPage.css'
import { Link } from 'react-router-dom'

export const ForgotPasswordPage = () => {
    const [email, setEmail] = useState("")

    return (
        <div className="login-page-container">
            <h1 className="login-logo"><span className="logo-icon">TF</span> Construction</h1>
            <div className="login-page-form-container">
                <div className="login-form-content">
                    <p className="login-label">Reset</p>
                    <div className="login-form-input-container">
                        <TextField 
                            variant="outlined" 
                            type="email"
                            label="Email" 
                            value= {email} 
                            onChange={ e => setEmail(e.target.value) }
                        />
                    </div>
                    <button 
                        className="login-form-submit-button"
                    >
                        SUBMIT
                    </button>
                    <Link to="/login">
                        <button 
                            className="login-form-forgot-button"
                        >
                            Back
                        </button>
                    </Link>
                </div>
            </div>
            <div className="login-page-right-container">
                <h1 className="welcome-back-text">Forgot Password?</h1>
                <img className="welcome-image" src={AuthenticationImage} alt="Forgot Password" />
                <p className="welcome-app-title">TruForm Construction Management Application</p>
            </div>
        </div>
    )
}