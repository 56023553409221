import { useEffect } from "react";
import { useSelector } from "react-redux";
import { WagesApi } from "../../api/ModuleApi";
import { SearchFilter } from "../../interfaces/search/FilterTypes";
import { PrevailingWage } from "../../interfaces/wages/PrevailingWageTypes";
import { selectPrevailingWageData } from "../../store/modules/ModuleSelectors";
import { DataPage } from "../shared/pages/DataPage";
import { EditPrevailingWageForm } from "./form/EditPrevailingWageForm";
import { NewPrevailingWageForm } from "./form/NewPrevailingWageForm";

const filters: SearchFilter[] = [
	{ title: "#", keys: ["id"], isActive: true },
	{ title: "Value", keys: ["value"], isActive: true },
	{ title: "Start Date", keys: ["startDate"], isActive: true },
	{ title: "End Date", keys: ["endDate"], isActive: true },
]

const columns = [
	{ field: 'id', headerName: '#', flex: 0.3, type: 'number' },
	{ field: 'value', headerName: 'Value', flex: 0.7, type: 'number' },
	{ field: 'startDate', headerName: 'Start Date', flex: 1 },
	{ field: 'endDate', headerName: 'End Date', flex: 1 }
];

export const PrevailingWagePage = () => {
    const pageData = useSelector(selectPrevailingWageData)

    useEffect(() => {
        WagesApi.fetchAll()
    }, [])

	const getRows = (tableData: PrevailingWage[]) => {
		return tableData.map((pw) => {
			return {
				id: pw.id,
				value: `$${pw.value}`,
				startDate: new Date(pw.startDate).toDateString(),
				endDate: new Date(pw.endDate).toDateString(),
				rowObject: pw
			}
		})
	}

	return (
		<DataPage
			filters={filters}
			title={"Prevailing Wages"}
			newButtonTitle={"New Wage"}
			pageData={pageData}
			tableColumns={columns}
			getRows={ tableData => getRows(tableData) }
			newForm={NewPrevailingWageForm}
			editForm={EditPrevailingWageForm}
		/>
	)
}