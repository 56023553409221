import { MenuItem, Select, TextField } from "@material-ui/core";
import { FormikProps, withFormik } from "formik";
import { useEffect, useState } from "react";
import { PrintFormatChangeOrderApi, PrintFormatInvoiceApi } from "../../../api/LimitedModuleApi";
import { CustomerValidationSchema } from "../../../helpers/FormValidators";
import { CustomerFormData } from "../../../interfaces/customers/CustomerTypes";
import { FormProps } from "../../../interfaces/forms/FormTypes";
import { ModuleDataLimited } from "../../../store/modules/ModuleTypes";
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage";
import { FormSubmitButton } from "../../shared/forms/FormSubmitButton";
import { AddressInput } from "../../shared/inputs/AddressInput";

const InnerForm = (props: FormProps<CustomerFormData> & FormikProps<CustomerFormData>) => {
	const { 
		handleChange, 
        setFieldValue,
        setFieldTouched,
		isValid, 
		formType, 
		handleSubmit,
        isSubmitting,
        title,
		values,
        handleBlur,
        touched,
        errors
	} = props;

    
    const [printViewCOData, setPrintViewCO] = useState<ModuleDataLimited[]>([])
    const [printViewInvoiceData, setPrintViewInvoice] = useState<ModuleDataLimited[]>([])


    useEffect( ()=> {
        PrintFormatChangeOrderApi.fetchAll().then( data => setPrintViewCO( data as ModuleDataLimited[]))
        PrintFormatInvoiceApi.fetchAll().then( data => setPrintViewInvoice( data as ModuleDataLimited[]))
    }, [])

	return (
        
		<form>
            <div className="form-input-container">
                <TextField 
                    label="Customer Name" 
                    value= {values.name} 
                    name="name"
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.name && Boolean(errors.name)}
                />
				{ touched.name && Boolean(errors.name) && <FormErrorMessage errorMessage={errors.name} /> }
            </div>
            <div className="form-input-container">
                <AddressInput address={ values.address } setAddress={ s => setFieldValue("address", s) } />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Contact" 
                    name="contact"
                    value= {values.contact} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Phone" 
                    name="mainPhone"
                    value= {values.mainPhone} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Phone" 
                    name="phone2"
                    value= {values.phone2} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Email" 
                    type="email"
                    name="email"
                    value= {values.email} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Federal Tax ID" 
                    name="federalTaxId"
                    value= {values.federalTaxId} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Contractor's State License Number" 
                    type="number"
                    name="contractorLicenseNum"
                    value= {values.contractorLicenseNum} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Change Order Print Format
                </h1>
                <Select
                    id="change-order-select"
                    value={ values.printFormatChangeOrder ? values.printFormatChangeOrder.name : "Default" }
                    error={touched.printFormatChangeOrder && Boolean(errors.printFormatChangeOrder)}
                    variant="outlined"
                    onChange={(e) => {
                        setFieldValue("printFormatChangeOrder", printViewCOData.find((data) => data.name === e.target.value))
                        setFieldTouched("printFormatChangeOrder", true)
                    }}
                >
                    {
                        printViewCOData.map((data) => (
                            <MenuItem value={data.name}>{ data.name }</MenuItem>
                        ))
                    }
                </Select>
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Invoice Print Format
                </h1>
                <Select
                    value={ values.printFormatInvoice ? values.printFormatInvoice.name : "Default" }
                    label="Invoice Print Format" 
                    error={touched.printFormatInvoice && Boolean(errors.printFormatInvoice)}
                    variant="outlined"
                    onChange={(e) => {
                        setFieldValue("printFormatInvoice", printViewInvoiceData.find((data) => data.name === e.target.value))
                        setFieldTouched("printFormatInvoice", true)
                    }}
                >
                    {
                        printViewInvoiceData.map((data) => (
                            <MenuItem value={data.name}>{ data.name }</MenuItem>
                        ))
                    }
                </Select>
            </div>
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const CustomerForm = withFormik<FormProps<CustomerFormData>, CustomerFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: CustomerValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Customer Form",
})(InnerForm);