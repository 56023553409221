import { InputAdornment, TextField } from "@material-ui/core"
import { FormikProps, withFormik } from "formik";
import { EmployeeValidationSchema } from "../../../helpers/FormValidators";
import { EmployeeFormData } from "../../../interfaces/employees/EmployeeTypes"
import { FormProps } from "../../../interfaces/forms/FormTypes"
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage";
import { FormSubmitButton } from "../../shared/forms/FormSubmitButton";
import { AddressInput } from "../../shared/inputs/AddressInput"

const InnerForm = (props: FormProps<EmployeeFormData> & FormikProps<EmployeeFormData>) => {
	const { 
		handleChange, 
        setFieldValue,
		handleSubmit,
		values,
        isValid,
        isSubmitting,
        title,
        formType,
        handleBlur,
        errors,
        touched
	} = props;
	return (
		<form>
            <div className="form-input-container">
                <TextField 
                    label="First Name" 
                    name="firstName"
                    value= {values.firstName} 
                    onChange={handleChange}
					onBlur={handleBlur}
					error={touched.firstName && Boolean(errors.firstName)}
                />
				{ touched.firstName && Boolean(errors.firstName) && <FormErrorMessage errorMessage={errors.firstName} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Last Name" 
                    name="lastName"
                    value= {values.lastName} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Job Title" 
                    name="jobTitle"
                    value= {values.jobTitle} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Cell Phone" 
                    name="cellPhone"
                    value= {values.cellPhone} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Phone" 
                    name="mainPhone"
                    value= {values.mainPhone} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <AddressInput address={ values.address } setAddress={ s => setFieldValue("address", s) } />
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Hire Date
                </h1>
                <TextField 
                    type="date"
                    name="hireDate"
                    value= {values.hireDate} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Birth Date
                </h1>
                <TextField 
                    type="date"
                    name="birthDate"
                    value= {values.birthDate} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Social Security" 
                    name="socialSecurityNum"
                    value= {values.socialSecurityNum} 
                    onChange={handleChange}
                    onBlur={handleBlur}
					error={touched.socialSecurityNum && Boolean(errors.socialSecurityNum)}
                />
				{ touched.socialSecurityNum && Boolean(errors.socialSecurityNum) && <FormErrorMessage errorMessage={errors.socialSecurityNum} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Email" 
                    name="email"
                    value= {values.email} 
                    type="email"
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
            <h1 className="form-input-label" style={{ marginLeft: 0 }}>
                <strong>Hourly Pay Rates</strong>
            </h1>
            <div className="form-input-container">
                <TextField 
                    label="H/F Rate" 
                    name="hangFramingRate"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    value= {values.hangFramingRate} 
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
					error={touched.hangFramingRate && Boolean(errors.hangFramingRate)}
                />
				{ touched.hangFramingRate && Boolean(errors.hangFramingRate) && <FormErrorMessage errorMessage={errors.hangFramingRate} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="TL Rate $" 
                    name="tapingLaborRate"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    value= {values.tapingLaborRate} 
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
					error={touched.tapingLaborRate && Boolean(errors.tapingLaborRate)}
                />
				{ touched.tapingLaborRate && Boolean(errors.tapingLaborRate) && <FormErrorMessage errorMessage={errors.tapingLaborRate} /> }
            </div>
            <h1 className="form-input-label" style={{ marginLeft: 0 }}>
                <strong>Emergency Contact</strong>
            </h1>
            <div className="form-input-container">
                <TextField 
                    label="Name" 
                    name="emergencyContact1.name"
                    value= {values.emergencyContact1?.name} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Phone" 
                    name="emergencyContact1.phone"
                    value= {values.emergencyContact1?.phone} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Relationship" 
                    name="emergencyContact1.relationship"
                    value= {values.emergencyContact1?.relationship} 
                    onChange={handleChange}
                />
            </div>
            <h1 className="form-input-label" style={{ marginLeft: 0 }}>
                <strong>Additonal Emergency Contact</strong>
            </h1>
            <div className="form-input-container">
                <TextField 
                    label="Name" 
                    name="emergencyContact2.name"
                    value= {values.emergencyContact2?.name} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Phone" 
                    name="emergencyContact2.phone"
                    value= {values.emergencyContact2?.phone} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Relationship" 
                    name="emergencyContact2.relationship"
                    value= {values.emergencyContact2?.relationship} 
                    onChange={handleChange}
                />
            </div>
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const EmployeeForm = withFormik<FormProps<EmployeeFormData>, EmployeeFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: EmployeeValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Employee Form",
})(InnerForm);