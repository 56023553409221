import { Divider, InputAdornment, TextField } from "@material-ui/core"
import { PDFViewButton } from "../../shared/forms/PdfViewButton"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { FormikProps, withFormik } from "formik"
import { useEffect, useState } from "react"
import { JobsApi } from "../../../api/ModuleApi"
import { getInvoiceFormCalculations } from "../../../helpers/DataHelpers"
import { InvoiceValidationSchema } from "../../../helpers/FormValidators"
import { FormProps, FormType } from "../../../interfaces/forms/FormTypes"
import { InvoiceFormCalculations, InvoiceFormData } from "../../../interfaces/invoices/InvoiceTypes"
import { ModuleDataLimited } from "../../../store/modules/ModuleTypes"
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage"
import { FormSubmitButton } from "../../shared/forms/FormSubmitButton"
import { getNextInvoiceNumber } from "../../../api/IndexApi"

const InnerForm = (props: FormProps<InvoiceFormData> & FormikProps<InvoiceFormData>) => {
    const [jobData, setJobData] = useState<ModuleDataLimited[]>([])
    const [calculations, setCalculations] = useState<InvoiceFormCalculations>({
        revisedContractAmount: 0,
        amountCompletedToDate: 0,
        lessRetentionAmount: 0,
        totalThisStatement: 0,
        totalDueThisStatement: 0
    })

    const handleViewAndSubmit = (isProgress: boolean) => {
        setFieldValue("generateInvoicePdfProgress", isProgress) 
        setFieldValue("generateInvoicePdfFinal", !isProgress) 
        handleSubmit()
    }

    const setInvoiceNumber = () => {
        getNextInvoiceNumber()
            .then( res => {
                setFieldValue("invoiceNumber", res)
                setFieldTouched("invoiceNumber", true)
            })
    }

    useEffect(() => {
        JobsApi.fetchAllLimited().then((r) => setJobData(r as ModuleDataLimited[]))
        if (formType === FormType.New) setInvoiceNumber();
    }, [])

	const { 
		handleChange, 
        setFieldValue,
		handleSubmit,
        handleBlur,
		values,
        setFieldTouched,
        isValid,
        isSubmitting,
        title,
        touched,
        errors,
        formType
	} = props;

    useEffect(() => {
        setCalculations(getInvoiceFormCalculations(values))
    }, [values])

	return (
		<form>
            <div className="form-input-container">
                <Autocomplete
                    options={jobData}
                    getOptionLabel={(job: ModuleDataLimited) => `${job.name} #${job.id}`}
                    style={{ width: "100%" }}
                    onBlur={handleBlur}
                    value={ values.job }
                    renderInput={(params) => <TextField {...params} label="Job" error={touched.job && Boolean(errors.job)} />}
                    getOptionSelected={ (option, value) => option.id === value.id }
                    onChange={ (e, v) => {
                        JobsApi.fetchById(v?.id ?? -1)
                        .then((response) => {
                            setFieldValue("job", response) 
                            setFieldTouched("job", true)
                        })
                    }}
                />
                { touched.job && Boolean(errors.job) && <FormErrorMessage errorMessage={errors.job} /> }
            </div>
            <div className="form-input-container">
                <TextField
                    style={{ width: "100%" }}
                    value={values.job?.customer?.name ?? ""}
                    label="Customer"
                    disabled={true}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Contract #" 
                    name="contractNumber"
                    value= {values.contractNumber} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="PO Number" 
                    name="poNumber"
                    value= {values.poNumber} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Invoice Number" 
                    name="invoiceNumber"
                    value= {values.invoiceNumber} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <h1 className="form-input-label">
                    Billing Through Date
                </h1>
                <TextField 
                    type="date"
                    name="billThroughDate"
                    value= {values.billThroughDate} 
                    onBlur={handleBlur}
                    onChange={handleChange}
					error={touched.billThroughDate && Boolean(errors.billThroughDate)}
                />
				{ touched.billThroughDate && Boolean(errors.billThroughDate) && <FormErrorMessage errorMessage={errors.billThroughDate} /> }
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Disputed Amount" 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    type="number"
                    name="disputedAmount"
                    value= {values.disputedAmount}
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Sub Contract Number" 
                    name="subContractNumber"
                    value= {values.subContractNumber} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Contract Amount" 
                    type="number"
                    name="contractAmount"
                    value= {values.contractAmount} 
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Through TFC CO #" 
                    name="throughTrufChangeOrderNumber"
                    value= {values.throughTrufChangeOrderNumber} 
                    onChange={handleChange}
                />
            </div>
            <div className="form-input-container">
                <TextField 
                    label="Change Orders To Date" 
                    type="number"
                    name="changeOrdersToDate"
                    value= {values.changeOrdersToDate} 
                    onChange={handleChange}
                />
            </div>
            <Divider />
                <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px", paddingBottom: "10px" }}>
                    <strong>Revised Contract Amount: </strong>
                    <em>${ calculations.revisedContractAmount.toLocaleString() }</em>
                </h1>
            <Divider />
            <div className="form-input-container">
                <TextField 
                    label="Percentage Completed To Date" 
                    type="number"
                    inputProps={{step: "0.01"}}
                    name="percentageCompletedToDate"
                    value= {values.percentageCompletedToDate} 
                    onChange={handleChange}
                />
            </div>
            <Divider />
                <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px", paddingBottom: "10px" }}>
                    <strong>Amount Completed To Date: </strong>
                    <em>${ calculations.amountCompletedToDate.toLocaleString() }</em>
                </h1>
            <Divider />
            <div className="form-input-container">
                <TextField 
                    label="Less Retention %" 
                    type="number"
                    name="lessRetentionPercentage"
                    value= {values.lessRetentionPercentage} 
                    onChange={handleChange}
                />
            </div>
            <Divider />
                <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px", paddingBottom: "10px" }}>
                    <strong>Less Retention Amount: </strong>
                    <em>${ calculations.lessRetentionAmount.toLocaleString() }</em>
                </h1>
            <Divider />
                <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px", paddingBottom: "10px" }}>
                    <strong>Total This Statement: </strong>
                    <em>${ calculations.totalThisStatement.toLocaleString() }</em>
                </h1>
            <Divider />
            <div className="form-input-container">
                <TextField 
                    label="Less Previously Billed" 
                    type="number"
                    name="lessPreviouslyBilled"
                    value= {values.lessPreviouslyBilled} 
                    onChange={handleChange}
                />
            </div>
            <Divider />
                <h1 className="form-input-label" style={{ marginLeft: 0, marginTop: "20px", paddingBottom: "10px" }}>
                    <strong>Total Due This Statement: </strong>
                    <em>${ calculations.totalDueThisStatement.toLocaleString() }</em>
                </h1>
            <Divider />
            <div className="form-input-container">
                <TextField 
                    label="Additional Notes" 
                    rowsMax={5}
                    multiline
                    type="text"
                    name="additionalNotes"
                    value= {values.additionalNotes} 
                    onChange={handleChange}
                />
            </div>
            <FormSubmitButton 
                isValid={isValid} 
                isSubmitting={isSubmitting}
                title={title}
                formType={formType}	
                handleSubmit={handleSubmit}
            />
            <div className="form-buttons-container">
                <PDFViewButton 
                    isValid={isValid} 
                    isSubmitting={isSubmitting}
                    handleSubmit={() => handleViewAndSubmit(true)}
                    text="In Progress PDF"
                />
                <PDFViewButton 
                    isValid={isValid} 
                    isSubmitting={isSubmitting}
                    handleSubmit={() => handleViewAndSubmit(false)}
                    text="Final PDF"
                />
            </div>
		</form>
	);
};

export const InvoiceForm = withFormik<FormProps<InvoiceFormData>, InvoiceFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validateOnBlur: true,
    validationSchema: InvoiceValidationSchema,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Invoice Form",
})(InnerForm);