import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { FormikProps, withFormik } from "formik"
import { NewUserValidationSchema, EditUserValidationSchema } from "../../../../helpers/FormValidators"
import { FormProps, FormType } from "../../../../interfaces/forms/FormTypes"
import { User } from "../../../../interfaces/auth/UserTypes"
import { FormErrorMessage } from "../../../shared/forms/FormErrorMessage"
import { FormSubmitButton } from "../../../shared/forms/FormSubmitButton"

export type UserFormData = Omit<User, 'id'> & { password: string, repeatPassword: string }

const InnerForm = (props: FormProps<UserFormData> & FormikProps<UserFormData>) => {
    const {
        handleChange,
        isValid,
        formType,
        handleSubmit,
        isSubmitting,
        title,
        values,
        handleBlur,
        touched,
        errors
    } = props;
    return (
        <form>
            <div className="form-input-container">
                <TextField
                    label="User Email"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                />
                {touched.email && Boolean(errors.email) && <FormErrorMessage errorMessage={errors.email} />}
            </div>
            <div className="form-input-container">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>User Role</InputLabel>
                    <Select
                        label="User Role"
                        value={values.role}
                        name="role"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value="staff">Staff</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="form-input-container">
                <TextField
                    label={formType === FormType.Edit ? "New Password (optional)" : "Password"}
                    type={'password'}
                    autoComplete={'newPassword'}
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                />
                {touched.password && Boolean(errors.password) && <FormErrorMessage errorMessage={errors.password} />}
            </div>
            <div className="form-input-container">
                <TextField
                    label={formType === FormType.Edit ? "Repeat Password (optional)" : "Repeat Password"}
                    type={'password'}
                    autoComplete={'newPassword'}
                    value={values.repeatPassword}
                    name="repeatPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.repeatPassword && Boolean(errors.repeatPassword)}
                />
                {touched.repeatPassword && Boolean(errors.repeatPassword) && <FormErrorMessage errorMessage={errors.repeatPassword} />}
            </div>
            <FormSubmitButton
                isValid={isValid}
                isSubmitting={isSubmitting}
                title={title}
                formType={formType}
                handleSubmit={handleSubmit}
            />
        </form>
    );
};

export const UserForm = withFormik<FormProps<UserFormData>, UserFormData>({
    mapPropsToValues: props => {
        return props.formData
    },
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: (props: FormProps<UserFormData>) => props.formType === FormType.Edit ? EditUserValidationSchema : NewUserValidationSchema,
    handleSubmit: (values, { setSubmitting, props }) => {
        props.onSubmit(values)
        setSubmitting(false)
    },
    displayName: "User Form",
})(InnerForm);