import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { MaterialsApi } from "../../api/ModuleApi"
import { Material } from "../../interfaces/materials/MaterialTypes"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectMaterialData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import { CheckForNullCell } from "../shared/table/CheckForNullCell"
import { EditMaterialForm } from "./form/EditMaterialForm"
import { NewMaterialForm } from "./form/NewMaterialForm"

const filters: SearchFilter[] = [
    { title: "#", keys: [ "id" ], isActive: true },
    { title: "Supplier", keys: [ "supplier.name", "supplier.id" ], isActive: true },
    { title: "Job", keys: [ "job.name", "job.id" ], isActive: true },
    { title: "Invoice", keys: [ "invoiceNumber" ], isActive: true }
]

const columns = [
    { field: 'id', headerName: '#', flex: 0.2, type: 'number' },
    { field: 'invoice', headerName: 'Invoice #', flex: 0.4 },
    { field: 'jobName', headerName: 'Job', flex: 1, renderCell: CheckForNullCell },
    { field: 'supplierName', headerName: 'Supplier', flex: 1, renderCell: CheckForNullCell },
    { field: 'amount', headerName: 'Amount', flex: 0.6 },
];

export const MaterialsPage = () => {
    const pageData = useSelector(selectMaterialData)

    useEffect(() => {
        MaterialsApi.fetchAll()
    }, [])

    const getRows = (tableData: Material[]) => {
        return tableData.map((material) => {
            return {
                id: material.id,
                jobName: material.job ? material.job.name : null,
                supplierName: material.supplier ? material.supplier.name : null,
                invoice: material.invoiceNumber,
                amount: "$" + material.amount,
                rowObject: material
            }
        })
    }

    return (
        <DataPage
            filters={filters}
            title={"Materials"}
            newButtonTitle={"New Material"}
            pageData={pageData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewMaterialForm}
            editForm={EditMaterialForm}
        />
    )
}