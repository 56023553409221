import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { SearchFilter } from "../../../interfaces/search/FilterTypes"

type Props = {
    onSearchChange: (s: string) => void,
    filters: SearchFilter[],
    onFilterChange: (updatedFilters: SearchFilter[]) => void
}

export const SearchInput = ({ onSearchChange, filters, onFilterChange }: Props) => {
    const [isSearchActive, setSearchActive] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")

    const handleFilterPressed = (filter: SearchFilter) => {
        const updatedFilters = filters.map(f => {
            if (f.title !== filter.title) { return f }
            return { ...f, isActive: !filter.isActive }
        })
        onFilterChange(updatedFilters)
    }

    return (
        <React.Fragment>
            <div className={"page-search-container" + (isSearchActive ? " page-search-container--active" : "")}>
                <FontAwesomeIcon icon={faSearch} />
                <input 
                    type="text" 
                    className="page-search-input" 
                    placeholder="Search" 
                    value={searchQuery}
                    onFocus={ e => setSearchActive(true) }
                    onBlur={ e => setSearchActive(false) }
                    onChange={ e => {
                        onSearchChange(e.target.value)
                        setSearchQuery(e.target.value) 
                    }}
                />
            </div>
            {
                filters.map((f) => (
                    <div 
                        key={f.title}
                        className={"page-search-filter-button" + (!f.isActive ? " page-search-filter-button--inactive" : "")}
                        onClick={ e => handleFilterPressed(f) }
                    >
                        { f.title }
                    </div>
                ))
            }
        </React.Fragment>
    )
}