import { JobsApi } from '../../../api/ModuleApi';
import { getJobDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { JobFormData, JobStatus } from '../../../interfaces/jobs/JobTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { JobForm } from '../shared/form/JobForm';

export const NewJobForm = ({ onClose }: NewFormProps) => {
    const initialFormData: JobFormData = { 
        status: JobStatus.InProgress, 
        showPrevailingWage: false, 
        date: formatDateToInput(new Date()),
        contractPrice: "0",
        hangFramingCost: "0",
        tapingLaborCost: "0",
        materialCost: "0",
        miscCost: "0",
    }

    const handleClose = () => {
        onClose()
    }

    const handleNewJob = (formData: JobFormData) => {
        JobsApi.create(getJobDto(formData))
        .then(() => {
            handleClose()
        })
    }

    return (
        <NewForm
            title="Job"
            form={
                <JobForm
                    formData={initialFormData}
                    formType={FormType.New}
                    title="Job"
                    onSubmit={ values => handleNewJob(values) }
                />
            }
            onClose={() => handleClose()}
        />
    )
}