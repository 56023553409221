import { TextField } from "@material-ui/core";
import { FormikProps, withFormik } from "formik";
import { PrevailingValidationSchema } from "../../../helpers/FormValidators";
import { FormProps } from "../../../interfaces/forms/FormTypes";
import { PrevailingWageFormData } from "../../../interfaces/wages/PrevailingWageTypes";
import { FormErrorMessage } from "../../shared/forms/FormErrorMessage";
import { FormSubmitButton } from "../../shared/forms/FormSubmitButton";


const InnerForm = (props: FormProps<PrevailingWageFormData> & FormikProps<PrevailingWageFormData>) => {
	const { 
		touched, 
		errors, 
		isSubmitting, 
		handleChange, 
		title, 
		isValid, 
		formType, 
		handleBlur, 
		handleSubmit,
		values 
	} = props;
	return (
		<form>
			<div className="form-input-container">
				<TextField 
					label="Value $" 
					name="value"
					type="number"
					value={values.value} 
					onChange={handleChange}
					onBlur={handleBlur}
					error={touched.value && Boolean(errors.value)}
				/>
				{ touched.value && Boolean(errors.value) && <FormErrorMessage errorMessage={errors.value} /> }
			</div>
			<div className="form-input-container">
				<h1 className="form-input-label">
					Start Date
				</h1>
				<TextField 
					onChange={handleChange}
					name="startDate"
					onBlur={handleBlur}
					error={touched.startDate && Boolean(errors.startDate)}
					type="date"
					value= {values.startDate} 
				/>
				{ touched.startDate && Boolean(errors.startDate) && <FormErrorMessage errorMessage={errors.startDate} /> }
			</div>
			<div className="form-input-container">
				<h1 className="form-input-label">
					End Date
				</h1>
				<TextField 
					type="date"
					name="endDate"
					onBlur={handleBlur}
					error={touched.endDate && Boolean(errors.endDate)}
					value={values.endDate} 
					onChange={handleChange}
				/>
				{ touched.endDate && Boolean(errors.endDate) && <FormErrorMessage errorMessage={errors.endDate} /> }
			</div>
			<FormSubmitButton 
				isValid={isValid} 
				isSubmitting={isSubmitting}
				title={title}
				formType={formType}	
				handleSubmit={handleSubmit}
			/>
		</form>
	);
};

export const PrevailingWageForm = withFormik<FormProps<PrevailingWageFormData>, PrevailingWageFormData>({
	mapPropsToValues: props => {
		return props.formData
	},
	validateOnMount: true,
	validationSchema: PrevailingValidationSchema,
	validateOnBlur: true,
	handleSubmit: (values, { setSubmitting, props }) => {
		props.onSubmit(values)
		setSubmitting(false)
	},
	displayName: "Prevailing Wage Form",
})(InnerForm);