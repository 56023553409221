import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { EmployeesApi } from "../../api/ModuleApi"
import { Employee } from "../../interfaces/employees/EmployeeTypes"
import { SearchFilter } from "../../interfaces/search/FilterTypes"
import { selectEmployeeData } from "../../store/modules/ModuleSelectors"
import { DataPage } from "../shared/pages/DataPage"
import { EditEmployeeForm } from "./form/EditEmployeeForm"
import { NewEmployeeForm } from "./form/NewEmployeeForm"

const filters: SearchFilter[] = [
    { title: "#", keys: [ "id" ], isActive: true },
    { title: "First Name", keys: [ "firstName" ], isActive: true },
    { title: "Last Name", keys: [ "lastName" ], isActive: true },
    { title: "Title", keys: [ "jobTitle" ], isActive: true },
    { title: "SSN", keys: [ "socialSecurityNum" ], isActive: true },
]

const columns = [
    { field: 'id', headerName: '#', flex: 0.2, type: 'number' },
    { field: 'first', headerName: 'First Name', flex: 0.7 },
    { field: 'last', headerName: 'Last Name', flex: 0.7 },
    { field: 'title', headerName: 'Title', flex: 0.6 },
    { field: 'phone', headerName: 'Phone', flex: 0.6 },
];

export const EmployeesPage = () => {
    const pageData = useSelector(selectEmployeeData)

    useEffect(() => {
        EmployeesApi.fetchAll()
    }, [])

    const getRows = (tableData: Employee[]) => {
        return tableData.map((employee) => {
            return {
                id: employee.id,
                first: employee.firstName,
                last: employee.lastName,
                phone: employee.cellPhone,
                title: employee.jobTitle,
                rowObject: employee
            }
        })
    }

    return (
        <DataPage
            filters={filters}
            title={"Employees"}
            newButtonTitle={"New Employee"}
            pageData={pageData}
            tableColumns={columns}
            getRows={ tableData => getRows(tableData) }
            newForm={NewEmployeeForm}
            editForm={EditEmployeeForm}
        />
    )
}