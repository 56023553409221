import { SuppliersApi } from '../../../api/ModuleApi';
import { getSupplierDto } from '../../../helpers/DtoGenerators';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { Supplier, SupplierFormData } from '../../../interfaces/suppliers/SupplierTypes';
import { EditForm } from '../../shared/forms/EditForm';
import { SupplierForm } from './SupplierForm';

export const EditSupplierForm = ({ currentValue, onClose }: EditFormProps<Supplier>) => {
    const initialData: SupplierFormData = {
        name: currentValue.name,
        address: currentValue.address,
        contact1: currentValue.contact1,
        phone: currentValue.phone,
        contact2: currentValue.contact2,
        additionalNotes: currentValue.additionalNotes
    }

    const handleUpdate = (formData: SupplierFormData) => {
        SuppliersApi.edit(currentValue.id, getSupplierDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemove = () => {
        SuppliersApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <EditForm
            title="Supplier"
            form={
                <SupplierForm
                    formData={initialData}
                    formType={FormType.Edit}
                    title="Supplier"
                    onSubmit={ values => handleUpdate(values) }
                />
            }
            onClose={onClose}
            handleDeleteAction={handleRemove}
        />
    )
}