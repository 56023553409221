import { BidsApi } from '../../../api/ModuleApi';
import { getBidDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { BidFormData } from '../../../interfaces/bids/BidTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { BidForm } from '../shared/form/BidForm';

export const NewBidForm = ({ onClose }: NewFormProps) => {
    const initialFormData: BidFormData = { 
        date: formatDateToInput(new Date()),
        name: '',
        awarded: false,
        awardAmount: '0',
        bidAmount: '0',
    }

    const handleClose = () => {
        onClose()
    }

    const handleNewBid = (formData: BidFormData) => {
        BidsApi.create(getBidDto(formData))
        .then(() => {
            handleClose()
        })
    }

    return (
        <NewForm
            title="Bid"
            form={
                <BidForm
                    formData={initialFormData}
                    formType={FormType.New}
                    title="Bid"
                    onSubmit={ values => handleNewBid(values) }
                />
            }
            onClose={() => handleClose()}
        />
    )
}