import { GridColumnsToolbarButton, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";

export const ExportToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridColumnsToolbarButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export const ExportToolbarLeftAlign = () => {
    return (
        <GridToolbarContainer style={{ justifyContent: "flex-start"}}>
            <GridColumnsToolbarButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export const ExportToolbarLeftAlignNoColumns = () => {
    return (
        <GridToolbarContainer style={{ justifyContent: "flex-start"}}>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}