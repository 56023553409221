import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { JobsApi } from '../../../api/ModuleApi';
import { getJobDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput, getStringFromNumberValue } from '../../../helpers/IndexHelpers';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { Job, JobFormData } from '../../../interfaces/jobs/JobTypes';
import { DeleteDialog } from '../../dialogs/DeleteDialog';
import { JobCosting } from '../costing/JobCosting';
import { JobForm } from '../shared/form/JobForm';
import './EditJobForm.css';
import { EditJobTabs } from './EditJobTabs';

export enum EditJobPage {
    Info,
    Costing
}

export const EditJobForm = ({ onClose, currentValue }: EditFormProps<Job>) => {
    const initialData: JobFormData = {
        ...currentValue,
        contractPrice: getStringFromNumberValue(currentValue.contractPrice),
        materialCost: getStringFromNumberValue(currentValue.materialCost),
        hangFramingCost: getStringFromNumberValue(currentValue.hangFramingCost),
        tapingLaborCost: getStringFromNumberValue(currentValue.tapingLaborCost),
        prevailingWage: currentValue.prevailingWage ? getStringFromNumberValue(currentValue.prevailingWage) : undefined,
        manDayPrice: getStringFromNumberValue(currentValue.manDayPrice),
        miscCost: getStringFromNumberValue(currentValue.miscCost),
        date: formatDateToInput(currentValue.date),
        showPrevailingWage: currentValue.prevailingWage !== undefined && currentValue.prevailingWage !== null
    }
    console.log(initialData)
    const [editPage, setEditPage] = useState(EditJobPage.Info)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    const handleUpdateJob = (formData: JobFormData) => {
        JobsApi.edit(currentValue.id, getJobDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemoveJob = () => {
        JobsApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <div className={"page-background-container"}>
            <div className={"edit-job-form-container" + (editPage === EditJobPage.Info ? " edit-job-form-container-info" : " edit-job-form-container-costing")}>
                <div className="form-container-header">
                    <h1>Edit Job</h1>
                    <FontAwesomeIcon icon={faTimes} size="2x" onClick={ e => onClose() } />
                </div>
                <div style={{ paddingBottom: "10px" }}>
                    <EditJobTabs editPage={editPage} onPageChange={ p => setEditPage(p) } />
                </div>
                { editPage === EditJobPage.Info &&
                    <React.Fragment>
                        <JobForm
                            formData={initialData}
                            formType={FormType.Edit}
                            title="Job"
                            onSubmit={ values => handleUpdateJob(values) }
                        />
                    </React.Fragment>
                }
                { editPage === EditJobPage.Costing &&
                    <JobCosting job={ currentValue }/>
                }
                <DeleteDialog
                    open={deleteDialogOpen}
                    handleClose={ () => setDeleteDialogOpen(false) }
                    handleDelete={ () => {
                        setDeleteDialogOpen(false)
                        handleRemoveJob()
                    }}
                    content="Are you sure you want to delete this job? This will delete all the change orders that go along with it."
                />
            </div>
        </div>
    )
}