import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

type Props = {
    onClose: () => void,
    form: JSX.Element,
    title: string,
}

export const NewForm = ({ onClose, form, title }: Props) => {
    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault()
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    return (
        <div className={"page-background-container"}>
            <div className="form-container">
                <div className="form-container-header">
                    <h1>New { title }</h1>
                    <FontAwesomeIcon icon={faTimes} size="2x" onClick={ e => onClose() } />
                </div>
                { form }
            </div>
        </div>
    )
}