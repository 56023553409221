import { Divider } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { getWorkersCompRate, toNumber } from "../../../helpers/DataHelpers"
import { Employee } from "../../../interfaces/employees/EmployeeTypes"
import { LaborFormData, LaborFormTotal } from "../../../interfaces/labor/LaborTypes"

type Props = {
    values: LaborFormData
}

export const LaborFormCalculations = ({ values }: Props) => {
    const [total, setTotal] = useState<LaborFormTotal>({hangFraming: 0, taping: 0, workersComp: 0});

    useEffect(() => {
        const getLaborCost = (rate: number, hours?: string, otHours?: string ) => {
            const otRate = 1.5;
            const laborHours = toNumber(hours);
            const laborOT = toNumber(otHours);
            return rate * (laborHours + ( otRate *  laborOT));
        }

        if (values.employee){
            const workersCompRate = values.wcRate 
                ? toNumber(values.wcRate) 
                : getWorkersCompRate(toNumber(values.employee?.hangFramingRate));

            const hangFramingTotal = getLaborCost(
                toNumber(values["hangFramingRate"]),
                values.hangFramingHours,
                values.hangFramingHoursOvertime
            )
            const tapingFrameTotal = getLaborCost(
                toNumber(values["tapingLaborRate"]),
                values.tapingLaborHours,
                values.tapingLaborHoursOvertime
            )
            
            setTotal({
                hangFraming: hangFramingTotal,
                taping: tapingFrameTotal,
                workersComp: workersCompRate * (hangFramingTotal + tapingFrameTotal)
            })            
        }     
    },[values])

    return (
        <React.Fragment>
            <Divider />
            <div className="labor-form-calc-container">                            
                <h1 className="form-input-label">
                    <i>Hang Framing Sub Total: ${total.hangFraming.toFixed(2)}
                    </i>
                </h1>
                <h1 className="form-input-label">
                    <i>Taping Labor Sub Total: ${total.taping.toFixed(2)}
                    </i>
                </h1>
                <br/>
                <h1 className="form-input-label">
                    <i>Total Payroll Tax: 
                        ${total.workersComp.toFixed(2)}
                    </i>
                </h1>
                <h1 className="form-input-label">                                
                    <i>Total Labor: 
                        ${
                            (total.taping + total.hangFraming).toFixed(2)
                        }
                    </i>                                
                </h1>
                <h1 className="form-input-label">
                    <strong>
                        <i>Total Cost: 
                            ${
                                (total.workersComp + total.taping + total.hangFraming).toFixed(2)
                            }
                        </i>
                    </strong>
                </h1>
            </div>
            <Divider />
        </React.Fragment>
    )
}