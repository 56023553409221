import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	BrowserRouter as Router,
	Redirect,
	Route, Switch
} from "react-router-dom";
import { AuthApi } from '../api/AuthApi';
import { loadMapsAPI } from '../helpers/GoogleMapHelpers';
import { navPages } from '../interfaces/navigation/NavPageTypes';
import { isLoggedIn } from '../store/auth/AuthSelectors';
import { AppAlert } from './alerts/AppAlert';
import './App.css';
import { ForgotPasswordPage } from './auth/forgot/ForgotPassword';
import { LoginPage } from './auth/login/LoginPage';
import { NotFound } from './errors/NotFound';
import { ProtectedRoute } from './routes/ProtectedRoute';
import './shared/styles/FormStyles.css';
import './shared/styles/PageStyles.css';
import './shared/styles/TableStyles.css';

function App() {
	const [authChecked, setAuthChecked] = useState(false)
	const isAuthenticated = useSelector(isLoggedIn)
	const loaded = React.useRef(false);
	loadMapsAPI(loaded)

	useEffect(() => {
		AuthApi.verifyAuthStatus()
		.then(() => setAuthChecked(true) )
		.catch(() => setAuthChecked(true) )
	}, [])

	const cancelNumberScroll = function(event: WheelEvent){
		const el = document.activeElement as HTMLInputElement
    if(el && el.type === "number" && el.blur){
			el.blur();
    }
	};

	useEffect(() => {
		document.addEventListener("wheel", cancelNumberScroll);

    return () => {
      document.removeEventListener('wheel', cancelNumberScroll);
    };

	});

	if (!authChecked) {
		return (
			<div>
			</div>
		)
	}

	if (!isAuthenticated) {
		return (
			<React.Fragment>
				<AppAlert />
				<Router>
					<div className="app-content-container">
						<Switch>
							<Route path="/login">
								<LoginPage />
							</Route>
							<Route path="/forgot">
								<ForgotPasswordPage />
							</Route>
							<Route exact path="/">
								<Redirect to="/login" />
							</Route>
							<Route path="*">
								<NotFound 
									notFoundButton={{ title: "Redirect To Login", redirect: "/login" }}
								/>
							</Route>
						</Switch>
					</div>
				</Router>
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<AppAlert />
			<Router>
				<div className="app-content-container">
					<Switch>
						{
							navPages.map((navPage) => (
								<Route 
									path={navPage.href} 
									exact 
									component={(props: any) => <ProtectedRoute page={navPage.page} /> } 
									key={navPage.title}
								/>
							))
						}
						<Route exact path="/">
							<Redirect to="/jobs" />
						</Route>
						<Route path="/login">
							<Redirect to="/jobs" />
						</Route>
						<Route path="*">
							<NotFound />
						</Route>
					</Switch>
				</div>
			</Router>
		</React.Fragment>
  );
}

export default App;
