import { WagesApi } from '../../../api/ModuleApi';
import { getWagesDto } from '../../../helpers/DtoGenerators';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { PrevailingWageFormData } from '../../../interfaces/wages/PrevailingWageTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { PrevailingWageForm } from './PrevailingWageForm';

export const NewPrevailingWageForm = ({ onClose }: NewFormProps) => {
    const handleNew = (formData: PrevailingWageFormData) => {
        WagesApi.create(getWagesDto(formData))
        .then(() => {
            onClose()
        })
    }

	return (
		<NewForm
			title="Prevailing Wage"
			form={ 
				<PrevailingWageForm
					title="Prevailing Wage"
					formType={FormType.New}
					formData={{}}
					onSubmit={ (values) => handleNew(values) }
				/> 
			}
			onClose={() => onClose()}
		/>
	)
}