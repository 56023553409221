import { TextField } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthApi } from '../../../api/AuthApi'
import { LoginFormData } from '../../../interfaces/auth/LoginTypes'
import WelcomeImage from './goals.svg'
import './LoginPage.css'

export const LoginPage = () => {
    const [formData, setFormData] = useState<LoginFormData>({})
    const [showError, setShowError] = useState(false)
    const history = useHistory()

    const handleLogin = () => {
        AuthApi.login(formData)
        .then(() => {
            history.push("/jobs")
        })
        .catch(() => {
            setShowError(true)
        })
    }

    return (
        <div className="login-page-container">
            <h1 className="login-logo"><span className="logo-icon">TF</span> Construction</h1>
            <div className="login-page-form-container">
                <div className="login-form-content">
                    <p className="login-label">Login</p>
                    { showError && 
                        <div className="login-error">Username and or password are incorrect. Please try again.</div> 
                    }
                    <div className="login-form-input-container">
                        <TextField 
                            variant="outlined" 
                            type="email"
                            label="Email" 
                            value= {formData.email} 
                            onChange={ e => setFormData({ ...formData, email: e.target.value }) }
                        />
                    </div>
                    <div className="login-form-input-container">
                        <TextField 
                            variant="outlined" 
                            type="password"
                            label="Password" 
                            value= {formData.password} 
                            onChange={ e => setFormData({ ...formData, password: e.target.value }) }
                        />
                    </div>
                    <button 
                        className="login-form-submit-button"
                        onClick={ e => handleLogin() }
                    >
                        SUBMIT
                    </button>
                </div>
            </div>
            <div className="login-page-right-container">
                <h1 className="welcome-back-text">Welcome Back!</h1>
                <img className="welcome-image" src={WelcomeImage} alt="Welcome" />
                <p className="welcome-app-title">TruForm Construction Management Application</p>
            </div>
        </div>
    )
}