import { createMuiTheme } from "@material-ui/core";

const LIGHT_BLUE_STEEL = "#BBCDE5"
const LIGHT_BLUE = "#298BE5"
const MAIN_BLUE = "#1C5D99";
const DARK_BLUE = "#123E66";

export const AppTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
        root: {
            '& $notchedOutline': {
                borderColor: LIGHT_BLUE_STEEL,
            },
            '&$disabled $notchedOutline': {
                borderColor: LIGHT_BLUE_STEEL,
            },
            '&$focused $notchedOutline': {
                borderColor: LIGHT_BLUE,
            },
            '&:hover $notchedOutline': {
                borderColor: MAIN_BLUE,
            }
        }
    },
    MuiFormLabel: {
        root: {
            '&$focused': {
                color: LIGHT_BLUE,
            }
        },
    },
    MuiButton: {
      text: {
        label: {
          color: DARK_BLUE
        }
      }
    }
  },
  props: {
    MuiTextField: {
        variant: "outlined"
    }
  }
});