import { CustomersApi } from '../../../api/ModuleApi';
import { getCustomerDto } from '../../../helpers/DtoGenerators';
import { Customer, CustomerFormData } from '../../../interfaces/customers/CustomerTypes';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { EditForm } from '../../shared/forms/EditForm';
import { CustomerForm } from './CustomerForm';

export const EditCustomerForm = ({ currentValue, onClose }: EditFormProps<Customer>) => {
    const initialValues = {
        ...currentValue,
        contractorLicenseNum: currentValue.contractorLicenseNum ? currentValue.contractorLicenseNum.toString() : "",
    }

    const handleUpdate = (formData: CustomerFormData) => {
        CustomersApi.edit(currentValue.id, getCustomerDto(formData))
        .then(() => {
            onClose()
        })
    }

    const handleRemove = () => {
        CustomersApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <EditForm
            title="Customer"
            form={
                <CustomerForm
                    formData={initialValues}
                    formType={FormType.Edit}
                    title="Customer"
                    onSubmit={ values => handleUpdate(values) }
                />
            }
            onClose={onClose}
            handleDeleteAction={ handleRemove }
        />
    )
}