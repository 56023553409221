import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeOrdersApi } from "../../../api/ModuleApi"
import { getChangeOrderDto } from "../../../helpers/DtoGenerators"
import { formatDateToInput } from "../../../helpers/IndexHelpers"
import { ChangeOrder, ChangeOrderFormData, ChangeOrderItemFormData } from "../../../interfaces/change/ChangeOrderTypes"
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes'
import { ChangeOrderForm, getChangeOrderFromUpdate } from "../form/ChangeOrderForm"
import { generateChangeOrderPdf } from "../assets/generateChangeOrderPdf";

type Props = {
    showJobInput?: boolean
}

export const EditChangeOrder = ({ currentValue, onClose, showJobInput = true }: EditFormProps<ChangeOrder> & Props) => {
    const initialValues: ChangeOrderFormData = {
        ...currentValue,
        date: formatDateToInput(currentValue.date),
        items: currentValue.items.map((item) => {
            return {
                ...item,
                cost: item.cost.toString()
            } as ChangeOrderItemFormData
        })
    }

    const handleUpdate = (formData: ChangeOrderFormData) => {
        ChangeOrdersApi.edit(currentValue.id, getChangeOrderDto(formData))
        .then((res) => {
            if(formData.generateChangeOrderPdf){
                //@ts-ignore Response interfaces?
                const pdfData = getChangeOrderFromUpdate(formData, res)
                generateChangeOrderPdf(pdfData)
                    .then((x) => onClose());
            }
            else
            {
                onClose()
            }
        })
    }

    const handleRemove = () => {
        ChangeOrdersApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <div className="page-background-container">
            <div className="page-alert-super-container">
                <div className="page-alert-container" style={{ width: "50vw" }}>
                    <div className="new-change-order-header-container">
                        <h1>Edit Change Order</h1>
                        <FontAwesomeIcon icon={faTimes} size="2x" onClick={ e => onClose() } />
                    </div>
                    <ChangeOrderForm
                        formData={initialValues}
                        formType={FormType.Edit}
                        showJobInput={showJobInput}
                        title="Change Order"
                        onSubmit={ values => handleUpdate(values) }
                    />
                    <div className="form-delete-button" onClick={ e => handleRemove() }>
                        <p>Delete Change Order</p>
                    </div>
                </div>
            </div>
        </div>
    )
}