import { InvoicesApi } from '../../../api/ModuleApi';
import { getInvoiceDto } from '../../../helpers/DtoGenerators';
import { formatDateToInput } from '../../../helpers/IndexHelpers';
import { EditFormProps, FormType } from '../../../interfaces/forms/FormTypes';
import { Invoice, InvoiceFormData } from '../../../interfaces/invoices/InvoiceTypes';
import { EditForm } from '../../shared/forms/EditForm';
import { InvoiceAssetGenerator } from '../assets/InvoiceAssetGenerator';
import { InvoiceForm } from './InvoiceForm';

export const EditInvoiceForm = ({ currentValue, onClose }: EditFormProps<Invoice>) => {
    const initialValues: InvoiceFormData = {
        ...currentValue,
        contractAmount: currentValue.contractAmount ? currentValue.contractAmount.toString() : "",
        revisedContractAmount: currentValue.revisedContractAmount ? currentValue.revisedContractAmount.toString() : "",
        percentageCompletedToDate: currentValue.percentageCompletedToDate ? currentValue.percentageCompletedToDate.toString() : "",
        changeOrdersToDate: currentValue.changeOrdersToDate ? currentValue.changeOrdersToDate.toString() : "",
        lessPreviouslyBilled: currentValue.lessPreviouslyBilled ? currentValue.lessPreviouslyBilled.toString() : "",
        lessRetentionPercentage: currentValue.lessRetentionPercentage ? currentValue.lessRetentionPercentage.toString() : "",
        disputedAmount: currentValue.disputedAmount ? currentValue.disputedAmount.toString() : "",
        billThroughDate: formatDateToInput(currentValue.billThroughDate) ?? ""
    }

    const handleUpdate = async (formData: InvoiceFormData) => {
        await InvoicesApi.edit(currentValue.id, getInvoiceDto(formData))
        if (formData.generateInvoicePdfProgress || formData.generateInvoicePdfFinal) {
            let updatedInvoice = await InvoicesApi.fetchById(currentValue.id)
            return new InvoiceAssetGenerator().generateAsset(updatedInvoice, formData.generateInvoicePdfProgress === true)
        }

        onClose()
    }

    const handleRemove = () => {
        InvoicesApi.delete(currentValue.id)
        .then(() => {
            onClose()
        })
    }

    return (
        <EditForm
            title="Invoice"
            form={
                <InvoiceForm
                    formData={initialValues}
                    formType={FormType.Edit}
                    title="Invoice"
                    onSubmit={ values => handleUpdate(values) }
                />
            }
            onClose={() => onClose()}
            handleDeleteAction={ () => handleRemove() }
        />
    )
}