import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
    onClick: () => void
}

export const PdfExportButton = ({ onClick }: Props) => {
    return (
        <div style={{ width: "100%", height: "100%"}} onClick={onClick}>
            <FontAwesomeIcon icon={faFilePdf} style={{color: "#123E66"}}/>
        </div>
    )
}