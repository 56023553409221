import axios from 'axios';
import { API_ROUTES, defaultApiOptions, getBackendUrlForPath } from "../helpers/ApiHelpers";
import { AlertTypes } from "../interfaces/alert/AlertState";
import store from "../store";
import { setAlert } from "../store/alert/AlertActions";

export class LimitedModuleApi {
    route = API_ROUTES.BASE
    dataName = ""

    constructor(dataName: string, route: API_ROUTES) {
        this.dataName = dataName
        this.route = route
    }

    async fetchAll() {
        try {
            let response = await axios.get(getBackendUrlForPath(this.route), defaultApiOptions)
            return response.data
        } catch (error: any) {
            store.dispatch(setAlert(`Could not fetch ${this.dataName.toLowerCase()}s. Please try refreshing the page.`, AlertTypes.Error))
            throw new Error(error)
        }
    }
}

export const PrintFormatChangeOrderApi = new LimitedModuleApi("Print Format - Change Order", API_ROUTES.PRINT_FORMAT_CHANGE_ORDER)
export const PrintFormatInvoiceApi = new LimitedModuleApi("Print Format - Invoice", API_ROUTES.PRINT_FORMAT_INVOICE)
