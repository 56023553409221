import { CustomersApi } from '../../../api/ModuleApi';
import { getCustomerDto } from '../../../helpers/DtoGenerators';
import { CustomerFormData } from '../../../interfaces/customers/CustomerTypes';
import { FormType, NewFormProps } from '../../../interfaces/forms/FormTypes';
import { NewForm } from '../../shared/forms/NewForm';
import { CustomerForm } from './CustomerForm';

export const NewCustomerForm = ({ onClose }: NewFormProps) => {
    const handleClose = () => {
        onClose()
    }

    const handleNewCustomer = (formData: CustomerFormData) => {
        CustomersApi.create(getCustomerDto(formData))
        .then(() => {
            handleClose()
        })
    }

    return (
        <NewForm
            title="Customer"
            form={
                <CustomerForm
                    formData={{}}
                    formType={FormType.New}
                    title="Customer"
                    onSubmit={ values => handleNewCustomer(values) }
                />
            }
            onClose={() => handleClose()}
        />
    )
}