import { AuthService } from "../../interfaces/auth/AuthService"
import { Service, ServiceStatusValues } from "../../interfaces/Service"
import { AuthActions, AuthActionTypes } from "./AuthTypes"

const initialState: Service<AuthService> = { status: ServiceStatusValues.Loading }

export const AuthReducer = (state: Service<AuthService> = initialState, action: AuthActions) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
            return {
                status: ServiceStatusValues.Loaded,
                payload: {
                    currentUser: action.payload
                }
            } as Service<AuthService>
        case AuthActionTypes.LOGOUT:
            return {
                status: ServiceStatusValues.Loaded,
                payload: {
                    currentUser: null
                }
            } as Service<AuthService>
        default:
            return state
    }
}