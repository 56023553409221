import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GridColumns, GridRowsProp } from "@material-ui/data-grid"
import React, { useState } from "react"
import { getSearchKeysFromFilters } from "../../../helpers/SearchHelpers"
import { NewFormProps, EditFormProps } from "../../../interfaces/forms/FormTypes"
import { SearchFilter } from "../../../interfaces/search/FilterTypes"
import { SearchInput } from "../inputs/SearchInput"
import { DataTable } from "../table/DataTable"

type Props<T> = {
    filters: SearchFilter[],
    title: string,
    newButtonTitle: string,
    pageData: T[],
    tableColumns: GridColumns,
    getRows: (tableData: T[]) => GridRowsProp,
    newForm: string | React.FunctionComponent<NewFormProps>,
    editForm: string | React.FunctionComponent<EditFormProps<T>>,
}

export const DataPage = <T extends object>({ 
        filters, 
        title, 
        pageData,
        tableColumns,
        getRows,
        newButtonTitle,
        newForm,
        editForm,
    }: Props<T>) => {
    const [searchQuery, setSearchQuery] = useState("")
    const [searchFilters, setSearchFilters] = useState(filters)
    const [showNewForm, setShowNewForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [updateData, setUpdateData] = useState<T | null>(null)

    const handleRowSelect = (r: T) => {
        setUpdateData(r)
        setShowEditForm(true)            
    }

    const handleNewButtonClick = () =>{
        setShowNewForm(true);
    }

    return (
        <React.Fragment>
            <div className="page-title">{ title }</div>
            <div className="page-header">
                <div className="page-header-left-container">
                    <SearchInput 
                        onSearchChange={ s => setSearchQuery(s) } 
                        filters={searchFilters}
                        onFilterChange={ newFilters => setSearchFilters(newFilters) } 
                    />
                </div>
                <div className="page-new-button" onClick={ e => handleNewButtonClick()}>
                    <FontAwesomeIcon icon={faPlus} style={{marginRight: "10px"}}/>
                    { newButtonTitle }
                </div>
            </div>
            <DataTable<T>
                onRowSelect={ d => handleRowSelect(d as T) }
                data={pageData}
                searchQuery={searchQuery}
                searchKeys={getSearchKeysFromFilters(searchFilters)} 
                columns={tableColumns} 
                getRows={getRows}
            />
            { showNewForm && 
                React.createElement(
                    newForm,
                    { onClose: () => setShowNewForm(false) } as NewFormProps
                )
            }
            { (showEditForm && updateData) && 
                React.createElement(
                    editForm,
                    { 
                        onClose: () => setShowEditForm(false), 
                        currentValue: updateData as T
                    } as EditFormProps<T>
                )
            }
        </React.Fragment>
    )
}