import { Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type Props = {
    label: string,
    value?: boolean,
    handleAnswerChange: (value: boolean) => void
}

export const ToggleInput = ({ label, value, handleAnswerChange }: Props) => {
    return (
        <div className="form-input-container flex-row">
            <FormControlLabel
                control={
                    <Switch
                        checked={value}
                        onClick={ e => {
                            handleAnswerChange(!value)
                        }}
                        name={label}
                        color="primary"
                    />
                }
                label={label}
                labelPlacement="start"
                style={{ marginLeft: "0px" }}
            />
        </div>  
    )
}