import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_ROUTES, defaultApiOptions, getBackendUrlForPath } from "../helpers/ApiHelpers";
import { AlertTypes } from "../interfaces/alert/AlertState";
import { ChangeOrder } from "../interfaces/change/ChangeOrderTypes";
import { Customer } from "../interfaces/customers/CustomerTypes";
import { Employee } from "../interfaces/employees/EmployeeTypes";
import { Invoice } from "../interfaces/invoices/InvoiceTypes";
import { Job } from "../interfaces/jobs/JobTypes";
import { Labor } from "../interfaces/labor/LaborTypes";
import { Material } from "../interfaces/materials/MaterialTypes";
import { Supplier } from "../interfaces/suppliers/SupplierTypes";
import { PrevailingWage } from "../interfaces/wages/PrevailingWageTypes";
import store from "../store";
import { setAlert } from "../store/alert/AlertActions";
import { dataLoaded } from "../store/modules/ModuleActions";
import { ModuleDataType, ModuleTypes } from "../store/modules/ModuleTypes";

export class ModuleApi<T extends ModuleDataType> {
    route = API_ROUTES.BASE
    dataName = ""
    moduleType

    constructor(dataName: string, route: API_ROUTES, moduleType: ModuleTypes) {
        this.dataName = dataName
        this.route = route
        this.moduleType = moduleType
    }

    async fetchAll() {
        try {
            let response = await axios.get(getBackendUrlForPath(this.route), defaultApiOptions)
            store.dispatch(dataLoaded(response.data as T[], this.moduleType))
            return response.data
        } catch (error: any) {
            store.dispatch(setAlert(`Could not fetch ${this.dataName.toLowerCase()}s. Please try refreshing the page.`, AlertTypes.Error))
            throw new Error(error)
        }
    }

    fetchAllLimited() {
        let url = getBackendUrlForPath(this.route) + "/limited"
        return new Promise((resolve, reject) => {
            axios.get(url, defaultApiOptions)
            .then((response: AxiosResponse) => {
                // store in redux
                resolve(response.data)
            }, (error: AxiosError) => {
                store.dispatch(setAlert(`Could not fetch ${this.dataName.toLowerCase()}s. Please try refreshing the page.`, AlertTypes.Error))
                reject(error);
            })
        })
    }

    fetchById(id: number) {
        let url = `${getBackendUrlForPath(this.route)}/${id}`
        return new Promise<T>((resolve, reject) => {
            axios.get(url, defaultApiOptions)
            .then((response: AxiosResponse) => {
                // store in redux
                resolve(response.data as T)
            }, (error: AxiosError) => {
                store.dispatch(setAlert(`Could not fetch ${this.dataName.toLowerCase()}s. Please try refreshing the page.`, AlertTypes.Error))
                reject(error);
            })
        })
    }

    create(dto: any) {
        return new Promise((resolve, reject) => {
            axios.post(getBackendUrlForPath(this.route), dto, defaultApiOptions)
            .then((response: AxiosResponse) => {
                store.dispatch(setAlert(`${this.dataName} has been succesfully created.`, AlertTypes.Success))
                // fetch again
                this.fetchAll()
                .then(() => resolve(response.data) )
            }, (error: AxiosError) => {
                store.dispatch(setAlert(`Could not create ${this.dataName.toLowerCase()}. Please try again.`, AlertTypes.Error))
                reject(error);
            })
        })
    }

    edit(id: number, dto: any) {
        let apiUrl = `${getBackendUrlForPath(this.route)}/${id}`
        return new Promise((resolve, reject) => {
            axios.put(apiUrl, dto, defaultApiOptions)
            .then((response: AxiosResponse) => {
                store.dispatch(setAlert(`${this.dataName} has been succesfully updated.`, AlertTypes.Success))
                // fetch again
                this.fetchAll()
                .then(() => resolve(response.data) )
            }, (error: AxiosError) => {
                store.dispatch(setAlert(`Could not update ${this.dataName.toLowerCase()}. Please try again.`, AlertTypes.Error))
                reject(error);
            })
        })
    }

    delete(id: number) {
        let apiUrl = `${getBackendUrlForPath(this.route)}/${id}`
        return new Promise((resolve, reject) => {
            axios.delete(apiUrl, defaultApiOptions)
            .then((response: AxiosResponse) => {
                store.dispatch(setAlert(`${this.dataName} has been succesfully deleted.`, AlertTypes.Success))
                // fetch again
                this.fetchAll()
                .then(() => resolve(id) )
            }, (error: AxiosError) => {
                store.dispatch(setAlert(`Could not delete ${this.dataName.toLowerCase()}. Please try again.`, AlertTypes.Error))
                reject(error);
            })
        })
    }

}

export const CustomersApi = new ModuleApi<Customer>("Customer", API_ROUTES.CUSTOMERS, ModuleTypes.Customer)
export const JobsApi = new ModuleApi<Job>("Job", API_ROUTES.JOBS, ModuleTypes.Job)
export const BidsApi = new ModuleApi<Job>("Bid", API_ROUTES.BIDS, ModuleTypes.Bid)
export const EmployeesApi = new ModuleApi<Employee>("Employee", API_ROUTES.EMPLOYEES, ModuleTypes.Employee)
export const InvoicesApi = new ModuleApi<Invoice>("Invoice", API_ROUTES.INVOICES, ModuleTypes.Invoice)
export const LaborApi = new ModuleApi<Labor>("Labor", API_ROUTES.LABOR, ModuleTypes.Labor)
export const SuppliersApi = new ModuleApi<Supplier>("Supplier", API_ROUTES.SUPPLIERS, ModuleTypes.Supplier)
export const WagesApi = new ModuleApi<PrevailingWage>("Prevailing Wage", API_ROUTES.WAGES, ModuleTypes.Wage)
export const MaterialsApi = new ModuleApi<Material>("Material", API_ROUTES.MATERIALS, ModuleTypes.Material)
export const ChangeOrdersApi = new ModuleApi<ChangeOrder>("Change Order", API_ROUTES.CHANGE_ORDERS, ModuleTypes.ChangeOrder)