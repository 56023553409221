import * as yup from 'yup';

export const PrevailingValidationSchema = yup.object({
	value: yup
        .number()
		.required('Value is required')
		.min(0, "Amount must be positive or 0."),
	startDate: yup
		.date()
		.required('Start Date is required'),
	endDate: yup
		.date()
		.min(
			yup.ref('startDate'),
			({ min }) => `Date needs to be after ${new Date(min).toDateString()}`
		)
});

export const JobValidationSchema = yup.object({
	name: yup
		.string()
		.required('Job name is required'),
	customer: yup
		.object()		
		.nullable()
		.required("Customer is required."),
	contractPrice: yup
		.number()
		.required("Contract amount is required.")
		.min(0, "Amount must be positive or 0."),
	hangFramingCost: yup
		.number()
		.required("Hang framing amount is required.")
		.min(0, "Amount must be positive or 0."),
	tapingLaborCost: yup
		.number()
		.required("Taping amount is required.")
		.min(0, "Amount must be positive or 0."),
	materialCost: yup
		.number()
		.required("Material amount is required.")
		.min(0, "Amount must be positive or 0."),
	miscCost: yup
		.number()
		.required("Misc amount is required.")
		.min(0, "Amount must be positive or 0."),
	manDayPrice: yup
		.number()
		.required("Man day price is required.")
		.min(0, "Amount must be positive or 0."),
	date: yup
		.date()
		.when("showPrevailingWage", {
			is: true,
			then: yup.date().required("You must enter a date to determine prevailing wage.")
		}),
	prevailingWage: yup
		.number()
		.nullable()
		.min(0, "Amount must be positive or 0."),
});

export const BidValidationSchema = yup.object({
	name: yup
		.string()
		.required('Bid name is required'),
	customer: yup
		.object()		
		.nullable()
		.required("Customer is required."),
	awarded:
		yup
		.boolean()
		.required(),
	bidAmount: yup
		.number()
		.required("Bid amount is required.")
		.min(0, "Bid amount must be positive or 0."),
	awardAmount: yup
		.number()
		.required("Award amount is required.")
		.min(0, "Award amount must be positive or 0."),
	date: yup
		.date()
		.required("Bid date is required."),
});


export const LaborValidationSchema = yup.object({
	job: yup
		.object()
		.nullable()
		.required('Job is required'),
	employee: yup
		.object()
		.nullable()
		.required('Employee is required'),
	tapingLaborRate: yup
		.number()
		.min(0, "Amount must be positive or 0."),
	hangFramingRate: yup
		.number()
		.min(0, "Amount must be positive or 0."),
	hangFramingHours: yup
		.number()
		.min(0, "Amount must be positive or 0."),
	hangFramingHoursOvertime: yup
		.number()
		.min(0, "Amount must be positive or 0."),
	tapingLaborHours: yup
		.number()
		.min(0, "Amount must be positive or 0."),
	tapingLaborHoursOvertime: yup
		.number()
		.min(0, "Amount must be positive or 0."),
	wcRate: yup
		.number()
		.min(0, "Amount must be positive or 0.")
		.required('Payroll Tax Rate is required')
});

export const ChangeOrderValidationSchema = yup.object({
	job: yup
		.object()
		.nullable()
		.required('Job is required.'),
	title: yup
		.string()
		.required('Title is required.'),
});

export const CustomerValidationSchema = yup.object({
	name: yup
		.string()
		.required('Customer name is required.'),
});

export const EmployeeValidationSchema = yup.object({
	firstName: yup
		.string()
		.required('First name is required.'),
	hangFramingRate: yup
		.number()
		.required('Framing rate is required.')
		.min(0, "Amount must be positive or 0."),
	tapingLaborRate: yup
		.number()
		.required('Taping rate is required.')
		.min(0, "Amount must be positive or 0.")
});

export const SupplierValidationSchema = yup.object({
	name: yup
		.string()
		.required('Supplier name is required.'),
});

export const MaterialValidationSchema = yup.object({
	supplier: yup
		.object()
		.nullable()
		.required('Supplier is required'),
	job: yup
		.object()
		.nullable()
		.required('Job is required'),
	invoiceNumber: yup
		.string()
		.required('Invoice number is required.'),
	amount: yup
		.number()
		.required("Amount is required."),
});

export const InvoiceValidationSchema = yup.object({
	job: yup
		.object()
		.nullable()
		.required('Job is required'),
	billThroughDate: yup
		.date()
		.required('Billing through date is required.'),
});

export const EditUserValidationSchema = yup.object({
	email: yup
		.string()
		.email('Email must be valid.')
		.required('Email is required.'),
	role: yup
		.string()
		.oneOf(['admin', 'staff'], 'Invalid role.'),
	password: yup
		.string()
		.optional()
		.min(8, 'Password must be at least 8 characters long.')
		.oneOf([yup.ref('repeatPassword'), null], 'Passwords must match.'),
	repeatPassword: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Passwords must match.')
});

export const NewUserValidationSchema = yup.object({
	email: yup
		.string()
		.email('Email must be valid.')
		.required('Email is required.'),
	role: yup
		.string()
		.oneOf(['admin', 'staff'], 'Invalid role.'),
	password: yup
		.string()
		.required()
		.min(8, 'Password must be at least 8 characters long.')
		.oneOf([yup.ref('repeatPassword'), null], 'Passwords must match.'),
	repeatPassword: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Passwords must match.')
});